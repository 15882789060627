import styled from "styled-components";

import StarFull from 'assets/icons/star-full.svg'
import StarEmpty from 'assets/icons/star.svg'
import StarHalf from 'assets/icons/star-half.svg'
import { colors } from "utils/colors";

export const Rating = ({ rating }) => {
    const decimal = rating % 1
    const fullCount = Math.floor(rating) + (decimal >= 0.75 ? 1 : 0)
    const halfCount = decimal > 0.25 && decimal < 0.75 ? 1 : 0;
    const emptyCount = 5 - fullCount - halfCount
    return <div className="cmp-rating">
        {fullCount ? Array(fullCount).fill(1).map((_, i) => {
            return <img key={i} src={StarFull} />
        }) : ''}
        {halfCount ? Array(halfCount).fill(1).map((_, i) => {
            return <img key={i} src={StarHalf} />
        }) : ''}
        {emptyCount ? Array(emptyCount).fill(1).map((_, i) => {
            return <img key={i} src={StarEmpty} />
        }) : ''}
    </div>
}

const BarWrapper = styled.div`
    & > div {
        display: inline-block;
    }
`

const BarEmpty = styled.div`
    width: 10px;
    height: 20px;
    background-color: ${colors.lightgrey};
    border-radius: 5px;
    margin: 0.2em;
`

const BarHalf = styled(BarEmpty)`
    background-color: ${colors.whitecyan};
`

const BarFull = styled(BarEmpty)`
    background-color: ${colors.cyan};
`

export const BarRating = ({ rating }) => {
    const decimal = rating % 1
    const fullCount = Math.floor(rating) + (decimal >= 0.75 ? 1 : 0)
    const halfCount = decimal > 0.25 && decimal < 0.75 ? 1 : 0;
    const emptyCount = 10 - fullCount - halfCount
    return <BarWrapper className="cmp-bar-rating">
        {fullCount ? Array(fullCount).fill(1).map((_, i) => {
            return <BarFull key={i} />
        }) : ''}
        {halfCount ? Array(halfCount).fill(1).map((_, i) => {
            return <BarHalf key={i} />
        }) : ''}
        {emptyCount ? Array(emptyCount).fill(1).map((_, i) => {
            return <BarEmpty key={i} />
        }) : ''}
    </BarWrapper>
}