import styled from "styled-components"
import intl from "react-intl-universal";
import { useNavigate, useSearchParams } from "react-router-dom";

import { colors } from "utils/colors";
import { Form, Link } from "components/form";
import { Input } from "components/input";
import { QuaternaryButton, SecondaryButton } from "components/buttons";

import LogoImg from "assets/logo.png";
import ArrowLeftSvg from "assets/icons/arrow-left.svg";
import { useState } from "react";
import { useMutation } from "urql";
import { ForgotEmailMutation, ForgotPasswordMutation, LoginMutation, ResetPasswordMutation, SignUpMutation } from "queries/auth";
import { isValidEmail, isValidPassword, isValidUsername } from "utils/validation";
import { useAppContext } from "components/page";
import { SecureStoragePlugin } from "capacitor-secure-storage-plugin";
import { Label } from "components/label";

const PageWrapper = styled.div`
    background: ${colors.lightgrey};
`

const Wrapper = styled.div.attrs(({ $top, $bottom }) => ({
    $top,
    $bottom,
}))`
    width: 100%;
    text-align: center;
    position: absolute;
    top: ${({ $top }) => $top}px;
    overflow: auto;
    width: 100%;
    height: calc(100vh - ${({ $top, $bottom }) => $top + $bottom}px);
`

const Logo = styled.img`
    width: 150px;
`

const FormCard = styled.div`
    background: ${colors.white};
    max-width: 400px;
    width: calc(100% - 3em);
    margin: auto;
    border-radius: 16px;
    padding: 1em;
    -webkit-box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.2);
`

const BrandName = styled.div`
    font-size: 50px;
    font-family: Rasputin;
    color: ${colors.cyan};
`

const Tagline = styled.div`
    padding: 0.25em;
    font-size: 18px;
`

const BackButton = styled.div`
    margin: 1em auto;
    padding: 0.25em 1em;
    border-radius: 8px;
    background: ${colors.white};
    width: fit-content;
    cursor: pointer;

    &:hover {
        background: ${colors.lightgrey};
    }

    & > img {
        filter: invert(70%) sepia(6%) saturate(5%) hue-rotate(346deg) brightness(100%) contrast(87%);
    }
`

const ButtonsWrapper = styled.div`
    & > * {
        margin-left: 0.5em;
        margin-right: 0.5em;
        margin-top: 0.5em;
        margin-bottom: -0.5em;
    }
`

const Disclaimer = styled.div`
    font-size: 16px;

    & > * {
        display: inline-block;
    }
`

const StyledLabel = styled(Label)`
    margin: 1em auto;
`

export const LoginPage = ({ mode }) => {
    const navigate = useNavigate();
    const { setAccessToken, safearea, setUsername } = useAppContext();
    const [, signUp] = useMutation(SignUpMutation)
    const [, login] = useMutation(LoginMutation)
    const [usernameErr, setUsernameErr] = useState(false)
    const [emailErr, setEmailErr] = useState(false)
    const [passwordErr, setPasswordErr] = useState(false)
    const [passwordConfirmErr, setPasswordConfirmErr] = useState(false)

    return <PageWrapper>
        <Wrapper $top={safearea.top} $bottom={safearea.bottom}>
            {mode === 'login' ? <FormCard>
                <Logo src={LogoImg} />
                <BrandName>BookBoos</BrandName>
                <Form onSubmit={(data) => {
                    const { username, password } = data
                    let hasErr = false
                    if (!isValidUsername(username)) { setUsernameErr(intl.get('errs.username')); hasErr = true }
                    if (!isValidPassword(password)) { setPasswordErr(intl.get('errs.password')); hasErr = true }
                    if (hasErr) return
                    setUsernameErr(false)
                    setPasswordErr(false)
                    login({ input: data }).then((resp) => {
                        if (resp.error) {
                            setPasswordErr(intl.get('errs.incorrectCreds'))
                            return
                        }
                        setUsername(username)
                        setAccessToken(resp.data?.login?.accessToken)
                        SecureStoragePlugin.set({ key: 'refreshToken', value: resp.data?.login?.refreshToken })
                        navigate('/feed')
                    })
                }}>
                    <Input
                        name="username"
                        label={intl.get('forms.username')}
                        errMsg={usernameErr}
                    />
                    <Input
                        type="password"
                        name="password"
                        label={intl.get('forms.password')}
                        errMsg={passwordErr}
                    />
                    <Disclaimer>
                        <Link onClick={() => {
                            navigate('/forgot-password')
                        }}>{intl.get('login.forgot')}</Link>
                    </Disclaimer>
                    <ButtonsWrapper>
                        <SecondaryButton>
                            {intl.get('actions.login')}
                        </SecondaryButton>
                        <QuaternaryButton onClick={() => {
                            navigate('/sign-up')
                        }}>
                            {intl.get('actions.signUp')}
                        </QuaternaryButton>
                    </ButtonsWrapper>
                </Form>
            </FormCard> : ''}
            {mode === 'sign-up' ? <FormCard>
                <Logo src={LogoImg} />
                <BrandName>BookBoos</BrandName>
                <Form onSubmit={(data) => {
                    const { username, email, password, passwordConfirm } = data
                    let hasErr = false
                    if (!isValidEmail(email)) { setEmailErr(intl.get('errs.email')); hasErr = true }
                    if (!isValidUsername(username)) { setUsernameErr(intl.get('errs.username')); hasErr = true }
                    if (!isValidPassword(password)) { setPasswordErr(intl.get('errs.password')); hasErr = true }
                    if (password !== passwordConfirm) { setPasswordConfirmErr(intl.get('errs.passwordNotMatch')); hasErr = true }
                    if (hasErr) return
                    setEmailErr(false)
                    setUsernameErr(false)
                    setPasswordErr(false)
                    setPasswordConfirmErr(false)
                    signUp({ input: { email, username, password } }).then((resp) => {
                        if (resp.error) {
                            setUsernameErr(intl.get('errs.usernameInUse'))
                            return
                        }
                        setUsername(username)
                        setAccessToken(resp.data?.signUp?.accessToken)
                        SecureStoragePlugin.set({ key: 'refreshToken', value: resp.data?.signUp?.refreshToken })
                        navigate('/feed')
                    })
                }}>
                    <Input
                        name="email"
                        label={intl.get('forms.email')}
                        errMsg={emailErr}
                    />
                    <Input
                        name="username"
                        label={intl.get('forms.username')}
                        errMsg={usernameErr}
                    />
                    <Input
                        type="password"
                        name="password"
                        label={intl.get('forms.password')}
                        errMsg={passwordErr}
                    />
                    <Input
                        type="password"
                        name="passwordConfirm"
                        label={intl.get('forms.confirmPassword')}
                        errMsg={passwordConfirmErr}
                    />
                    <Disclaimer>
                        {intl.get('login.disclaimer')} <Link onClick={() => {
                            window.open('/#/privacy', '_blank')
                        }}>{intl.get('login.privacyPolicy')}</Link>
                    </Disclaimer>
                    <ButtonsWrapper>
                        <SecondaryButton>
                            {intl.get('actions.signUp')}
                        </SecondaryButton>
                        <QuaternaryButton onClick={() => {
                            navigate('/login')
                        }}>
                            {intl.get('actions.login')}
                        </QuaternaryButton>
                    </ButtonsWrapper>
                </Form>
            </FormCard> : ''}
            <BackButton onClick={() => {
                navigate('/')
            }}>
                <img src={ArrowLeftSvg} />
            </BackButton>
        </Wrapper>
    </PageWrapper >
}

export const ForgotPasswordPage = ({ mode }) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { safearea } = useAppContext();
    const [, forgotPassword] = useMutation(ForgotPasswordMutation)
    const [, resetPassword] = useMutation(ResetPasswordMutation)
    const [emailErr, setEmailErr] = useState(false)
    const [passwordErr, setPasswordErr] = useState(false)
    const [passwordConfirmErr, setPasswordConfirmErr] = useState(false)
    const [msg, setMsg] = useState('')

    return <PageWrapper>
        <Wrapper $top={safearea.top} $bottom={safearea.bottom}>
            {mode === 'forgot-password' ? <FormCard>
                <Logo src={LogoImg} />
                <BrandName>BookBoos</BrandName>
                <StyledLabel $center>{intl.get('labels.forgotPassword')}</StyledLabel>
                {msg ? <>
                    {msg}
                </> : <Form onSubmit={(data) => {
                    const { email } = data
                    let hasErr = false
                    if (!isValidEmail(email)) { setEmailErr(intl.get('errs.email')); hasErr = true }
                    if (hasErr) return
                    forgotPassword({ input: data }).then((resp) => {
                        if (resp.error) {
                            setEmailErr(intl.get('errs.emailNotFound'))
                            return
                        }
                        setMsg(intl.get('msgs.checkResetEmail'))
                    })
                }}>
                    <Input
                        name="email"
                        label={intl.get('forms.email')}
                        errMsg={emailErr}
                    />
                    <ButtonsWrapper>
                        <SecondaryButton>
                            {intl.get('actions.sendEmail')}
                        </SecondaryButton>
                    </ButtonsWrapper>
                </Form>}

            </FormCard> : ''}
            {mode === 'reset-password' ? <FormCard>
                <Logo src={LogoImg} />
                <BrandName>BookBoos</BrandName>
                <StyledLabel $center>{intl.get('labels.resetPassword')}</StyledLabel>
                {msg ? <>
                    {msg}
                </> : <Form onSubmit={(data) => {
                    const { password, passwordConfirm } = data
                    let hasErr = false
                    if (!isValidPassword(password)) { setPasswordErr(intl.get('errs.password')); hasErr = true }
                    if (password !== passwordConfirm) { setPasswordConfirmErr(intl.get('errs.passwordNotMatch')); hasErr = true }
                    if (hasErr) return
                    resetPassword({ input: { token: searchParams.get('token'), password } }).then((resp) => {
                        if (resp.error) {
                            setPasswordConfirmErr(intl.get('errs.tokenExpired'))
                            return
                        }
                        setMsg(intl.get('msgs.passwordReset'))
                    })
                }}>
                    <Input
                        type="password"
                        name="password"
                        label={intl.get('forms.password')}
                        errMsg={passwordErr}
                    />
                    <Input
                        type="password"
                        name="passwordConfirm"
                        label={intl.get('forms.confirmPassword')}
                        errMsg={passwordConfirmErr}
                    />
                    <ButtonsWrapper>
                        <SecondaryButton>
                            {intl.get('actions.submit')}
                        </SecondaryButton>
                    </ButtonsWrapper>
                </Form>}
            </FormCard> : ''}
            <BackButton onClick={() => {
                navigate('/')
            }}>
                <img src={ArrowLeftSvg} />
            </BackButton>
        </Wrapper>
    </PageWrapper >
}