import styled from "styled-components";
import { colors } from "utils/colors";

const Wrapper = styled.button`
  font-size: 16px;
  border-radius: 8px;
  color: ${colors.white};
  padding: 0.5em 1em;
  text-align: center;
  cursor: pointer;
  transition: 0.2s background-color;
  &:hover {
    background-color: ${colors.darkgrey};
  }
`;

const Primary = styled(Wrapper)`
  background-color: ${colors.cyan};
`;

const Secondary = styled(Wrapper)`
  background-color: ${colors.lightcyan};
`;

const Tertiary = styled(Wrapper)`
  background-color: ${colors.cyan};
  border-radius: 2em;
`;

const Quaternary = styled(Wrapper)`
  color: ${colors.darkgrey};
  &:hover {
    background-color: ${colors.lightgrey};
  }
`;

const LoaderWrapper = styled.div`
  padding: 0.7em 1em;
`;

const Loader = styled.div`
  @keyframes spin {
    100% {
      transform: rotate(1turn);
    }
  }

  --d: 11px;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  box-shadow: calc(1 * var(--d)) calc(0 * var(--d)) 0 0,
    calc(0.707 * var(--d)) calc(0.707 * var(--d)) 0 0.5px,
    calc(0 * var(--d)) calc(1 * var(--d)) 0 1px,
    calc(-0.707 * var(--d)) calc(0.707 * var(--d)) 0 1.5px,
    calc(-1 * var(--d)) calc(0 * var(--d)) 0 2px,
    calc(-0.707 * var(--d)) calc(-0.707 * var(--d)) 0 2.5px,
    calc(0 * var(--d)) calc(-1 * var(--d)) 0 3px;
  animation: spin 0.5s infinite steps(8);
`;

export const PrimaryButton = ({ children, loading, onClick }) => {
  return (
    <Primary className="cmp-button cmp-primary-button" onClick={onClick}>
      {loading ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        children
      )}
    </Primary>
  );
};

export const SecondaryButton = ({ children, loading, onClick }) => {
  return (
    <Secondary className="cmp-button cmp-secondary-button" onClick={onClick}>
      {loading ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        children
      )}
    </Secondary>
  );
};

export const TertiaryButton = ({ children, loading, onClick }) => {
  return (
    <Tertiary className="cmp-button cmp-tertiary-button" onClick={onClick}>
      {loading ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        children
      )}
    </Tertiary>
  );
};

export const QuaternaryButton = ({ children, loading, onClick }) => {
  return (
    <Quaternary className="cmp-button cmp-tertiary-button" onClick={onClick}>
      {loading ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        children
      )}
    </Quaternary>
  );
};

export const Icon = styled.div`
    width: 32px;
    height: 32px;
    background-image: url(${({ src }) => src});
    background-size: cover;
    cursor: pointer;
`