import { useState, useEffect } from "react";
import { useMutation, useQuery } from "urql";
import intl from "react-intl-universal";
import styled from "styled-components";
import { Container, Row, Col } from 'react-grid-system';

import { colors } from "utils/colors";
import { AutocompleteBooksQuery, LoadFeedBooksQuery } from 'queries/book';

import { Page, useAppContext } from "components/page";
import { ApiProvider } from "components/api";
import { FriendBook } from "components/book/friend";
import { Caption, Label } from "components/label";
import { Story } from "components/story";

import { GetFriendsUpdatesQuery } from "queries/person";
import { HasNewNotificationsQuery } from "queries/notification";
import { Selections } from "components/selection";

import PfpPlaceholderImg from 'assets/imgs/pfp-placeholder.jpeg'
import FilterSvg from 'assets/icons/filter.svg'
import AddSvg from 'assets/icons/add.svg'
import { Modal } from "components/modal";
import { PrimaryButton, QuaternaryButton, TertiaryButton } from "components/buttons";
import { Input } from "components/input";
import { Form } from "components/form";
import { LeaveCommentMutation } from "queries/comment";

const Content = styled.div`
  max-width: 1200px;
  width: 100%;
  text-align: center;
  margin: auto;
`

const BG = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${colors.lightgrey};
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
`

const UpdatesWrapper = styled.div.attrs(({ $top, $bottom }) => ({
  $top, $bottom
}))`
  position: fixed;
  top: calc(50px + ${({ $top }) => $top}px);
  z-index: 2;
  background-color: ${colors.white};

  left: 0;
  white-space: nowrap;
  width: 100%;
  overflow: auto;
  display: inline-flex;
  height: calc(50px + 2em);
  padding: 0.5em 0;

  @media (min-width: 600px) {
      width: 100px;
      left: unset;
      right: 0;
      display: block;
      padding-top: 1em;
      padding-bottom: 1em;
      height: calc(100vh - 50px - 2em - ${({ $top, $bottom }) => $top + $bottom}px);
  }
`

const UpdateWrapper = styled.div`
  margin: 0.5em;
  cursor: pointer;
  transition: opacity 0.2s;
  width: fit-content;

  &:hover {
    opacity: 0.9;
  }
`

const Pfp = styled.img.attrs(({ $hasUpdates }) => ({
  $hasUpdates
}))`
  border-radius: 50px;
  width: 50px;
  height: 50px;
  outline: ${({ $hasUpdates }) => $hasUpdates ? `solid 5px ${colors.cyan}` : 'none'};
  outline-style: double;
`

const Username = styled.div`
  text-align: center;
  font-size: 14px;
  margin-top: 5px;
  width: 80px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const HeaderWrapper = styled.div`
  padding: 2em;
  margin-bottom: 1em;
`

const Spacer = styled.div`
  height: 0.5em;
  width: 100%;
`

const FeedWrapper = styled.div`
`

const ViewWrapper = styled.div.attrs(({ $top, $bottom }) => ({
  $top, $bottom
}))`
  overflow: auto;
  @media (min-width: 600px) {
    width: calc(100% - 100px);
    height: calc(100vh - 50px - ${({ $top }) => $top}px);
  }
  @media (max-width: 600px) {
    height: calc(100vh - 150px - ${({ $top, $bottom }) => $top + $bottom}px);
    margin-top: calc(100px + ${({ $top }) => $top}px);
  }
`

const FilterIcon = styled.img`
  position: relative;
  top: 5px;
  margin-right: 5px;
`

const FilterWrapper = styled.div`
  float: right;
`

const PostButton = styled.div`
  float: right;
  margin: 0.25em 0;
  margin-right: 0.5em;
`

const PostText = styled.div`
  top: -0.125em;
  position: relative;
  display: inline;
`

const PlusIcon = styled.img`
  margin: -0.25em;
  margin-top: 0;
  margin-left: 0.125em;
`

const ModalWrapper = styled.div.attrs(({ $isMessage }) => ({
  $isMessage
}))`
  margin: 0.5em;
  min-height: ${({ $isMessage }) => $isMessage ? 'unset' : '80vh'};
`

const ModalButtons = styled.div`
  text-align: center;
  margin: 0.5em;
  & > * {
    margin: 0.25em;
  }
`

const SelectedBookWrapper = styled.div`
  text-align: center;
  margin: 1em 0;
`

const BookImg = styled.img`

`

const MessageWrapper = styled.div`
  & * {
    text-align: center;
  }
`

const NoFriendsWrapper = styled.div`
  white-space: normal;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  padding: 1em;
`

export const FeedPage = () => {
  const { safearea, username, userIsLoggedIn } = useAppContext();
  const [story, setStory] = useState(false);
  const [selectedBook, setSelectedBook] = useState(false)
  const [books, setBooks] = useState([])

  const [cursor, setCursor] = useState(0)
  const [friendsOnly, setFriendsOnly] = useState(true)
  const [booksResult, refetchBooks] = useQuery({
    query: LoadFeedBooksQuery,
    variables: {
      input: {
        cursor,
        friends_only: friendsOnly
      }
    },
    pause: !userIsLoggedIn,
    requestPolicy: 'cache-and-network',
  });

  const newBooks = booksResult?.data?.loadFeedBooks?.books
  const hasMore = booksResult?.data?.loadFeedBooks?.has_more

  useEffect(() => {
    if (!newBooks || newBooks?.length === 0) return
    setBooks([...books, ...newBooks])
  }, [newBooks])

  const [friendIdx, setFriendIdx] = useState(-1)
  const [friendsCursor, setFriendsCursor] = useState(0)
  const [friendsResult, refetchFriends] = useQuery({
    query: GetFriendsUpdatesQuery,
    variables: {
      input: {
        cursor: friendsCursor
      }
    },
    pause: !userIsLoggedIn,
    requestPolicy: 'cache-and-network',
  });

  const friends = friendsResult?.data?.topFriends?.users

  const [hasNewNotifs, refetchHasNewNotifs] = useQuery({
    query: HasNewNotificationsQuery,
    variables: {
      input: {}
    },
    pause: !userIsLoggedIn,
    requestPolicy: 'cache-and-network',
  });
  const hasUpdates = hasNewNotifs?.data?.hasNewNotifications?.ok

  const [postModal, setPostModal] = useState(false)

  useEffect(() => {
    if (username) {
      refetchFriends()
    }
  }, [username])

  useEffect(() => {
    if (friendIdx < 0) return
    setStory({ friend: friends[friendIdx], lastFriend: friendIdx === friends.length - 1 })
  }, [friendIdx])

  const [keywords, setKeywords] = useState("");
  const [searchResult, refetchSearch] = useQuery({
    query: AutocompleteBooksQuery,
    variables: {
      input: {
        keywords
      }
    },
    pause: keywords === ''
  });

  const [, leaveComment] = useMutation(LeaveCommentMutation)

  return (
    <ApiProvider>
      <Page hasUpdates={hasUpdates} refetchHasNewNotifs={refetchHasNewNotifs}>
        {postModal ? <Modal onClose={() => {
          setPostModal(false)
        }}>
          <ModalWrapper $isMessage={postModal === 'done'}>
            {postModal === 'done' ? <MessageWrapper>
              <Caption>{intl.get('feed.postCreated')}</Caption>
              <ModalButtons>
                <PrimaryButton onClick={() => {
                  setPostModal(false)
                  setSelectedBook(false)
                }}>{intl.get('actions.close')}</PrimaryButton>
              </ModalButtons>
            </MessageWrapper> : <>
              <Label>{intl.get('feed.createPost')}</Label>
              <Spacer />
              <Input debounce placeholder={intl.get('feed.searchBook')} onChange={(e, v) => {
                setKeywords(v)
              }} autocompleteList={searchResult?.data?.searchBooks?.books.map((book, i) => {
                return {
                  id: book.book_id,
                  img_url: book.img_url,
                  text: `${book.title}${book.authors ? ` - ${book.authors.join(', ')}` : ''}`
                }
              })} onAutocomplete={(id, i) => {
                setSelectedBook(searchResult?.data?.searchBooks?.books[i])
              }} />
              <Spacer />
              {selectedBook ? <SelectedBookWrapper>
                <BookImg src={selectedBook.img_url} />
                <Label $center>{selectedBook.title} - {selectedBook?.authors?.join(', ')}</Label>
              </SelectedBookWrapper> : ''}
              <Form onSubmit={(data) => {
                if (!data?.comment) return
                leaveComment({ input: { book_id: selectedBook.book_id, content: data.comment } }).then((resp) => {
                  if (resp.error) {
                    // TODO show toast
                    console.log('failed to leave comment', resp.error)
                    return
                  }
                  setPostModal('done')
                })
              }}>
                {selectedBook ? <Input
                  type="textarea"
                  name="comment"
                  actionIcon="none"
                  placeholder={intl.get('labels.enterComment')}
                /> : ''}
                <ModalButtons>
                  {selectedBook ? <PrimaryButton>{intl.get('feed.post')}</PrimaryButton> : ''}
                  <QuaternaryButton onClick={() => {
                    setPostModal(false)
                  }}>{intl.get('actions.cancel')}</QuaternaryButton>
                </ModalButtons>
              </Form>
            </>}
          </ModalWrapper>
        </Modal> : ''}
        <BG />
        {story ? <Story {...story}
          onClose={() => setStory(false)}
          onNext={() => {
            setFriendIdx(Math.min(friendIdx + 1, friends.length - 1))
          }}
          onPrev={() => {
            setFriendIdx(Math.max(friendIdx - 1, 0))
          }}
          lastFriend={friendIdx === friends.length - 1}
        /> : ''}
        <Content>
          <UpdatesWrapper $top={safearea.top} $bottom={safearea.bottom}>
            {friends?.length > 0 ? friends.map((friend, i) => {
              return <UpdateWrapper key={i} onClick={() => {
                setStory({ friend })
                setFriendIdx(i)
              }}>
                <Pfp $hasUpdates={friend.has_updates} src={friend.pfp_url || PfpPlaceholderImg} />
                <Username>{friend.username}</Username>
              </UpdateWrapper>
            }) : <NoFriendsWrapper>
              {intl.get('labels.noFriendsYet')}
            </NoFriendsWrapper>}
          </UpdatesWrapper>
          <ViewWrapper $top={safearea.top} $bottom={safearea.bottom} onScroll={(e) => {
            if (e.target.scrollTop + 100 > e.target.scrollHeight - e.target.clientHeight && hasMore) {
              setCursor(books.length)
            }
          }}>
            <HeaderWrapper>
              <Container>
                <Row>
                  <Col sm={4}>
                    <Spacer />
                    <Label>{intl.get('feed.title')}</Label>
                    <Spacer />
                    <Caption>{intl.get('feed.caption')}</Caption>
                  </Col>
                  <Col sm={8}>
                    <FilterWrapper>
                      <Selections label={<FilterIcon src={FilterSvg} />} defaultValue={friendsOnly ? 'friends' : 'everyone'} options={[
                        { key: 'everyone', name: intl.get('feed.everyone') },
                        { key: 'friends', name: intl.get('feed.friends') }
                      ]} onSelect={(selection) => {
                        setFriendsOnly(selection['everyone'] ? false : true)
                      }} />
                    </FilterWrapper>
                    <PostButton onClick={() => {
                      setPostModal(true)
                    }}>
                      <PrimaryButton>
                        <PostText>{intl.get('feed.post')}</PostText>
                        <PlusIcon src={AddSvg} />
                      </PrimaryButton>
                    </PostButton>
                  </Col>
                </Row>
              </Container>
            </HeaderWrapper>
            <FeedWrapper>
              {books?.length > 0 ? books?.map((item, i) => {
                return <FriendBook key={i} refetch={refetchBooks} {...item.book} {...item} />
              }) : intl.get('feed.noPosts', { people: friendsOnly ? intl.get('feed.friends') : intl.get('feed.everyone') })}
            </FeedWrapper>
          </ViewWrapper>
        </Content>
      </Page>
    </ApiProvider>
  );
};
