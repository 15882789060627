import { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "utils/colors";
import $ from 'jquery'

import CloseSvg from 'assets/icons/close.svg'
import { BarRating } from "./rating";
import intl from "react-intl-universal";
import { Capacitor } from "@capacitor/core";
import { useSwipeable } from "react-swipeable";
import { useAppContext } from "./page";
import { useQuery } from "urql";


import PfpPlaceholderImg from 'assets/imgs/pfp-placeholder.jpeg'
import { GetStoriesQuery } from "queries/notification";
import { NonInteractiveComment } from "./comment";
import { SecondaryButton } from "./buttons";

const Wrapper = styled.div.attrs(({ $top }) => ({
    $top
}))`
    position: fixed;
    top: ${({ $top }) => $top}px;
    z-index: 10;
    width: 100%;
    height: 100vh;

    & * {
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
`

const Backdrop = styled.div`
    position: absolute;
    width: 100%;
    height: 100vh;
    background: ${colors.black};
`

const Content = styled.div`
    z-index: 10;
    position: absolute;
    width: 100%;
    max-width: 600px;
    height: 100vh;
    overflow: auto;
    background: ${colors.white};
    left: 50%;
    transform: translateX(-50%);
    order: 2;
    transition-property: margin-left;
    transition-duration: 0.1s;
`

const CloseButton = styled.img`
    width: 32px;
    height: 32px;
    position: absolute;
    top: calc(0.5em + 20px);
    right: 0.5em;
    cursor: pointer;
    border-radius: 8px;
    z-index: 10;

    &:hover {
        background-color: ${colors.lightgrey};
    }
`

const BookWrapper = styled.div`
    position: relative;
    margin: auto;
    top: calc(32px + 3em);
    text-align: center;
`

const BookImg = styled.div`
    background-image: url(${({ src }) => src});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    max-width: 100%;
    height: 30vh;
    margin: 1em 0;
`

const NameText = styled.div`
    font-family: Nourd-Bold;
    font-size: 24px;
`

const AuthorText = styled.div`
    font-family: Nourd-Light;
    font-size: 20px;
`

const FriendRating = styled.div`
    font-family: Nourd-Light;
    font-size: 14px;
    margin: 0.5em auto;
`

const Indicator = styled.div`
    position: absolute;
    top: 0;
    margin: 0.25em 0.5em;
    z-index: 10;
    width: calc(100% - 1em);
`

const Segment = styled.div.attrs(({ $count, $viewed }) => ({
    $count, $viewed
}))`
    display: inline-flex;
    width: calc(${({ $count }) => 100 / $count}% - 0.5em);
    margin: 0.25em;
    height: 5px;
    border-radius: 5px;
    background-color: ${({ $selected, $viewed }) => $viewed ? colors.grey : colors.midgrey};
    position: relative;
`

const SegmentProgress = styled.div`
    position: absolute;
    height: 5px;
    border-radius: 5px;
    animation: progress 5s forwards;
    animation-timing-function: linear;
    background-color: ${colors.grey};

    @keyframes progress {
        from {width: 0;}
        to {width: 100%;}
    }
`

const FriendWrapper = styled.div`
    position: absolute;
    margin-left: 10px;
    top: 20px;
    width: calc(100% - 32px - 0.5em);
`

const FriendName = styled.div`
    position: absolute;
    left: calc(32px + 1em);
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
`

const Pfp = styled.img`
    margin: 0.25em;
    width: calc(32px + 0.5em);
    height: calc(32px + 0.5em);
    border-radius: 50px;
`

const GenereText = styled.div`
    display: inline-block;
    font-size: 14px;
    border-radius: 6px;
    background-color: ${colors.darkgrey};
    color: ${colors.white};
    padding: 0.25em 0.5em;
    margin: 0.25em;
    max-width: calc(100% - 2em);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`

const CommentsWrapper = styled.div`
    padding: 0 1em;
    margin-top: 3em;
`

const DiscussButton = styled.div`
    text-align: center;
    position: absolute;
    bottom: 1em;
    width: 100%;
`

const NoUpdatesWrapper = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`

let timeout

export const Story = ({ friend, onClose, onNext, onPrev, lastFriend }) => {
    const { userIsLoggedIn, safearea } = useAppContext();
    const [idx, setIdx] = useState(0);
    const [swiping, setSwiping] = useState(false);
    const [paused, setPaused] = useState(false)

    const [storiesResult, refetchStories] = useQuery({
        query: GetStoriesQuery,
        variables: {
            input: {
                username: friend.username
            }
        },
        pause: !userIsLoggedIn,
        requestPolicy: 'cache-and-network',
    });

    const stories = storiesResult?.data?.getStories?.stories || []
    const book = stories[idx]?.book || {}
    const comment1 = stories[idx]?.parent_comment || {}
    const comment2 = stories[idx]?.comment || {}
    const parentComment = comment1?.thread_id === comment2?.comment_id ? comment2 : comment1
    const comment = comment1?.thread_id === comment2?.comment_id ? comment1 : comment2
    const stats = {}
    stories.map((s) => {
        if (s.parent_comment) {
            stats[s.parent_comment.comment_id] = s.parent_comment
        }
        if (s.comment) {
            stats[s.comment.comment_id] = s.comment
        }
    })

    useEffect(() => {
        if (timeout) clearTimeout(timeout)
        timeout = setTimeout(() => {
            if (paused) return
            if (idx + 1 >= stories.length) {
                if (lastFriend) return
                if (onNext) {
                    onNext()
                    setIdx(0)
                }
                return
            }
            setIdx(idx + 1)
        }, 5000)
    }, [idx])

    const onClick = (e) => {
        if ((e.touches && !e.touches?.length) || swiping) return;
        const x = (e.touches ? e.touches[0].pageX : e.pageX);
        if (x < $(window).width() / 2) {
            if (idx === 0) return
            setIdx(idx - 1)
        } else {
            if (idx + 1 === stories.length) return
            setIdx(idx + 1)
        }
    }

    const handlers = useSwipeable({
        trackMouse: true,
        onSwipedLeft: (e) => {
            if (onNext && Math.abs(e.deltaX) > 100) onNext()
            $('.cmp-story .subcmp-content').css('margin-left', '0px')
        },
        onSwipedRight: (e) => {
            if (onPrev && Math.abs(e.deltaX) > 100) onPrev()
            $('.cmp-story .subcmp-content').css('margin-left', '0px')
        },
        onSwiping: (e) => {
            setSwiping(true)
            $('.cmp-story .subcmp-content').css('margin-left', `${e.deltaX}px`)
        },
        onSwiped: (e) => {
            setIdx(0)
            setTimeout(() => {
                setSwiping(false)
            }, 100)
        },
    });
    if (Capacitor.getPlatform() === 'web') {
        handlers.onClick = onClick
    } else {
        handlers.onTouchStart = onClick
    }

    return <Wrapper className="cmp-story" $top={safearea.top}>
        <Backdrop />
        <Content
            className="subcmp-content"
            {...handlers}
        >
            <CloseButton draggable={false} src={CloseSvg} onClick={(e) => {
                if (onClose) onClose(e)
            }} />
            <FriendWrapper>
                <Pfp src={friend.pfp_url || PfpPlaceholderImg} />
                <FriendName>{friend.username}</FriendName>
            </FriendWrapper>
            {Object.keys(book).length ? <>
                <BookWrapper>
                    <BookImg draggable={false} src={book?.img_url} />
                    <NameText>{book?.title}</NameText>
                    <AuthorText>{(book?.authors || []).join(', ')}</AuthorText>
                    {(book?.genres || [intl.get('labels.unknown')]).map((genre, i) => {
                        return <GenereText key={i}>{genre}</GenereText>
                    })}
                    <FriendRating>
                        <BarRating rating={book?.friend_rating || 0} />
                    </FriendRating>
                </BookWrapper>
                <CommentsWrapper>
                    {parentComment?.comment_id ? <NonInteractiveComment comment={parentComment} i={0} stats={stats} isSubComment={false} /> : ''}
                    {comment?.comment_id ? <NonInteractiveComment comment={comment} i={0} stats={stats} isSubComment={parentComment?.comment_id} /> : ''}
                </CommentsWrapper>
                {/* <DiscussButton>
                <SecondaryButton>
                    {intl.get('actions.discuss')}
                </SecondaryButton>
            </DiscussButton> */}
            </> : <NoUpdatesWrapper>
                {intl.get('labels.noRecentUpdates')}
            </NoUpdatesWrapper>}
            <Indicator>
                {stories.map((_, i) => {
                    return <Segment key={i} $count={stories.length} $viewed={idx > i}>
                        {idx === i ? <SegmentProgress /> : ''}
                    </Segment>
                })}
            </Indicator>
        </Content>
    </Wrapper>
}