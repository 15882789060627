/* eslint-disable react-hooks/exhaustive-deps */

import intl from "react-intl-universal";
import { Client, Provider, cacheExchange, fetchExchange } from "urql";
import { authExchange } from "@urql/exchange-auth";
import { getLocale } from "utils/common";
import { _accessToken } from "components/page";

import LanguagePack from 'assets/locale/en-US.json'

const locale = getLocale();
const locales = {};
locales[locale] = LanguagePack//require(`assets/locale/${locale}.json`);
intl.init({
    currentLocale: locale,
    locales,
    warningHandler: e => {
        if (!e.includes("format message failed")) console.warn(e);
    },
});

document.addEventListener(
    "dragover",
    e => {
        e.preventDefault();
    },
    false
);

export const GraphQLHost = process.env.NODE_ENV === "development"
    ? "http://localhost:8080/query"
    : "https://bookboos.com/query"

export const HTTPHost = process.env.NODE_ENV === "development"
    ? "http://localhost:8080"
    : "https://bookboos.com"

export const ApiProvider = ({ children }) => {
    const client = new Client({
        url: GraphQLHost,
        exchanges: [
            cacheExchange,
            authExchange(async utils => {
                return {
                    addAuthToOperation(operation) {
                        return utils.appendHeaders(operation, {
                            Authorization: _accessToken ? `Bearer ${_accessToken}` : "",
                            "X-Path": `gql/${operation.query?.definitions[0]?.name?.value}`,
                        });
                    },
                };
            }),
            fetchExchange,
        ],
    });
    return <Provider value={client}>{children}</Provider>;
};
