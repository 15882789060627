import styled from "styled-components";
import intl from "react-intl-universal";
import { colors } from "utils/colors";
import { Caption, Label } from "components/label";
import { Rating } from "components/rating";
import { QuaternaryButton, SecondaryButton, TertiaryButton } from "components/buttons";
import { useMutation, useQuery } from "urql";
import { GetBookDetailsQuery, SaveBookMutation, UnsaveBookMutation } from "queries/book";
import { useAppContext } from "components/page";

const Wrapper = styled.div`
    padding: 0 2em;
    padding-top: calc(32px + 0.5em);
    text-align: center;
`

const BookImg = styled.img`
    width: 200px;
`

const GenereText = styled.div`
    display: inline-block;
    font-size: 14px;
    border-radius: 6px;
    background-color: ${colors.darkgrey};
    color: ${colors.white};
    padding: 0.25em 0.5em;
    margin: 0.25em;
    max-width: calc(100% - 2em);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`

const Buttons = styled.div`
    & > * {
        margin: 0.5em;
    }
`

export const BookDetails = ({ book_id, onAction }) => {
    const { userIsLoggedIn } = useAppContext();
    const [, saveBook] = useMutation(SaveBookMutation)
    const [, unsaveBook] = useMutation(UnsaveBookMutation)
    const [detailsResult, refetchDetails] = useQuery({
        query: GetBookDetailsQuery,
        variables: {
            input: {
                book_id
            }
        },
        pause: !userIsLoggedIn,
        requestPolicy: 'cache-and-network',
    });

    const { img_url, title, authors, genres, rating } = detailsResult?.data?.getBookDetails?.book || {}
    const saved = detailsResult?.data?.getBookDetails?.saved

    return <Wrapper>
        <BookImg src={img_url} />
        <Label $center>{title}</Label>
        <Caption $center>{authors?.join(", ")}</Caption>
        {genres || [intl.get('labels.unknown')].map((genre, i) => {
            return <GenereText key={i}>{genre}</GenereText>
        })}
        <Rating rating={rating} />
        <Buttons >
            {saved ? <SecondaryButton onClick={() => {
                unsaveBook({ input: { book_id } }).then((resp) => {
                    if (resp.error) {
                        // TODO show toast
                        console.log('failed to save', resp.error)
                        return
                    }
                    refetchDetails()
                    if (onAction) onAction()
                })
            }}>{intl.get('actions.unsaveBooks')}</SecondaryButton> : <SecondaryButton onClick={() => {
                saveBook({ input: { book_id } }).then((resp) => {
                    if (resp.error) {
                        // TODO show toast
                        console.log('failed to save', resp.error)
                        return
                    }
                    refetchDetails()
                    if (onAction) onAction()
                })
            }}>{intl.get('actions.saveMyBooks')}</SecondaryButton>}
        </Buttons>
    </Wrapper>
}