import { gql } from 'urql';

export const SearchBooksQuery = gql`
query SearchBooks($input: SearchBooksRequest!){
    searchBooks(input: $input){
        books {
            book_id
            title
            authors
            img_url
            rating
            genres
        }
    }
}
`

export const GetBookDetailsQuery = gql`
query GetBookDetails($input: GetBookDetailsRequest!){
    getBookDetails(input: $input){
        book {
            book_id
            title
            authors
            img_url
            rating
            genres
        }
        saved
    }
}
`

export const AutocompleteBooksQuery = gql`
query SearchBooks($input: SearchBooksRequest!){
    searchBooks(input: $input){
        books {
            book_id
            title
            authors
            img_url
        }
    }
}
`

export const SaveBookMutation = gql`
mutation SaveBook($input: SaveBookRequest!){
    saveBook(input: $input){
        ok
    }
}
`

export const UnsaveBookMutation = gql`
mutation UnsaveBook($input: UnsaveBookRequest!){
    unsaveBook(input: $input){
        ok
    }
}
`

export const RateBookMutation = gql`
mutation RateBook($input: RateBookRequest!){
    rateBook(input: $input){
        ok
    }
}
`

export const ListBooksQuery = gql`
query ListBooks($input: ListBooksRequest!){
    listBooks(input: $input){
        books {
            book {
                book_id
                title
                authors
                genres
                img_url
            }
            personal_rating
            status
            bookmarked_at
            person {
                username
                pfp_url
            }
        }
        has_more
    }
}
`

export const LoadFeedBooksQuery = gql`
query LoadFeedBooks($input: LoadFeedBooksRequest!){
    loadFeedBooks(input: $input){
        books {
            book {
                book_id
                title
                authors
                genres
                img_url
            }
            personal_rating
            status
            bookmarked_at
            person {
                username
                pfp_url
            }
        }
        has_more
    }
}
`