import intl from "react-intl-universal";

export const getLocale = () => {
    return navigator.languages && navigator.languages.length
        ? navigator.languages[0]
        : navigator.language;
}

export const shortenCount = num => {
    const value = Math.trunc(num)
    if (value < 1000) {
        return value
    }
    const maginitudes = [1000, 1000000, 1000000000, 1000000000000]
    const unit = ['K', 'M', 'B', 'T']
    for (let i in maginitudes) {
        const shortenedNum = value / maginitudes[i]
        if (shortenedNum < 1000) {
            return shortenedNum.toFixed(1) + unit[i]
        }
    }
    return parseInt(value) || 0
}

export const shortenTimestamp = (ts) => {
    if (typeof ts === 'string') ts = new Date(parseInt(ts))
    const seconds = Math.floor((new Date() - ts) / 1000)
    const daysAgo = seconds / 86400
    if (daysAgo > 3) return ts.toLocaleDateString(intl.get('locale'))
    if (daysAgo > 1)
        return intl.get('duration.days', { duration: Math.floor(daysAgo) })
    const hoursAgo = seconds / 3600
    if (hoursAgo > 1)
        return intl.get('duration.hours', { duration: Math.floor(hoursAgo) })
    const minutesAgo = seconds / 60
    if (minutesAgo > 1)
        return intl.get('duration.minutes', { duration: Math.floor(minutesAgo) })
    if (seconds < 10)
        return intl.get('duration.now')
    return intl.get('duration.seconds', { duration: Math.floor(seconds) })
}

export const sdbm = str => {
    let arr = str.split('');
    return arr.reduce(
        (hashCode, currentVal) =>
            (hashCode = currentVal.charCodeAt(0) + (hashCode << 6) + (hashCode << 16) - hashCode),
        0
    );
};

export const randomString = (length) => {
    return (Math.random() + 1).toString(36).substring(length)
}

export const setCaretPosition = (elem, caretPos) => {
    if (!elem) return
    elem.setSelectionRange(caretPos, caretPos);
}