import styled from "styled-components";

import CloseSvg from 'assets/icons/close.svg'
import { colors } from "utils/colors";

const Wrapper = styled.div`
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
`

const Backdrop = styled.div`
    width: 100%;
    height: 100vh;
    position: absolute;
    background: rgba(0,0,0,0.5);
`

const Popup = styled.div.attrs(({ $backgroundColor }) => ({
    $backgroundColor
}))`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: ${({ $backgroundColor }) => $backgroundColor || colors.white};
    padding: 0.5em;
    border-radius: 16px;
    width: 90%;
    max-width: 600px;
    overflow: auto;
    max-height: 90vh;
    
    @media (max-width: 600px) {
        width: calc(100% - 3em);
        max-height: 80vh;
    }
`

const CloseButton = styled.img`
    width: 32px;
    height: 32px;
    position: absolute;
    right: 0.5em;
    cursor: pointer;
    border-radius: 8px;

    &:hover {
        background-color: ${colors.lightgrey};
    }
`

export const Modal = ({ children, onClose, backgroundColor }) => {
    return <Wrapper className="cmp-modal">
        <Backdrop onClick={(e) => {
            if (onClose) onClose(e)
        }} />
        <Popup $backgroundColor={backgroundColor}>
            <CloseButton src={CloseSvg} onClick={(e) => {
                if (onClose) onClose(e)
            }} />
            {children}
        </Popup>
    </Wrapper>
}