import { useState } from "react";
import styled from "styled-components";
import { colors } from "utils/colors";

const Wrapper = styled.div`
`

const Option = styled.div.attrs(({ $selected }) => ({
    $selected
}))`
    border: solid 2px ${colors.cyan};
    background-color: ${({ $selected }) => $selected ? colors.cyan : colors.white};
    color: ${({ $selected }) => $selected ? colors.white : colors.darkgrey};
    padding: 0.25em 0.5em;
    display: inline-block;
    margin: 0.5em 0.25em;
    border-radius: 8px;
    cursor: pointer;
`

export const Selections = ({ label, defaultValue, options, multiSelect, onSelect }) => {
    const [selected, setSelected] = useState({ [defaultValue]: true })
    return <Wrapper className="cmp-selection">
        {label || ''}
        {options.map((opt, i) => {
            return <Option key={i} $selected={selected[opt.key]} onClick={() => {
                if (multiSelect) {
                    selected[opt.key] = !selected[opt.key]
                    setSelected({ ...selected })
                    if (onSelect) onSelect(selected)
                } else {
                    setSelected({ [opt.key]: true })
                    if (onSelect) onSelect({ [opt.key]: true })
                }
            }}>
                {opt.name}
            </Option>
        })}
    </Wrapper>
}