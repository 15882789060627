import intl from "react-intl-universal";
import styled from "styled-components";

import { colors } from "utils/colors";
import { shortenCount, shortenTimestamp } from "utils/common";
import { QuaternaryButton, SecondaryButton, TertiaryButton } from "components/buttons";
import { Input } from "components/input";
import { Form } from "components/form";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "urql";
import { DeleteCommentMutation, EditCommentMutation, GetCommentStatsQuery, LeaveCommentMutation, LikeCommentMutation, ListCommentsQuery, UnlikeCommentMutation } from "queries/comment";
import { useAppContext } from "./page";

import PfpPlaceholderImg from 'assets/imgs/pfp-placeholder.jpeg'
import HeartSvg from 'assets/icons/heart.svg'
import EmptyHeartSvg from 'assets/icons/heart-empty.svg'
import CommentSvg from 'assets/icons/comment.svg'
import DeleteSvg from 'assets/icons/delete.svg'
import EditSvg from 'assets/icons/edit.svg'

const Wrapper = styled.div`
    width: 100%;
`

const CommentBox = styled.div.attrs(({ $reviewed, $isSubComment, $expanded }) => ({
    $reviewed, $isSubComment, $expanded
}))`
    background-color: ${colors.white};
    width: ${({ $isSubComment }) => $isSubComment ? 'calc(100% - 4em)' : 'calc(100% - 2em)'};
    padding: 1em;
    border-radius: 5px;
    text-align: ${({ $reviewed }) => $reviewed ? 'left' : 'center'};
    position: relative;
    margin-bottom: 1em;
    margin-left: ${({ $isSubComment }) => $isSubComment ? '2em' : 'initial'};
    border-left: ${({ $expanded }) => $expanded ? `solid 5px ${colors.cyan}` : 'initial'};
    -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.15);
    overflow: hidden;
`

const UsernameBox = styled.div`
    font-family: Nourd-bold;
    top: -0.5em;
    position: relative;
`

const ActionBox = styled.div`
    text-align: right;
    bottom: -0.5em;
    position: relative;
`

const CommentContent = styled.div`
    padding: 0.5em;
`

const Icon = styled.img`
    position: relative;
    top: 0.3em;
    margin: 0 0.25em;
    cursor: pointer;
`

const Pfp = styled.img`
    width: 24px;
    height: 24px;
    position: relative;
    bottom: -6px;
    border-radius: 24px;
    margin-right: 0.5em;
`

const InputWrapper = styled.div`
    width: 100%;
`

const Buttons = styled.div`
    margin-top: 0.5em;
    text-align: center;

    & > * {
        margin: 0 0.5em;
    }
`

const MoreButton = styled.div`
    margin: auto;
`

const Timestamp = styled.div`
    position: absolute;
    bottom: 0.5em;
    color: ${colors.midgrey};
`

const DeleteButton = styled.img`
    position: absolute;
    right: 0;
    top: 0;
    margin: 0.5em;
    padding: 0.25em;
    border-radius: 8px;
    cursor: pointer;

    &:hover{
        background-color: ${colors.lightgrey};
    }
`

const ConfirmDeleteButtons = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    left: 0;
    top: 0;
    z-index: 5;
    text-align: center;

    & * {
        margin: 0.5em;
    }
`

const ButtonsWrapper = styled.div`
    position: relative;
    top: 50%;
    transform: translateY(-50%);
`


const EditButton = styled.img`
    position: relative;
    display: inline;
    padding: 0.25em;
    border-radius: 8px;
    cursor: pointer;
    top: 10px;
    margin-left: 2px;

    &:hover{
        background-color: ${colors.lightgrey};
    }
`

const ReplyingBox = styled.div`
    padding-top : 0.5em;
    padding-left : 0.25em;
    color: ${colors.midgrey};
    text-align: left;
`

const TextButton = styled.div`
    display: inline;
    margin-left: 0.5em;
    color: ${colors.linkblue};
    cursor: pointer;
`

const Spacer = styled.div`
  height: 1em;
  width: 100%;
`

export const NonInteractiveComment = ({ comment, i, isSubComment, stats }) => {
    return <CommentBox $isSubComment={isSubComment} $expanded={true} key={i}>
        <UsernameBox>
            <Pfp src={comment.pfp_url || PfpPlaceholderImg} />
            {comment.username}
        </UsernameBox>
        <CommentContent>
            {comment.content}
        </CommentContent>
        <ActionBox>
            <Icon src={stats[comment.comment_id]?.liked ? HeartSvg : EmptyHeartSvg} />
            {shortenCount(stats[comment.comment_id]?.likes)}
            {!isSubComment ? <>
                <Icon src={CommentSvg} />{shortenCount(stats[comment.comment_id]?.comments)}
            </> : ''}
            <Timestamp>{shortenTimestamp(new Date((comment.edited_at || comment.created_at) * 1000))}</Timestamp>
        </ActionBox>
    </CommentBox>
}

export const CommentsSection = ({ book_id, person }) => {
    const { userIsLoggedIn, username } = useAppContext();
    const [, likeComment] = useMutation(LikeCommentMutation)
    const [, unlikeComment] = useMutation(UnlikeCommentMutation)
    const [, leaveComment] = useMutation(LeaveCommentMutation)
    const [, editComment] = useMutation(EditCommentMutation)
    const [, deleteComment] = useMutation(DeleteCommentMutation)

    const [confirmDelete, setConfirmDelete] = useState(false)
    const [editing, setEditing] = useState(false)
    const [overrideComment, setOverrideComment] = useState(false)
    const [stats, setStats] = useState({})
    const [statsUpdated, setStatsUpdated] = useState(false)

    const [comments, setComments] = useState([])
    const [commentsUpdated, setCommentsUpdated] = useState(false)
    const [subComments, setSubComments] = useState([])
    const [subCommentsUpdated, setSubCommentsUpdated] = useState(false)

    const [replyToThreadId, setReplyToThreadId] = useState(person.username)
    const [commentsCursor, setCommentsCursor] = useState(0)
    const [commentsResult, refetchComments] = useQuery({
        query: ListCommentsQuery,
        variables: {
            input: {
                username: person.username,
                book_id: book_id,
                thread_id: replyToThreadId,
                cursor: commentsCursor
            }
        },
        pause: !userIsLoggedIn,
        requestPolicy: 'cache-and-network',
    });
    const newComments = commentsResult?.data?.listComments?.comments || []
    const hasMore = commentsResult?.data?.listComments?.has_more

    const [subThreadId, setSubThreadId] = useState(false)
    const [subThreadCursor, setSubThreadCursor] = useState(0)
    const [subThreadResult, refetchSubThread] = useQuery({
        query: ListCommentsQuery,
        variables: {
            input: {
                username: person.username,
                book_id: book_id,
                thread_id: subThreadId,
                cursor: subThreadCursor
            }
        },
        pause: !userIsLoggedIn || !subThreadId,
        requestPolicy: 'cache-and-network',
    });
    const newSubComments = subThreadResult?.data?.listComments?.comments || []
    const subHasMore = subThreadResult?.data?.listComments?.has_more

    const [statsResult, refetchStats] = useQuery({
        query: GetCommentStatsQuery,
        variables: {
            input: {
                comment_ids: comments?.map((c) => c.comment_id).concat(subComments?.map((c) => c.comment_id))
            }
        },
        pause: !userIsLoggedIn || (comments?.length === 0 && subComments?.length === 0),
        requestPolicy: 'cache-and-network',
    });
    const newStats = statsResult?.data?.getCommentStats?.stats || []

    useEffect(() => {
        if (commentsUpdated || newComments.length === 0) return
        if (commentsCursor === 0)
            setComments([...newComments])
        else {
            comments.push(...newComments)
            setComments([...comments])
        }
        setCommentsUpdated(true)
    }, [newComments])

    useEffect(() => {
        if (newSubComments.length === 0) return
        if (subThreadCursor === 0)
            setSubComments([...newSubComments])
        else {
            if (subCommentsUpdated) return
            subComments.push(...newSubComments)
            setSubComments([...subComments])
        }
        setSubCommentsUpdated(true)
        setStatsUpdated(false)
        refetchStats()
    }, [newSubComments])

    useEffect(() => {
        if (statsUpdated || newStats.length === 0) return
        newStats.map(stat => {
            stats[stat.comment_id] = stat
        })
        setStats({ ...stats })
        setStatsUpdated(true)
    }, [newStats])


    const Comment = ({ comment, i, isSubComment }) => {
        return <CommentBox $reviewed $isSubComment={isSubComment} $expanded={subThreadId === comment.comment_id} key={i}>
            <UsernameBox>
                <Pfp src={comment.pfp_url || PfpPlaceholderImg} />
                {comment.username}
            </UsernameBox>
            {username === comment.username ? <DeleteButton src={DeleteSvg} onClick={() => {
                setConfirmDelete({ ...comment, idx: i, isSubComment })
            }} /> : ''}
            {confirmDelete?.idx === i && isSubComment === confirmDelete.isSubComment ? <ConfirmDeleteButtons onClose={() => {
                setConfirmDelete(false)
            }}>
                <ButtonsWrapper>
                    <SecondaryButton onClick={(e) => {
                        deleteComment({
                            input: {
                                book_id: confirmDelete.book_id,
                                thread_id: confirmDelete.thread_id,
                                comment_id: confirmDelete.comment_id,
                            }
                        }).then((resp) => {
                            if (resp.error) {
                                // TODO show toast
                                console.log('failed to leave comment', resp.error)
                                return
                            }
                            setConfirmDelete(false)
                            if (isSubComment) {
                                setSubThreadCursor(confirmDelete.idx)
                                setSubComments(comments.slice(0, confirmDelete.idx))
                                setSubCommentsUpdated(false)
                                refetchSubThread()
                            } else {
                                setCommentsUpdated(false)
                                setCommentsCursor(confirmDelete.idx)
                                setComments(comments.slice(0, confirmDelete.idx))
                                refetchComments()
                            }
                        })
                    }}>
                        {intl.get('actions.confirmDelete')}
                    </SecondaryButton>
                    <TertiaryButton onClick={(e) => {
                        setConfirmDelete(false)
                    }}>
                        {intl.get('actions.cancel')}
                    </TertiaryButton>
                </ButtonsWrapper>
            </ConfirmDeleteButtons> : ''}
            {editing?.idx === i && isSubComment === editing.isSubComment ? <InputWrapper>
                <Form onSubmit={(data) => {
                    if (!data?.comment) return
                    editComment({ input: { book_id: editing.book_id, thread_id: editing.thread_id, comment_id: editing.comment_id, content: data.comment } }).then((resp) => {
                        if (resp.error) {
                            // TODO show toast
                            console.log('failed to leave comment', resp.error)
                            return
                        }
                        setEditing(false)
                        setOverrideComment('')
                        if (isSubComment) {
                            setSubThreadCursor(editing.idx)
                            setSubComments(comments.slice(0, editing.idx))
                            setSubCommentsUpdated(false)
                            refetchSubThread()
                        } else {
                            setCommentsUpdated(false)
                            setCommentsCursor(editing.idx)
                            setComments(comments.slice(0, editing.idx))
                            refetchComments()
                        }
                    })
                }}>
                    <Input
                        type="textarea"
                        name="comment"
                        actionIcon="none"
                        defaultValue={comment.content}
                        placeholder={intl.get('labels.enterComment')}
                        overrideValue={overrideComment || comment.content}
                        onChange={() => {
                            setOverrideComment(false)
                        }}
                        autoFocus
                    />
                    <Buttons>
                        <SecondaryButton>
                            {intl.get('actions.update')}
                        </SecondaryButton>
                        <QuaternaryButton onClick={() => {
                            setEditing(false)
                        }}>
                            {intl.get('actions.cancel')}
                        </QuaternaryButton>
                    </Buttons>
                </Form>
            </InputWrapper> : <CommentContent>
                {comment.content}
                {username === comment.username ? <EditButton src={EditSvg} onClick={() => {
                    setEditing({ ...comment, idx: i, isSubComment })
                }} /> : ''}
            </CommentContent>}
            <ActionBox>
                <QuaternaryButton onClick={(e) => {
                    setOverrideComment(`@${comment.username} `)
                    if (!isSubComment) {
                        setReplyToThreadId(comment.comment_id)
                    } else {
                        setReplyToThreadId(comment.thread_id)
                    }
                }}>
                    {isSubComment ? intl.get('actions.mention') : intl.get('actions.reply')}
                </QuaternaryButton>
                <Icon src={stats[comment.comment_id]?.liked ? HeartSvg : EmptyHeartSvg} onClick={() => {
                    const fn = stats[comment.comment_id]?.liked ? unlikeComment : likeComment
                    fn({
                        input: {
                            thread_id: comment.thread_id,
                            comment_id: comment.comment_id,
                        }
                    }).then((resp) => {
                        if (resp.error) {
                            // TODO show toast
                            console.log('failed to leave comment', resp.error)
                            return
                        }
                        setStatsUpdated(false)
                        refetchStats()
                    })
                }} />{shortenCount(stats[comment.comment_id]?.likes)}
                {!isSubComment ? <>
                    <Icon src={CommentSvg} onClick={() => {
                        if (stats[comment.comment_id]?.comments === 0) return
                        setSubCommentsUpdated(false)
                        setSubThreadId(comment.comment_id)
                    }} />{shortenCount(stats[comment.comment_id]?.comments)}
                </> : ''}
                <Timestamp>{shortenTimestamp(new Date((comment.edited_at || comment.created_at) * 1000))}</Timestamp>
            </ActionBox>
        </CommentBox>
    }

    const ReplyInput = () => {
        return userIsLoggedIn ? <InputWrapper>
            <Form onSubmit={(data) => {
                if (!data?.comment) return
                leaveComment({ input: { book_id, thread_id: replyToThreadId, content: data.comment } }).then((resp) => {
                    if (resp.error) {
                        // TODO show toast
                        console.log('failed to leave comment', resp.error)
                        return
                    }
                    setOverrideComment('')
                    if (replyToThreadId !== person.username) {
                        setSubThreadCursor(0)
                        setSubThreadId(replyToThreadId)
                        setSubCommentsUpdated(false)
                    } else {
                        setCommentsUpdated(false)
                        setCommentsCursor(comments.length)
                        refetchComments()
                    }
                })
            }}>
                <ReplyingBox onClick={() => {
                    setReplyToThreadId(person.username)
                    setOverrideComment('')
                }}>
                    {intl.get('labels.replyingTo', { username: replyToThreadId !== person.username ? person.username : intl.get('labels.mainThread') })}
                    {replyToThreadId !== person.username ? <TextButton>{intl.get('actions.cancel')}</TextButton> : ''}
                </ReplyingBox>
                <Input
                    type="textarea"
                    name="comment"
                    actionIcon="none"
                    placeholder={intl.get('labels.enterComment')}
                    overrideValue={overrideComment}
                    onChange={() => {
                        setOverrideComment(false)
                    }}
                />
                <Buttons>
                    <SecondaryButton>
                        {intl.get('actions.reply')}
                    </SecondaryButton>
                </Buttons>
            </Form>
        </InputWrapper> : ''
    }

    return <Wrapper className="cmp-comments-section">
        {comments?.length > 0 ? comments?.map((comment, i) => {
            return <div key={i}>
                <Comment comment={comment} i={i} />
                {subThreadId === comment.comment_id && subComments?.length > 0 ? subComments?.map((c, j) => {
                    return <Comment comment={c} i={j} key={j} isSubComment={true} />
                }) : ''}
                {subThreadId === comment.comment_id && subHasMore ? <MoreButton>
                    <TextButton onClick={() => {
                        setSubCommentsUpdated(false)
                        setSubThreadCursor(subComments.length)
                        refetchSubThread()
                    }}>
                        {intl.get('labels.showMoreComments')}
                    </TextButton>
                    <Spacer />
                </MoreButton> : ''}
                {replyToThreadId === comment.comment_id ? <ReplyInput /> : ''}
            </div>
        }) : <CommentBox>
            {intl.get('feed.noReviewYet', { username: person.username && person.username !== username ? person.username : intl.get('labels.you') })}
        </CommentBox>}
        {hasMore ? <MoreButton>
            <TertiaryButton onClick={() => {
                setCommentsUpdated(false)
                setCommentsCursor(comments.length)
                setSubThreadId(false)
            }}>
                {intl.get('actions.more')}
            </TertiaryButton>
        </MoreButton> : ''}
        {replyToThreadId !== person.username ? '' : <ReplyInput />}
    </Wrapper>
}