import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import $ from 'jquery'

import { colors } from "utils/colors";

import CloseIcon from "assets/icons/close.svg";
import ShowIcon from "assets/icons/visibility.svg";
import HideIcon from "assets/icons/visibility_off.svg";
import { setCaretPosition } from "utils/common";

const OuterWrapper = styled.div`
  position: relative;
`

const InputWrapper = styled.div`
  position: relative;
`;

const Error = styled.div`
  color: ${colors.red};
  text-align: center;
  font-size: 18px;
  margin: 0.25em 0;
`;

const StyledInput = styled.input`
  padding: 0.5em;
  width: calc(100% - 2.5em);
  font-size: 16px;
  border-radius: 5px;
  padding-right: 2em;
  background: ${colors.white};
  border: solid 2px ${colors.grey}:
`;

const StyledTextarea = styled.textarea`
  padding: 0.5em;
  width: calc(100% - 2.5em);
  font-size: 16px;
  border-radius: 5px;
  padding-right: 2em;
  background: ${colors.white};
  border: solid 2px ${colors.grey}:
`;

const ActionButton = styled.img`
  position: absolute;
  top: 0.375em;
  right: 0.125em;
  cursor: pointer;
`;


const Label = styled.div`
  position: relative;
  text-align: left;
  margin: 0.25em;
  font-size: 15px;
`;

const AutocompleteWrapper = styled.div`
  width: calc(100% - 2.5em);
  text-align: left;
  background-color: ${colors.white};
  border-radius: 0 0 8px 8px;
  padding: 0.25em;
  position: absolute;
  z-index: 5;
  max-height: 50vh;
  overflow: auto;
  min-height: 2em;
  margin-top: -5px;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.25);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.25);
`;

const AutocompleteItem = styled.div`
  cursor: pointer;
  margin: 0.5em 0.25em;
  padding: 0.25em;
  border-radius: 5px;

  &:hover {
    background-color: ${colors.lightgrey};
  }

  & > * {
    display: inline-block;
  }
`

const AutocompleteImg = styled.img`
  height: 30px;
  max-width: 30px;
  transform: translateY(0.1em);
`

const AutocompleteText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 2em);
  transform: translate(0.5em, -0.25em);
`

const PasswordToggle = styled.div`
  position: absolute;
  right: 1.5em;
  top: 0.375em;
  cursor: pointer;
`

const VisibilityIcon = styled.img``

export const Input = ({ name, type, label, errMsg, placeholder, defaultValue, background, onChange, autocompleteList, onAutocomplete, debounce, onAction, actionIcon, charsCountWidth, autoFocus, overrideValue }) => {
  const timeout = useRef(null)
  const [focused, setFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [v, setV] = useState(defaultValue || "");
  const ref = useRef(null);
  const inputParams = {
    'data-key': name,
    value: v || "",
    $focused: focused,
    $background: background,
    onChange: e => {
      e.preventDefault()
      if (debounce) {
        if (timeout.current) clearTimeout(timeout.current)
        timeout.current = setTimeout(() => {
          if (onChange) onChange(e, e.target.value)
        }, 250)
      } else {
        if (onChange) onChange(e, e.target.value)
      }
      setV(e.target.value);
    },
    onFocus: (e) => {
      setFocused(true);
      setCaretPosition(e.target, e.target.value.length);
    },
    onBlur: () => {
      setTimeout(() => {
        setFocused(false);
      }, 150)
    },
    placeholder: placeholder,
  }
  const charsCount = charsCountWidth || 20

  useEffect(() => {
    if (overrideValue === false) return
    setV(overrideValue)
    ref.current.value = overrideValue
    ref.current.focus()
  }, [overrideValue])

  return (
    <OuterWrapper className="cmp-input">
      {label ? <Label>{label}</Label> : ""}
      <InputWrapper>
        {type === 'textarea' ?
          <StyledTextarea ref={ref} autoFocus={autoFocus} rows={`${Math.ceil(v?.length / charsCount)}`} cols={charsCount} {...inputParams} /> :
          <StyledInput ref={ref} autoFocus={autoFocus} type={showPassword ? 'text' : type} {...inputParams} />}
        {type === 'password' ? <PasswordToggle onClick={() => {
          setShowPassword(!showPassword)
        }}>
          <VisibilityIcon src={showPassword ? HideIcon : ShowIcon}></VisibilityIcon>
        </PasswordToggle> : ''}
        {actionIcon !== 'none' ? <ActionButton
          src={actionIcon || CloseIcon}
          onClick={e => {
            if (onAction) onAction(v, setV)
            else setV("");
            e.preventDefault();
          }}
        /> : ''}
      </InputWrapper>
      {errMsg ? <Error>{errMsg}</Error> : ""}
      {focused && autocompleteList ? <AutocompleteWrapper>
        {(autocompleteList || []).map((item, i) => {
          return <AutocompleteItem key={i} onClick={() => {
            if (onAutocomplete) onAutocomplete(item.id, i)
            setV(item.text)
          }}>
            {item.img_url ? <AutocompleteImg src={item.img_url} /> : ''}
            <AutocompleteText>{item.text}</AutocompleteText>
          </AutocompleteItem>
        })}
      </AutocompleteWrapper> : ''}
    </OuterWrapper>
  );
};

const UploaderWrapper = styled.div`
  width: fit-content;
  cursor: pointer;
  & > * {
    pointer-events: none;
  }
`

const InvisibleInput = styled.input`
  display: none;
`

export const ImageUpload = ({ name, onChange, children }) => {
  const [value, setValue] = useState('');
  return <UploaderWrapper
    className="cmp-image-upload"
    data-key={name}
    value={value}
    onClick={(e) => {
      $(e.target).find('input[type=file]').trigger('click');
    }}>
    {children}
    <InvisibleInput type="file" accept="image/png, image/jpeg" onChange={(e) => {
      var reader = new FileReader()
      reader.onload = function () {
        setValue(reader.result)
        if (onChange) onChange(e, reader.result)
      }
      if (e.target.files.length > 0) reader.readAsDataURL(e.target.files[0])
    }} />
    <InvisibleInput type='submit' onClick={e => e.stopPropagation()} />
  </UploaderWrapper>
}