import intl from 'react-intl-universal'
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useQuery } from 'urql';
import { Capacitor } from '@capacitor/core';

import { ApiProvider } from 'components/api'
import { Page, useAppContext } from 'components/page';
import { FeaturedBook } from 'components/book/featured';
import { Label } from 'components/label';
import { useEffect } from 'react';
import { SearchBooksQuery } from 'queries/book';


const PageWrapper = styled.div.attrs(({ $top }) => ({
    $top,
}))`
    margin-top: ${({ $top }) => Capacitor.getPlatform() !== 'web' ? $top + 50 : 0}px;
`

const HeaderWrapper = styled.div`
    max-width: 1200px;
    width: calc(100% - 2em);
    margin: 2em auto;
    margin-bottom: 1em;
`

const FeaturedSection = styled.div`
    max-width: 1200px;
    width: calc(100% - 2em);
    margin: auto;
    text-align: center;
    
    @media only screen and (max-width: 600px) {
        white-space: nowrap;
        overflow: auto;
    }
`

export const FeaturedPage = () => {
    const navigate = useNavigate()
    const { userIsLoggedIn, safearea } = useAppContext();
    const [searchResult, refetchSearch] = useQuery({
        query: SearchBooksQuery,
        variables: {
            input: {
                keywords: "romance"
            }
        },
    });

    useEffect(() => {
        if (userIsLoggedIn) {
            navigate('/featured')
        }
    }, [userIsLoggedIn])

    return <ApiProvider>
        <Page allowScroll>
            <PageWrapper $top={safearea.top}>
                <HeaderWrapper>
                    <Label>{intl.get('labels.featured')}</Label>
                </HeaderWrapper>
                <FeaturedSection>
                    {searchResult?.data?.searchBooks?.books ? searchResult.data.searchBooks.books.map((book, i) => {
                        return <FeaturedBook key={i} {...book} />
                    }) : ''}
                </FeaturedSection>
            </PageWrapper>
        </Page>
    </ApiProvider>
}