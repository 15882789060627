import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { colors } from "utils/colors";

const Wrapper = styled.div`
  position: relative;
`;

const Text = styled.div`
  position: absolute;
  font-size: 15px;
`;

const MinText = styled(Text)`
  left: 0;
  top: 2px;
`;
const MaxText = styled(Text)`
  right: 0;
  top: 2px;
`;

const Value = styled.div`
  position: absolute;
  left: 50%;
  top: 2em;
  transform: translate(-50%,-50%);
  padding: 0 0.25em;
  width: 100%;
`;

const StyledSlider = styled.input`
  -webkit-appearance: none;
  appearance: none;
  background: ${colors.lightgrey};
  cursor: pointer;
  width: calc(100% - 3em);
  height: 0.5em;
  border-radius: 10px;
  margin-bottom: 1em;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background-color: ${colors.cyan};
    height: 1.5em;
    width: 0.5em; 
    border-radius: 10px;
  }
`

export const Slider = ({
  label,
  name,
  min,
  max,
  defaultValue,
  unit,
  withChar,
  step,
  valueFn,
  onChange,
}) => {
  const [v, setV] = useState(defaultValue || "");
  const timeout = useRef(null)
  return (
    <Wrapper
      className="cmp-slider"
      data-key={name}
      value={v}
    >
      {label ? <div>{label}</div> : ""}
      <MinText>
        {(() => {
          if (withChar) {
            return withChar.repeat(Math.round(min));
          }
          return min;
        })()}
      </MinText>
      <StyledSlider name={name} type="range" step={step} min={min} max={max} value={v || ''} onChange={(e) => {
        setV(e.target.value)
        if (timeout.current) clearTimeout(timeout.current)
        timeout.current = setTimeout(() => {
          if (onChange) onChange(e, e.target.value)
        }, 250)
      }} />
      <MaxText>
        {(() => {
          if (withChar) {
            return withChar.repeat(Math.round(max));
          }
          return max;
        })()}
      </MaxText>
      <Value>
        {(() => {
          if (valueFn) return valueFn(v)
          if (withChar) return withChar.repeat(Math.round(v));
          return v;
        })()}
        {unit ? ` ${unit}` : ""}
      </Value>
    </Wrapper>
  );
};
