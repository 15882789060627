import { colors } from "utils/colors";
import styled from "styled-components";

export const Label = styled.div.attrs(({ $center }) => ({
    $center
}))`
    font-family: Nourd-Bold;
    color: ${colors.darkgrey};
    text-align: ${({ $center }) => $center ? 'center' : 'left'};
    font-size: 18px;
`

export const Caption = styled.div.attrs(({ $center }) => ({
    $center
}))`
    color: ${colors.midgrey};
    text-align: ${({ $center }) => $center ? 'center' : 'left'};
    font-size: 16px;
`