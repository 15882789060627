import { gql } from 'urql';

export const ListCommentsQuery = gql`
query ListComments($input: ListCommentsRequest!){
    listComments(input: $input){
        comments {
            book_id
            thread_id
            comment_id
            username
            pfp_url
            content
            created_at
            edited_at
        }
        has_more
    }
}
`

export const GetCommentStatsQuery = gql`
query GetCommentStats($input: GetCommentStatsRequest!){
    getCommentStats(input: $input){
        stats {
            comment_id
            likes
            liked
            comments
        }
    }
}
`

export const LeaveCommentMutation = gql`
mutation LeaveComment($input: LeaveCommentRequest!){
    leaveComment(input: $input){
        ok
    }
}
`
export const EditCommentMutation = gql`
mutation EditComment($input: EditCommentRequest!){
    editComment(input: $input){
        ok
    }
}
`
export const DeleteCommentMutation = gql`
mutation DeleteComment($input: DeleteCommentRequest!){
    deleteComment(input: $input){
        ok
    }
}
`

export const LikeCommentMutation = gql`
mutation LikeComment($input: LikeCommentRequest!){
    likeComment(input: $input){
        ok
    }
}
`

export const UnlikeCommentMutation = gql`
mutation UnlikeComment($input: UnlikeCommentRequest!){
    unlikeComment(input: $input){
        ok
    }
}
`

export const GetBookReviewQuery = gql`
query GetBookReview($input: GetBookReviewRequest!){
    getBookReview(input: $input){
        review {
            thread_id
            comment_id
            username
            content
            created_at
            edited_at
            likes
            liked
            comments
        }
    }
}
`