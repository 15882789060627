import { Form } from "components/form";
import { Slider } from "components/slider";
import intl from "react-intl-universal";
import styled from "styled-components";
import { colors, seasonalPalette, selectColorWithHash } from "utils/colors";

import { useMutation, useQuery } from "urql";
import { RateBookMutation, UnsaveBookMutation } from "queries/book";
import { Input } from "components/input";

import { PrimaryButton, QuaternaryButton, SecondaryButton } from "components/buttons";
import { useEffect, useState } from "react";
import { Modal } from "components/modal";
import { DeleteCommentMutation, EditCommentMutation, GetBookReviewQuery, GetCommentStatsQuery, LeaveCommentMutation, ListCommentsQuery } from "queries/comment";
import { useAppContext } from "components/page";
import { Label } from "components/label";
import { BarRating } from "components/rating";


import PfpPlaceholderImg from 'assets/imgs/pfp-placeholder.jpeg'
import DeleteSvg from 'assets/icons/delete.svg'
import EditSvg from 'assets/icons/edit.svg'
import HeartSvg from 'assets/icons/heart.svg'
import CommentSvg from 'assets/icons/comment.svg'
import { shortenCount } from "utils/common";
import { CommentsSection } from "components/comment";

const Wrapper = styled.div.attrs(({ $background }) => ({
    $background
}))`
    width: 250px;
    background-color: ${({ $background }) => $background || colors.lightgrey};
    display: inline-block;
    margin: 0.5em;
    margin: calc(0.5em + 40px);
    border-radius: 30px;
    transition: 0.2s background-color;
    position: relative;
`

const Frame = styled.div`
    margin-top: 10px;
    margin-left: 10px;
    padding: 10px;
    width: 210px;
    height: 210px;
    background-color: ${colors.white};
    position: relative;
    text-align: center;
    border-radius: 30px;
`
const BookImage = styled.div`
    background-image: url(${({ src }) => src});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 210px;
    width: 100%;
`

const DescWrapper = styled.div`
    text-align: center;
    padding: 0.5em;
`

const NameText = styled.div`
    font-family: Nourd-Bold;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`

const AuthorText = styled.div`
    font-family: Nourd-Light;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`

const FriendPfp = styled.img`
    width: 80px;
    height: 80px;
    position: absolute;
    left: -40px;
    top: -40px;
    z-index: 1;
    border-radius: 80px;
    outline: solid 2px ${colors.cyan};
`

const FriendWrapper = styled.div`
    position: relative;
`

const GenereText = styled.div`
    display: inline-block;
    font-size: 14px;
    border-radius: 6px;
    background-color: ${colors.darkgrey};
    color: ${colors.white};
    padding: 0.25em 0.5em;
    margin: 0.25em;
    max-width: calc(100% - 2em);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`

const RatingWrapper = styled.div`
    max-width: 300px;
    margin: 1em auto;
`

const FriendUsername = styled.div`
    position: absolute;
    left: 50px;
    top: -25px;
`

const RemoveButton = styled.img`
    position: absolute;
    z-index: 2;
    top: -1em;
    right: 1em;
    cursor: pointer;
    background-color: ${colors.grey};
    padding: 0.25em;
    border: solid 2px ${colors.midgrey};
    border-radius: 10px;
    transition-duration: 0.2s;

    &:hover {
        background-color: ${colors.midgrey};
    }
`


const BookBox = styled.div`
    padding: 1em;
`

const ModalBox = styled.div`
    padding: 0.5em;
`

const PromptWrapper = styled.div`
    margin: 1em;
`

export const MyBook = ({ book_id, img_url, title, authors, personal_rating, genres, person, refetch }) => {
    const [, unsaveBook] = useMutation(UnsaveBookMutation)
    const [, rateBook] = useMutation(RateBookMutation)
    const [reviewModal, setReviewModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)

    return <Wrapper $background={selectColorWithHash(title, seasonalPalette)}>
        <FriendWrapper>
            <FriendPfp src={person.pfp_url || PfpPlaceholderImg} />
            <FriendUsername>{person.username}</FriendUsername>
        </FriendWrapper>
        <RemoveButton src={DeleteSvg} onClick={() => {
            setDeleteModal(true)
        }}>
        </RemoveButton>
        <Frame>
            <BookImage src={img_url} />
        </Frame>
        <DescWrapper>
            <NameText>{title}</NameText>
            <AuthorText>{authors?.join(', ')}</AuthorText>
            {(genres || [intl.get('labels.unknown')]).map((genre, i) => {
                return <GenereText key={i}>{genre}</GenereText>
            })}
            <BarRating rating={personal_rating || 0} />
            <div>
                <SecondaryButton onClick={() => {
                    setReviewModal(true)
                }}>
                    {intl.get('labels.yourReview')}
                </SecondaryButton>
            </div>
        </DescWrapper>
        {reviewModal ? <Modal onClose={() => { setReviewModal(false) }}>
            <ModalBox>
                <BookBox>
                    <NameText>{title}</NameText>
                    <BookImage src={img_url} />
                    <RatingWrapper>
                        <Slider defaultValue={personal_rating || "??"} min={1} max={10} name="personalRating" step={0.1} valueFn={(value) => {
                            return intl.get('feed.yourScore', { score: value })
                        }} onChange={(e, v) => {
                            rateBook({ input: { book_id, rating: v } }).then((resp) => {
                                if (resp.error) {
                                    // TODO show toast
                                    console.log('failed to unsave', resp.error)
                                    return
                                }
                                if (refetch) refetch()
                            })
                        }} />
                    </RatingWrapper>
                </BookBox>
                <CommentsSection book_id={book_id} person={person} />
            </ModalBox>
        </Modal> : ''}
        {deleteModal ? <Modal onClose={() => { setDeleteModal(false) }}>
            <PromptWrapper>
                {intl.get('prompt.unsaveBook', { title })}
            </PromptWrapper>
            <SecondaryButton onClick={() => {
                unsaveBook({ input: { book_id } }).then((resp) => {
                    if (resp.error) {
                        // TODO show toast
                        console.log('failed to unsave', resp.error)
                        return
                    }
                    setDeleteModal(false)
                    if (refetch) refetch()
                })
            }}>
                {intl.get('actions.confirm')}
            </SecondaryButton>
        </Modal> : ''}
    </Wrapper>
}