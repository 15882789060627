import { gql } from 'urql';

export const GetNotificationsQuery = gql`
query GetNotifications($input: GetNotificationsRequest!){
    getNotifications(input: $input){
        notifications {
            notification_id
            type
            username
        }
    }
}
`
export const RemoveNotificationMutation = gql`
mutation RemoveNotification($input: RemoveNotificationRequest!){
    removeNotification(input: $input){
        ok
    }
}
`

export const HasNewNotificationsQuery = gql`
query HasNewNotifications($input: HasNewNotificationsRequest!){
    hasNewNotifications(input: $input){
        ok
    }
}
`
export const MarkNotificationsReadMutation = gql`
mutation MarkNotificationsRead($input: MarkNotificationsReadRequest!){
    markNotificationsRead(input: $input){
        ok
    }
}
`
export const GetStoriesQuery = gql`
query GetStories($input: GetStoriesRequest!){
    getStories(input: $input){
        stories {
            book {
                book_id
                title
                authors
                img_url
                genres
                rating
            }
            parent_comment {
                thread_id
                comment_id
                username
                pfp_url
                content
                liked
                likes
                comments
                created_at
                edited_at
            }
            comment {
                thread_id
                comment_id
                username
                pfp_url
                content
                liked
                likes
                comments
                created_at
                edited_at
            }
        }
    }
}
`