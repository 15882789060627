import { Rating } from "components/rating";
import { useState } from "react";
import intl from "react-intl-universal";
import styled from "styled-components";
import { colors } from "utils/colors";
import { BookDetails } from "./search";
import { Modal } from "components/modal";

const Wrapper = styled.div`
    width: 200px;
    background-color: ${colors.lightgrey};
    display: inline-block;
    margin: 1em;
    cursor: pointer;
    transition: 0.2s background-color;

    &:hover {
        background-color: ${colors.grey};
    }
`

const Frame = styled.div`
    margin-top: 6px;
    margin-left: 6px;
    width: 176px;
    height: 176px;
    border: solid 6px white;
    position: relative;
    text-align: center;
    padding: 0.5em 0;
`
const BookImage = styled.div`
    background-image: url(${({ src }) => src});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 176px;
    width: 100%;
`

const DescWrapper = styled.div`
    text-align: center;
    padding: 0.5em;
`

const NameText = styled.div`
    font-family: Nourd-Bold;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`

const AuthorText = styled.div`
    font-family: Nourd-Light;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`

const GenereText = styled.div`
    display: inline-block;
    font-size: 14px;
    border-radius: 6px;
    background-color: ${colors.darkgrey};
    color: ${colors.white};
    padding: 0.25em 0.5em;
    margin: 0.25em;
    max-width: calc(100% - 2em);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`

export const FeaturedBook = ({ book_id, img_url, title, author, genres, rating }) => {
    const [modal, setModal] = useState(false);
    return <>
        {modal ? <Modal onClose={() => {
            setModal(false)
        }}>
            <BookDetails book_id={book_id} />
        </Modal> : ''}
        <Wrapper onClick={() => {
            setModal(true)
        }}>
            <Frame>
                <BookImage src={img_url} />
            </Frame>
            <DescWrapper>
                <NameText>{title}</NameText>
                <AuthorText>{author}</AuthorText>
                {(genres || [intl.get('labels.unknown')]).map((genre, i) => {
                    return <GenereText key={i}>{genre}</GenereText>
                })}
                <Rating rating={rating} />
            </DescWrapper>
        </Wrapper>
    </>
}