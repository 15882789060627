import { gql } from 'urql';

export const LoginMutation = gql`
mutation login($input: LoginRequest!){
    login(input: $input){
        refreshToken
        accessToken
    }
}
`
export const SignUpMutation = gql`
mutation signUp($input: SignUpRequest!){
    signUp(input: $input){
        refreshToken
        accessToken
    }
}
`
export const RefreshAuthMutation = gql`
mutation refreshAuth($input: RefreshAuthRequest!){
    refreshAuth(input: $input){
        username
        refreshToken
        accessToken
    }
}
`
export const ForgotPasswordMutation = gql`
mutation ForgotPassword($input: ForgotPasswordRequest!){
    forgotPassword(input: $input){
        ok
    }
}
`
export const ResetPasswordMutation = gql`
mutation ResetPassword($input: ResetPasswordRequest!){
    resetPassword(input: $input){
        ok
    }
}
`