import intl from 'react-intl-universal'
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useQuery } from 'urql';

import { colors } from 'utils/colors';
import { ApiProvider } from 'components/api'
import { Page, useAppContext } from 'components/page';
import { FeaturedBook } from 'components/book/featured';
import { Label } from 'components/label';
import { useEffect, useState } from 'react';
import { GetBookDetailsQuery, LoadFeedBooksQuery, SearchBooksQuery } from 'queries/book';

import CombinedBlobImg from 'assets/landing/combined-blob.png'
import ReaderImg from 'assets/landing/reader.png'
import FilledBlobImg from 'assets/landing/single-filled-blob.png'
import EmptyBlobImg from 'assets/landing/single-white-blob.png'
import { BookDetails } from 'components/book/search';
import { FriendBook } from 'components/book/friend';
import { Modal } from 'components/modal';

const PageWrapper = styled.div.attrs(({ $top, $bottom }) => ({
    $top, $bottom,
}))`
    overflow: auto;
    width: 100%;
    margin-top: ${({ $top }) => $top}px;
    height: calc(100vh - 50px - ${({ $top, $bottom }) => $top + $bottom}px);
`

const BannerSection = styled.div`
    height: 300px;
    background-color: ${colors.cyan};
    position: relative;
    overflow: hidden;
`

const BannerWrapper = styled.div`
    max-width: 600px;
    width: calc(100% - 2em);
    margin: auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
`

const BannerText = styled.div`
    font-family: Nourd-Bold;
    font-size: 46px;
    color: ${colors.white};
    text-transform: uppercase;
`

const BannerDescText = styled.div`
    font-family: Nourd-Light;
    font-size: 18px;
    color: ${colors.white};
`

const FooterSection = styled.div`
    background-color: ${colors.cyan};
    color: ${colors.white};
    text-align: center;
    padding: 1em;
`

const CombinedBlob = styled.img`
    position: absolute;
    width: 400px;
    left: -4em;
`
const Reader = styled.img`
    position: absolute;
    height: 300px;
    bottom: -15%;
    right: 10%;
`
const FilledBlob = styled.img`
    position: absolute;
    width: 600px;
    left: 50%;
    transform: translateX(-50%);
`
const EmptyBlob = styled.img`
    position: absolute;
    width: 500px;
    right: -10%;
    top: -10em;
`

const FeedWrapper = styled.div`
    text-align: center;
    margin: 1em auto;
    max-width: 1200px;
`

export const HomePage = () => {
    const navigate = useNavigate()
    const { userIsLoggedIn, safearea } = useAppContext();

    const [cursor, setCursor] = useState(0)
    const [booksResult, refetchBooks] = useQuery({
        query: LoadFeedBooksQuery,
        variables: {
            input: {
                cursor,
                friends_only: false
            }
        },
        requestPolicy: 'cache-and-network',
    });
    const books = booksResult?.data?.loadFeedBooks?.books

    useEffect(() => {
        if (userIsLoggedIn) {
            navigate('/featured')
        }
    }, [userIsLoggedIn])

    return <ApiProvider>
        <Page>
            <PageWrapper $top={safearea.top} $bottom={safearea.bottom}>
                <BannerSection>
                    <CombinedBlob src={CombinedBlobImg} />
                    <EmptyBlob src={EmptyBlobImg} />
                    <FilledBlob src={FilledBlobImg} />
                    <Reader src={ReaderImg} />
                    <BannerWrapper>
                        <BannerText>{intl.get('home.banner')}</BannerText>
                        <BannerDescText>{intl.get('home.bannerDesc')}</BannerDescText>
                    </BannerWrapper>
                </BannerSection>
                <FeedWrapper>
                    {books?.length > 0 ? books?.map((item, i) => {
                        return <FriendBook key={i} refetch={refetchBooks} {...item.book} {...item} />
                    }) : intl.get('feed.noPosts', { people: intl.get('feed.everyone') })}
                </FeedWrapper>
                <FooterSection>
                    Bookboos © 2024
                </FooterSection>
            </PageWrapper>
        </Page>
    </ApiProvider>
}