import React from "react";
import styled from "styled-components";
import { colors } from "utils/colors";
import { useAppContext } from "./page";

const Wrapper = styled.div.attrs(({ $top, $bottom }) => ({ $top, $bottom }))`
        overflow: auto;
        height: calc(100vh - ${({ $top, $bottom }) => $top + $bottom}px);
        margin-top: ${({ $top }) => $top}px;
`

export const PrivacyPolicy = () => {
        const { safearea } = useAppContext();
        return (
                <Wrapper $top={safearea.top} $bottom={safearea.bottom}>
                        <div
                                style={{
                                        margin: "30px",
                                        padding: "30px",
                                        background: colors.white,
                                }}
                                dangerouslySetInnerHTML={{
                                        __html: `
  <style>
  [data-custom-class='body'],
  [data-custom-class='body'] * {
          background: transparent !important;
  }

  [data-custom-class='title'],
  [data-custom-class='title'] * {
          font-family: Arial !important;
          font-size: 26px !important;
          color: #000000 !important;
  }

  [data-custom-class='subtitle'],
  [data-custom-class='subtitle'] * {
          font-family: Arial !important;
          color: #595959 !important;
          font-size: 14px !important;
  }

  [data-custom-class='heading_1'],
  [data-custom-class='heading_1'] * {
          font-family: Arial !important;
          font-size: 19px !important;
          color: #000000 !important;
  }

  [data-custom-class='heading_2'],
  [data-custom-class='heading_2'] * {
          font-family: Arial !important;
          font-size: 17px !important;
          color: #000000 !important;
  }

  [data-custom-class='body_text'],
  [data-custom-class='body_text'] * {
          color: #595959 !important;
          font-size: 14px !important;
          font-family: Arial !important;
  }

  [data-custom-class='link'],
  [data-custom-class='link'] * {
          color: #3030F1 !important;
          font-size: 14px !important;
          font-family: Arial !important;
          word-break: break-word !important;
  }
</style>

<div data-custom-class="body">
  <div><strong><span style="font-size: 26px;"><span data-custom-class="title">
                                  <bdt class="block-component"></bdt>PRIVACY NOTICE<bdt class="else-block"></bdt>
                          </span></span></strong></div>
  <div><br></div>
  <div><span style="color: rgb(127, 127, 127);"><strong><span style="font-size: 15px;"><span
                                          data-custom-class="subtitle">Last updated <bdt class="question">
                                                  Nov 6, 2023</bdt></span></span></strong></span></div>
  <div><br></div>
  <div><br></div>
  <div><br></div>
  <div style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                  data-custom-class="body_text">This privacy notice for <bdt class="question">
                                          BookBoos Studio LLC<bdt class="block-component"></bdt>
                                  </bdt> (<bdt class="block-component"></bdt>"<strong>we</strong>,"
                                  "<strong>us</strong>," or "<strong>our</strong>"<bdt
                                          class="statement-end-if-in-editor"></bdt></span><span
                                  data-custom-class="body_text">), describes how and why we might collect, store,
                                  use, and/or share (<bdt class="block-component"></bdt>"<strong>process</strong>"
                                  <bdt class="statement-end-if-in-editor"></bdt>) your information when you use
                                  our services (<bdt class="block-component"></bdt>"<strong>Services</strong>"<bdt
                                          class="statement-end-if-in-editor"></bdt>), such as when
                                  you:</span></span></span><span style="font-size: 15px;"><span
                          style="color: rgb(127, 127, 127);"><span data-custom-class="body_text"><span
                                          style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                                  <bdt class="block-component"></bdt>
                                          </span></span></span></span></span></div>
  <ul>
          <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                          data-custom-class="body_text">Visit our website<bdt
                                                  class="block-component"></bdt> at <bdt class="question"><a
                                                          href="https://bookboos.com" target="_blank"
                                                          data-custom-class="link">https://bookboos.com</a></bdt>
                                          <span style="font-size: 15px;"><span
                                                          style="color: rgb(89, 89, 89);"><span
                                                                  data-custom-class="body_text"><span
                                                                          style="font-size: 15px;"><span
                                                                                  style="color: rgb(89, 89, 89);">
                                                                                  <bdt
                                                                                          class="statement-end-if-in-editor">
                                                                                          , or any website of ours
                                                                                          that links to this
                                                                                          privacy notice</bdt>
                                                                          </span></span></span></span></span></span></span></span>
          </li>
  </ul>
  <div>
          <bdt class="block-component"><span style="font-size: 15px;"><span style="font-size: 15px;"><span
                                          style="color: rgb(127, 127, 127);"><span
                                                  data-custom-class="body_text"><span
                                                          style="color: rgb(89, 89, 89);"><span
                                                                  data-custom-class="body_text">
                                                                  <bdt class="block-component"></bdt>
                                                          </span></span></span></span></span></span></bdt>
  </div>
  <ul>
          <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                          data-custom-class="body_text">Download and use<bdt
                                                  class="block-component"></bdt> our mobile application<bdt
                                                  class="block-component"></bdt> (<bdt class="question">BookBoos)<span
                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                          data-custom-class="body_text"><span
                                                                                  style="font-size: 15px;"><span
                                                                                          style="color: rgb(89, 89, 89);"><span
                                                                                                  data-custom-class="body_text"><span
                                                                                                          style="font-size: 15px;"><span
                                                                                                                  style="color: rgb(89, 89, 89);">
                                                                                                                  <bdt
                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                          ,
                                                                                                                  </bdt>
                                                                                                          </span></span></span></span></span></span></span></span>
                                          </bdt></span><span data-custom-class="body_text"><span
                                                  style="font-size: 15px;"><span
                                                          style="color: rgb(89, 89, 89);"><span
                                                                  data-custom-class="body_text"><span
                                                                          style="font-size: 15px;"><span
                                                                                  style="color: rgb(89, 89, 89);">
                                                                                  <bdt
                                                                                          class="statement-end-if-in-editor">
                                                                                          <bdt
                                                                                                  class="block-component">
                                                                                                  or any other
                                                                                                  application of
                                                                                                  ours that links
                                                                                                  to this privacy
                                                                                                  notice</bdt>
                                                                                  </bdt>
                                                                          </span></span></span></span></span></span></span></span>
          </li>
  </ul>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: rgb(127, 127, 127);"><span
                                  data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span
                                                  data-custom-class="body_text">
                                                  <bdt class="block-component"></bdt>
                                          </span></span></span></span></span></div>
  <ul>
          <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                          data-custom-class="body_text">Engage with us in other related ways,
                                          including any sales, marketing, or events<span
                                                  style="font-size: 15px;"><span
                                                          style="color: rgb(89, 89, 89);"><span
                                                                  data-custom-class="body_text"><span
                                                                          style="font-size: 15px;"><span
                                                                                  style="color: rgb(89, 89, 89);">
                                                                                  <bdt
                                                                                          class="statement-end-if-in-editor">
                                                                                  </bdt>
                                                                          </span></span></span></span></span></span></span></span>
          </li>
  </ul>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: rgb(127, 127, 127);"><span
                                  data-custom-class="body_text"><strong>Questions or concerns? </strong>Reading
                                  this privacy notice will help you understand your privacy rights and choices. If
                                  you do not agree with our policies and practices, please do not use our
                                  Services.<bdt class="block-component"></span></span></span></div>
  <div style="line-height: 1.5;"><br></div>
  <div style="line-height: 1.5;"><br></div>
  <div style="line-height: 1.5;"><strong><span style="font-size: 15px;"><span
                                  data-custom-class="heading_1">SUMMARY OF KEY POINTS</span></span></strong></div>
  <div style="line-height: 1.5;"><br></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><span
                          data-custom-class="body_text"><strong><em>This summary provides key points from our
                                          privacy notice, but you can find out more details about any of these
                                          topics by clicking the link following each key point or by using
                                          our </em></strong></span></span><a data-custom-class="link"
                  href="#toc"><span style="font-size: 15px;"><span data-custom-class="body_text"><strong><em>table
                                                  of contents</em></strong></span></span></a><span
                  style="font-size: 15px;"><span data-custom-class="body_text"><strong><em> below to find the
                                          section you are looking for.</em></strong></span></span></div>
  <div style="line-height: 1.5;"><br></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text"><strong>What
                                  personal information do we process?</strong> When you visit, use, or navigate
                          our Services, we may process personal information depending on how you interact with us
                          and the Services, the choices you make, and the products and features you use. Learn
                          more about </span></span><a data-custom-class="link" href="#personalinfo"><span
                          style="font-size: 15px;"><span data-custom-class="body_text">personal information you
                                  disclose to us</span></span></a><span data-custom-class="body_text">.</span>
  </div>
  <div style="line-height: 1.5;"><br></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text"><strong>Do we
                                  process any sensitive personal information?</strong>
                          <bdt class="block-component"></bdt>We do not process sensitive personal information.<bdt
                                  class="else-block"></bdt>
                  </span></span></div>
  <div style="line-height: 1.5;"><br></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text"><strong>Do we
                                  receive any information from third parties?</strong>
                          <bdt class="block-component"></bdt>We do not receive any information from third parties.
                          <bdt class="else-block"></bdt>
                  </span></span></div>
  <div style="line-height: 1.5;"><br></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text"><strong>How do
                                  we process your information?</strong> We process your information to provide,
                          improve, and administer our Services, communicate with you, for security and fraud
                          prevention, and to comply with law. We may also process your information for other
                          purposes with your consent. We process your information only when we have a valid legal
                          reason to do so. Learn more about </span></span><a data-custom-class="link"
                  href="#infouse"><span style="font-size: 15px;"><span data-custom-class="body_text">how we
                                  process your information</span></span></a><span
                  data-custom-class="body_text">.</span></div>
  <div style="line-height: 1.5;"><br></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text"><strong>In
                                  what situations and with which <bdt class="block-component"></bdt>parties do we
                                  share personal information?</strong> We may share information in specific
                          situations and with specific <bdt class="block-component"></bdt>third parties. Learn
                          more about </span></span><a data-custom-class="link" href="#whoshare"><span
                          style="font-size: 15px;"><span data-custom-class="body_text">when and with whom we share
                                  your personal information</span></span></a><span style="font-size: 15px;"><span
                          data-custom-class="body_text">.<bdt class="block-component"></bdt></span></span></div>
  <div style="line-height: 1.5;"><br></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text"><strong>How do
                                  we keep your information safe?</strong> We have <bdt class="block-component">
                          </bdt>organizational<bdt class="statement-end-if-in-editor"></bdt> and technical
                          processes and procedures in place to protect your personal information. However, no
                          electronic transmission over the internet or information storage technology can be
                          guaranteed to be 100% secure, so we cannot promise or guarantee that hackers,
                          cybercriminals, or other <bdt class="block-component"></bdt>login<bdt
                                  class="statement-end-if-in-editor"></bdt> third parties will not be able to
                          defeat our security and improperly collect, access, steal, or modify your information.
                          Learn more about </span></span><a data-custom-class="link" href="#infosafe"><span
                          style="font-size: 15px;"><span data-custom-class="body_text">how we keep your
                                  information safe</span></span></a><span
                  data-custom-class="body_text">.</span><span style="font-size: 15px;"><span
                          data-custom-class="body_text">
                          <bdt class="statement-end-if-in-editor"></bdt>
                  </span></span></div>
  <div style="line-height: 1.5;"><br></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text"><strong>What
                                  are your rights?</strong> Depending on where you are located geographically, the
                          applicable privacy law may mean you have certain rights regarding your personal
                          information. Learn more about </span></span><a data-custom-class="link"
                  href="#privacyrights"><span style="font-size: 15px;"><span data-custom-class="body_text">your
                                  privacy rights</span></span></a><span data-custom-class="body_text">.</span>
  </div>
  <div style="line-height: 1.5;"><br></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text"><strong>How do
                                  you exercise your rights?</strong> The easiest way to exercise your rights is by
                          <bdt class="block-component"></bdt>visiting <bdt class="question"><a
                                          href="https://bookboos.com/account" target="_blank"
                                          data-custom-class="link">https://bookboos.com/account</a></bdt>
                          <bdt class="else-block"></bdt>, or by contacting us. We will consider and act upon any
                          request in accordance with applicable data protection laws.
                  </span></span></div>
  <div style="line-height: 1.5;"><br></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text">Want to learn
                          more about what we do with any information we collect? </span></span><a
                  data-custom-class="link" href="#toc"><span style="font-size: 15px;"><span
                                  data-custom-class="body_text">Review the privacy notice in
                                  full</span></span></a><span style="font-size: 15px;"><span
                          data-custom-class="body_text">.</span></span></div>
  <div style="line-height: 1.5;"><br></div>
  <div style="line-height: 1.5;"><br></div>
  <div id="toc" style="line-height: 1.5;"><span style="font-size: 15px;"><span
                          style="color: rgb(127, 127, 127);"><span style="color: rgb(0, 0, 0);"><strong><span
                                                  data-custom-class="heading_1">TABLE OF
                                                  CONTENTS</span></strong></span></span></span></div>
  <div style="line-height: 1.5;"><br></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link"
                          href="#infocollect"><span style="color: rgb(89, 89, 89);">1. WHAT INFORMATION DO WE
                                  COLLECT?</span></a></span></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link" href="#infouse"><span
                                  style="color: rgb(89, 89, 89);">2. HOW DO WE PROCESS YOUR INFORMATION?<bdt
                                          class="block-component"></bdt></span></a></span></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><a
                                  data-custom-class="link" href="#whoshare">3. WHEN AND WITH WHOM DO WE SHARE YOUR
                                  PERSONAL INFORMATION?</a></span><span data-custom-class="body_text">
                          <bdt class="block-component"></bdt>
                  </span></a><span style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89, 89);"><span
                                          data-custom-class="body_text"><span style="color: rgb(89, 89, 89);">
                                                  <bdt class="block-component"></bdt>
                                          </span></span></span></span></span></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link" href="#cookies"><span
                                  style="color: rgb(89, 89, 89);">4. DO WE USE COOKIES AND OTHER TRACKING
                                  TECHNOLOGIES?</span></a><span style="color: rgb(127, 127, 127);"><span
                                  style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                                                  style="color: rgb(89, 89, 89);">
                                                  <bdt class="statement-end-if-in-editor"></bdt>
                                          </span></span><span data-custom-class="body_text"><span
                                                  style="color: rgb(89, 89, 89);"><span
                                                          style="color: rgb(89, 89, 89);"><span
                                                                  style="color: rgb(89, 89, 89);">
                                                                  <bdt class="block-component"></bdt>
                                                          </span></span></span></span></span></span></span></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link"
                          href="#sociallogins"><span style="color: rgb(89, 89, 89);"><span
                                          style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);">5.
                                                  HOW DO WE HANDLE YOUR SOCIAL
                                                  LOGINS?</span></span></span></a><span
                          style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89, 89);"><span
                                          data-custom-class="body_text"><span
                                                  style="color: rgb(89, 89, 89);"><span
                                                          style="color: rgb(89, 89, 89);"><span
                                                                  style="color: rgb(89, 89, 89);">
                                                                  <bdt class="statement-end-if-in-editor"></bdt>
                                                          </span></span>
                                                  <bdt class="block-component"></bdt>
                                          </span></span></span></span></span></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link"
                          href="#inforetain"><span style="color: rgb(89, 89, 89);">6. HOW LONG DO WE KEEP YOUR
                                  INFORMATION?</span></a><span style="color: rgb(127, 127, 127);"><span
                                  style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                                                  style="color: rgb(89, 89, 89);"><span
                                                          style="color: rgb(89, 89, 89);">
                                                          <bdt class="block-component"></bdt>
                                                  </span></span></span></span></span></span></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link" href="#infosafe"><span
                                  style="color: rgb(89, 89, 89);">7. HOW DO WE KEEP YOUR INFORMATION
                                  SAFE?</span></a><span style="color: rgb(127, 127, 127);"><span
                                  style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                                                  style="color: rgb(89, 89, 89);">
                                                  <bdt class="statement-end-if-in-editor"></bdt>
                                                  <bdt class="block-component"></bdt>
                                          </span></span></span></span></span></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link"
                          href="#infominors"><span style="color: rgb(89, 89, 89);">8. DO WE COLLECT INFORMATION
                                  FROM MINORS?</span></a><span style="color: rgb(127, 127, 127);"><span
                                  style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                                                  style="color: rgb(89, 89, 89);">
                                                  <bdt class="statement-end-if-in-editor"></bdt>
                                          </span></span></span></span></span></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><a
                                  data-custom-class="link" href="#privacyrights">9. WHAT ARE YOUR PRIVACY
                                  RIGHTS?</a></span></span></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link" href="#DNT"><span
                                  style="color: rgb(89, 89, 89);">10. CONTROLS FOR DO-NOT-TRACK FEATURES<bdt
                                          class="block-component"></bdt></span></a></span></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link" href="#uslaws"><span
                                  style="color: rgb(89, 89, 89);">11. DO UNITED STATES RESIDENTS HAVE SPECIFIC
                                  PRIVACY RIGHTS?</span></a></span>
          <bdt class="block-component"><span style="font-size: 15px;"><span data-custom-class="body_text"></span>
          </bdt></span>
  </div>
  <div style="line-height: 1.5;">
          <bdt class="block-component"><span style="font-size: 15px;"></bdt>
          <bdt class="block-component"></bdt>
          <bdt class="block-component"></bdt>
          <bdt class="block-component"></bdt>
          <bdt class="block-component"></bdt>
          <bdt class="block-component"></bdt>
          <bdt class="block-component"></bdt>
          <bdt class="block-component"></bdt>
          <bdt class="block-component"></bdt>
          <bdt class="block-component"></span></bdt>
  </div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link"
                          href="#policyupdates"><span style="color: rgb(89, 89, 89);">12. DO WE MAKE UPDATES TO
                                  THIS NOTICE?</span></a></span></div>
  <div style="line-height: 1.5;"><a data-custom-class="link" href="#contact"><span
                          style="color: rgb(89, 89, 89); font-size: 15px;">13. HOW CAN YOU CONTACT US ABOUT THIS
                          NOTICE?</span></a></div>
  <div style="line-height: 1.5;"><a data-custom-class="link" href="#request"><span
                          style="color: rgb(89, 89, 89);">14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
                          COLLECT FROM YOU?</span></a></div>
  <div style="line-height: 1.5;"><br></div>
  <div style="line-height: 1.5;"><br></div>
  <div id="infocollect" style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                                  style="color: rgb(0, 0, 0);"><strong><span
                                                                  data-custom-class="heading_1">1. WHAT
                                                                  INFORMATION DO WE
                                                                  COLLECT?</span></strong></span></span></span></span></span>
  </div>
  <div style="line-height: 1.5;"><br></div>
  <div id="personalinfo" style="line-height: 1.5;"><span data-custom-class="heading_2"
                  style="color: rgb(0, 0, 0);"><span style="font-size: 15px;"><strong>Personal information you
                                  disclose to us</strong></span></span></div>
  <div>
          <div><br></div>
          <div style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                          data-custom-class="body_text"><span
                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                  data-custom-class="body_text"><strong><em>In
                                                                                  Short:</em></strong></span></span></span></span><span
                                          data-custom-class="body_text"><span
                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                  data-custom-class="body_text"><strong><em> </em></strong><em>We
                                                                          collect personal information that you
                                                                          provide to
                                                                          us.</em></span></span></span></span></span></span>
          </div>
  </div>
  <div style="line-height: 1.5;"><br></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                          style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">We
                                  collect personal information that you voluntarily provide to us when you <span
                                          style="font-size: 15px;">
                                          <bdt class="block-component"></bdt>
                                  </span>register on the Services, </span><span style="font-size: 15px;"><span
                                          data-custom-class="body_text"><span style="font-size: 15px;">
                                                  <bdt class="statement-end-if-in-editor"></bdt>
                                          </span></span><span data-custom-class="body_text">express an interest in
                                          obtaining information about us or our products and Services, when you
                                          participate in activities on the Services, or otherwise when you contact
                                          us.</span></span></span></span></div>
  <div style="line-height: 1.5;"><br></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                  data-custom-class="body_text"><span style="font-size: 15px;">
                                          <bdt class="block-component"></bdt>
                                  </span></span></span></span></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                  data-custom-class="body_text"><strong>Personal Information Provided by
                                          You.</strong> The personal information that we collect depends on the
                                  context of your interactions with us and the Services, the choices you make, and
                                  the products and features you use. The personal information we collect may
                                  include the following:<span style="font-size: 15px;"><span
                                                  data-custom-class="body_text">
                                                  <bdt class="forloop-component"></bdt>
                                          </span></span></span></span></span></div>
  <ul>
          <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                          data-custom-class="body_text"><span style="font-size: 15px;"><span
                                                          data-custom-class="body_text">
                                                          <bdt class="question">names</bdt>
                                                  </span></span></span></span></span></li>
  </ul>
  <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                  data-custom-class="body_text"><span style="font-size: 15px;"><span
                                                  data-custom-class="body_text">
                                                  <bdt class="forloop-component"></bdt>
                                          </span></span></span></span></span></div>
  <ul>
          <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                          data-custom-class="body_text"><span style="font-size: 15px;"><span
                                                          data-custom-class="body_text">
                                                          <bdt class="question">phone numbers</bdt>
                                                  </span></span></span></span></span></li>
  </ul>
  <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                  data-custom-class="body_text"><span style="font-size: 15px;"><span
                                                  data-custom-class="body_text">
                                                  <bdt class="forloop-component"></bdt>
                                          </span></span></span></span></span></div>
  <ul>
          <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                          data-custom-class="body_text"><span style="font-size: 15px;"><span
                                                          data-custom-class="body_text">
                                                          <bdt class="question">email addresses</bdt>
                                                  </span></span></span></span></span></li>
  </ul>
  <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                  data-custom-class="body_text"><span style="font-size: 15px;"><span
                                                  data-custom-class="body_text">
                                                  <bdt class="forloop-component"></bdt>
                                          </span></span></span></span></span></div>
  <ul>
          <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                          data-custom-class="body_text"><span style="font-size: 15px;"><span
                                                          data-custom-class="body_text">
                                                          <bdt class="question">location</bdt>
                                                  </span></span></span></span></span></li>
  </ul>
  <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                  data-custom-class="body_text"><span style="font-size: 15px;"><span
                                                  data-custom-class="body_text">
                                                  <bdt class="forloop-component"></bdt>
                                          </span><span data-custom-class="body_text">
                                                  <bdt class="statement-end-if-in-editor"></bdt>
                                          </span></span></span></span></span></div>
  <div id="sensitiveinfo" style="line-height: 1.5;"><span style="font-size: 15px;"><span
                          data-custom-class="body_text"><strong>Sensitive Information.</strong>
                          <bdt class="block-component"></bdt>We do not process sensitive information.
                  </span></span></div>
  <div style="line-height: 1.5;"><br></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text">
                          <bdt class="else-block"></bdt>
                  </span></span><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                  data-custom-class="body_text"><span style="font-size: 15px;"><span
                                                  data-custom-class="body_text">
                                                  <bdt class="block-component">
                                                          <bdt class="block-component"></bdt>
                                                  </bdt>
                                          </span></span></span></span></span></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                  data-custom-class="body_text"><strong>Social Media Login Data. </strong>We may
                                  provide you with the option to register with us using your existing social media
                                  account details, like your Facebook, Twitter, or other social media account. If
                                  you choose to register in this way, we will collect the information described in
                                  the section called <bdt class="block-component"></bdt>"<bdt
                                          class="statement-end-if-in-editor"></bdt><span
                                          style="font-size: 15px;"><span data-custom-class="body_text"><span
                                                          style="font-size: 15px;"><span
                                                                  style="color: rgb(89, 89, 89);"><a
                                                                          data-custom-class="link"
                                                                          href="#sociallogins">HOW DO WE HANDLE
                                                                          YOUR SOCIAL
                                                                          LOGINS?</a></span></span></span></span>
                                  <bdt class="block-component"></bdt>"<bdt class="statement-end-if-in-editor">
                                  </bdt> below.
                          </span></span></span></div>
  <div style="line-height: 1.5;"><br></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                  data-custom-class="body_text"><span style="font-size: 15px;">
                                          <bdt class="statement-end-if-in-editor">
                                                  <bdt class="statement-end-if-in-editor"></bdt>
                                          </bdt>
                                  </span></span></span></span>
          <bdt class="block-component">
                  <bdt class="block-component"></bdt>
          </bdt>
  </div>
  <div style="line-height: 1.5;"><span data-custom-class="body_text"><span
                          style="font-size: 15px;"><strong>Application Data.</strong> If you use our
                          application(s), we also may collect the following information if you choose to provide
                          us with access or permission:<bdt class="block-component"></bdt></span></span></div>
  <ul>
          <li style="line-height: 1.5;"><span data-custom-class="body_text"><span
                                  style="font-size: 15px;"><em>Geolocation Information.</em> We may request access
                                  or permission to track location-based information from your mobile device,
                                  either continuously or while you are using our mobile application(s), to provide
                                  certain location-based services. If you wish to change our access or
                                  permissions, you may do so in your device's settings.<bdt
                                          class="statement-end-if-in-editor"></bdt></span></span></li>
  </ul>
  <div style="line-height: 1.5;">
          <bdt class="block-component"><span style="font-size: 15px;"><span
                                  data-custom-class="body_text"></span></span></bdt>
  </div>
  <ul>
          <li style="line-height: 1.5;"><span style="font-size: 15px;"><span
                                  data-custom-class="body_text"><em>Mobile Device Access.</em> We may request
                                  access or permission to certain features from your mobile device, including your
                                  mobile device's <bdt class="forloop-component"></bdt>
                                  <bdt class="question">contacts</bdt>, <bdt class="forloop-component"></bdt>and
                                  other features. If you wish to change our access or permissions, you may do so
                                  in your device's settings.<bdt class="statement-end-if-in-editor"></bdt>
                          </span></span></li>
  </ul>
  <div style="line-height: 1.5;">
          <bdt class="block-component"><span style="font-size: 15px;"><span data-custom-class="body_text"></bdt>
          </span></span></li>
          </ul>
          <div style="line-height: 1.5;">
                  <bdt class="block-component"><span style="font-size: 15px;"><span
                                          data-custom-class="body_text"></span></span></bdt>
          </div>
          <ul>
                  <li style="line-height: 1.5;"><span style="font-size: 15px;"><span
                                          data-custom-class="body_text"><em>Push Notifications.</em> We may
                                          request to send you push notifications regarding your account or certain
                                          features of the application(s). If you wish to opt out from receiving
                                          these types of communications, you may turn them off in your device's
                                          settings.<bdt class="statement-end-if-in-editor"></bdt></span></span>
                  </li>
          </ul>
          <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text">This
                                  information is primarily needed to maintain the security and operation of our
                                  application(s), for troubleshooting, and for our internal analytics and
                                  reporting purposes.</span></span></div>
          <div style="line-height: 1.5;"><br></div>
          <div style="line-height: 1.5;">
                  <bdt class="statement-end-if-in-editor"><span style="font-size: 15px;"><span
                                          data-custom-class="body_text"></span></span></bdt>
          </div>
          <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                          data-custom-class="body_text">All personal information that you provide
                                          to us must be true, complete, and accurate, and you must notify us of
                                          any changes to such personal information.</span></span></span></div>
          <div style="line-height: 1.5;"><br></div>
          <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                          data-custom-class="body_text">
                                          <bdt class="block-component"></bdt>
                                  </span></span></span></div>
          <div style="line-height: 1.5;"><span data-custom-class="heading_2" style="color: rgb(0, 0, 0);"><span
                                  style="font-size: 15px;"><strong>Information automatically
                                          collected</strong></span></span></div>
          <div>
                  <div><br></div>
                  <div style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                  data-custom-class="body_text"><span
                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                          data-custom-class="body_text"><strong><em>In
                                                                                          Short:</em></strong></span></span></span></span><span
                                                  data-custom-class="body_text"><span
                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                          data-custom-class="body_text"><strong><em> </em></strong><em>Some
                                                                                  information — such as your
                                                                                  Internet Protocol (IP) address
                                                                                  and/or browser and device
                                                                                  characteristics — is collected
                                                                                  automatically when you visit our
                                                                                  Services.</em></span></span></span></span></span></span>
                  </div>
          </div>
          <div style="line-height: 1.5;"><br></div>
          <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                          data-custom-class="body_text">We automatically collect certain
                                          information when you visit, use, or navigate the Services. This
                                          information does not reveal your specific identity (like your name or
                                          contact information) but may include device and usage information, such
                                          as your IP address, browser and device characteristics, operating
                                          system, language preferences, referring URLs, device name, country,
                                          location, information about how and when you use our Services, and other
                                          technical information. This information is primarily needed to maintain
                                          the security and operation of our Services, and for our internal
                                          analytics and reporting purposes.</span></span></span></div>
          <div style="line-height: 1.5;"><br></div>
          <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                          data-custom-class="body_text">
                                          <bdt class="block-component"></bdt>
                                  </span></span></span></div>
          <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                          data-custom-class="body_text">Like many businesses, we also collect
                                          information through cookies and similar technologies. <bdt
                                                  class="block-component"></bdt>
                                          <bdt class="block-component"></bdt>
                                  </span></span></span></div>
          <div style="line-height: 1.5;"><br></div>
          <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                          data-custom-class="body_text">
                                          <bdt class="statement-end-if-in-editor"><span
                                                          data-custom-class="body_text"></span></bdt>
                                  </span><span data-custom-class="body_text">
                                          <bdt class="block-component"></bdt>
                                  </span></span></span></div>
          <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                          data-custom-class="body_text">The information we collect includes:<bdt
                                                  class="block-component"></bdt></span></span></span></div>
          <ul>
                  <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                  data-custom-class="body_text"><em>Log and Usage Data.</em> Log
                                                  and usage data is service-related, diagnostic, usage, and
                                                  performance information our servers automatically collect when
                                                  you access or use our Services and which we record in log files.
                                                  Depending on how you interact with us, this log data may include
                                                  your IP address, device information, browser type, and settings
                                                  and information about your activity in the Services<span
                                                          style="font-size: 15px;"> </span>(such as the date/time
                                                  stamps associated with your usage, pages and files viewed,
                                                  searches, and other actions you take such as which features you
                                                  use), device event information (such as system activity, error
                                                  reports (sometimes called <bdt class="block-component"></bdt>
                                                  "crash dumps"<bdt class="statement-end-if-in-editor"></bdt>),
                                                  and hardware settings).<span style="font-size: 15px;"><span
                                                                  style="color: rgb(89, 89, 89);"><span
                                                                          data-custom-class="body_text"><span
                                                                                  style="font-size: 15px;"><span
                                                                                          style="color: rgb(89, 89, 89);">
                                                                                          <bdt
                                                                                                  class="statement-end-if-in-editor">
                                                                                          </bdt>
                                                                                  </span></span></span></span></span></span></span></span>
                  </li>
          </ul>
          <div style="line-height: 1.5;">
                  <bdt class="block-component"><span style="font-size: 15px;"><span data-custom-class="body_text">
                  </bdt></span></span></span></span></span></span></span></span></li>
                  </ul>
                  <div style="line-height: 1.5;">
                          <bdt class="block-component"><span style="font-size: 15px;"><span
                                                  data-custom-class="body_text"></span></span></bdt>
                  </div>
                  <ul>
                          <li style="line-height: 1.5;"><span
                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                          data-custom-class="body_text"><em>Location Data.</em> We
                                                          collect location data such as information about your
                                                          device's location, which can be either precise or
                                                          imprecise. How much information we collect depends on
                                                          the type and settings of the device you use to access
                                                          the Services. For example, we may use GPS and other
                                                          technologies to collect geolocation data that tells us
                                                          your current location (based on your IP address). You
                                                          can opt out of allowing us to collect this information
                                                          either by refusing access to the information or by
                                                          disabling your Location setting on your device. However,
                                                          if you choose to opt out, you may not be able to use
                                                          certain aspects of the Services.<span
                                                                  style="font-size: 15px;"><span
                                                                          style="color: rgb(89, 89, 89);"><span
                                                                                  data-custom-class="body_text"><span
                                                                                          style="font-size: 15px;"><span
                                                                                                  style="color: rgb(89, 89, 89);">
                                                                                                  <bdt
                                                                                                          class="statement-end-if-in-editor">
                                                                                                  </bdt>
                                                                                          </span></span></span></span></span></span></span></span>
                          </li>
                  </ul>
                  <div>
                          <bdt class="block-component"><span style="font-size: 15px;"></bdt>
                          </bdt>
                          <bdt class="statement-end-if-in-editor"></bdt>
                          </bdt></span><span data-custom-class="body_text"><span
                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                  data-custom-class="body_text"><span
                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                  data-custom-class="body_text">
                                                                  <bdt class="statement-end-if-in-editor">
                                                                          <bdt class="block-component"></bdt>
                                                                  </bdt>
                                                          </span></span></span></span></bdt>
                                  </span></span></span></span></span></span></span></span><span
                                  style="font-size: 15px;"><span data-custom-class="body_text">
                                          <bdt class="block-component"></bdt>
                                  </span></span>
                  </div>
                  <div id="infouse" style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                  id="control"
                                                                  style="color: rgb(0, 0, 0);"><strong><span
                                                                                  data-custom-class="heading_1">2.
                                                                                  HOW DO WE PROCESS YOUR
                                                                                  INFORMATION?</span></strong></span></span></span></span></span>
                  </div>
                  <div>
                          <div style="line-height: 1.5;"><br></div>
                          <div style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                          data-custom-class="body_text"><span
                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                  data-custom-class="body_text"><strong><em>In
                                                                                                  Short: </em></strong><em>We
                                                                                          process your information
                                                                                          to provide, improve, and
                                                                                          administer our Services,
                                                                                          communicate with you,
                                                                                          for security and fraud
                                                                                          prevention, and to
                                                                                          comply with law. We may
                                                                                          also process your
                                                                                          information for other
                                                                                          purposes with your
                                                                                          consent.</em></span></span></span></span></span></span>
                          </div>
                  </div>
                  <div style="line-height: 1.5;"><br></div>
                  <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                  data-custom-class="body_text"><strong>We process your personal
                                                          information for a variety of reasons, depending on how
                                                          you interact with our Services, including:</strong>
                                                  <bdt class="block-component"></bdt>
                                          </span></span></span></div>
                  <ul>
                          <li style="line-height: 1.5;"><span
                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                          data-custom-class="body_text"><strong>To facilitate
                                                                  account creation and authentication and
                                                                  otherwise manage user accounts. </strong>We may
                                                          process your information so you can create and log in to
                                                          your account, as well as keep your account in working
                                                          order.<span
                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                  data-custom-class="body_text"><span
                                                                                          style="font-size: 15px;"><span
                                                                                                  style="color: rgb(89, 89, 89);"><span
                                                                                                          data-custom-class="body_text"><span
                                                                                                                  style="font-size: 15px;"><span
                                                                                                                          style="color: rgb(89, 89, 89);"><span
                                                                                                                                  data-custom-class="body_text">
                                                                                                                                  <bdt
                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                  </bdt>
                                                                                                                          </span></span></span></span></span></span></span></span></span></span></span></span>
                          </li>
                  </ul>
                  <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                  data-custom-class="body_text">
                                                  <bdt class="block-component"></bdt>
                                          </span></span></span></span></span></span></span></span></span></span></span></span></span></span></span>
                          </li>
                          </ul>
                          <div style="line-height: 1.5;"><span
                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                          data-custom-class="body_text">
                                                          <bdt class="block-component"></bdt>
                                                  </span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span>
                                  </li>
                                  </ul>
                                  <div style="line-height: 1.5;"><span
                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                  data-custom-class="body_text">
                                                                  <bdt class="block-component"></bdt>
                                                          </span></span></span></span></span></span></span></span></span></span></span></span>
                                          </li>
                                          </ul>
                                          <div style="line-height: 1.5;"><span
                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                          data-custom-class="body_text">
                                                                          <bdt class="block-component"></bdt>
                                                                  </span></span></span></li>
                                                  </ul>
                                                  <div style="line-height: 1.5;">
                                                          <bdt class="block-component"><span
                                                                          style="font-size: 15px;"></bdt>
                                                          </span></span></span></span></span></span></li>
                                                          </ul>
                                                          <div style="line-height: 1.5;">
                                                                  <bdt class="block-component"><span
                                                                                  style="font-size: 15px;"></bdt>
                                                                  </span></span></span></span></span></span></span></span></span>
                                                                  </li>
                                                                  </ul>
                                                                  <div style="line-height: 1.5;">
                                                                          <bdt class="block-component"><span
                                                                                          style="font-size: 15px;">
                                                                          </bdt>
                                                                          </span></span></span></span></span></span></span></span></span></span></span></span>
                                                                          </li>
                                                                          </ul>
                                                                          <div style="line-height: 1.5;">
                                                                                  <bdt class="block-component">
                                                                                          <span
                                                                                                  style="font-size: 15px;"><span
                                                                                                          data-custom-class="body_text"></span></span>
                                                                                  </bdt>
                                                                                  </li>
                                                                                  </ul>
                                                                                  <p
                                                                                          style="font-size: 15px; line-height: 1.5;">
                                                                                          <bdt
                                                                                                  class="block-component">
                                                                                                  <span
                                                                                                          style="font-size: 15px;"></span>
                                                                                          </bdt>
                                                                                  </p>
                                                                                  <ul>
                                                                                          <li
                                                                                                  style="line-height: 1.5;">
                                                                                                  <span
                                                                                                          style="font-size: 15px;"><span
                                                                                                                  style="color: rgb(89, 89, 89);"><span
                                                                                                                          style="color: rgb(89, 89, 89);"><span
                                                                                                                                  data-custom-class="body_text"><strong>To
                                                                                                                                          enable
                                                                                                                                          user-to-user
                                                                                                                                          communications. </strong>We
                                                                                                                                  may
                                                                                                                                  process
                                                                                                                                  your
                                                                                                                                  information
                                                                                                                                  if
                                                                                                                                  you
                                                                                                                                  choose
                                                                                                                                  to
                                                                                                                                  use
                                                                                                                                  any
                                                                                                                                  of
                                                                                                                                  our
                                                                                                                                  offerings
                                                                                                                                  that
                                                                                                                                  allow
                                                                                                                                  for
                                                                                                                                  communication
                                                                                                                                  with
                                                                                                                                  another
                                                                                                                                  user.<span
                                                                                                                                          style="color: rgb(89, 89, 89);"><span
                                                                                                                                                  style="color: rgb(89, 89, 89);"><span
                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                  style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                                  style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                          <bdt
                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                          </bdt>
                                                                                                                                                                                  </span></span></span></span></span></span></span></span></span></span></span>
                                                                                          </li>
                                                                                  </ul>
                                                                                  <p
                                                                                          style="font-size: 15px; line-height: 1.5;">
                                                                                          <bdt
                                                                                                  class="block-component">
                                                                                                  <span
                                                                                                          style="font-size: 15px;">
                                                                                          </bdt>
                                                                                          </span></span></span></span></span></span></span></span></span></span></span>
                                                                                          </li>
                                                                                          </ul>
                                                                                  <p
                                                                                          style="font-size: 15px; line-height: 1.5;">
                                                                                          <bdt
                                                                                                  class="block-component">
                                                                                          </bdt>
                                                                                          </span></span></span></span></span></span></span></span></span></span></span>
                                                                                          </li>
                                                                                          </ul>
                                                                                  <p
                                                                                          style="font-size: 15px; line-height: 1.5;">
                                                                                          <bdt
                                                                                                  class="block-component">
                                                                                          </bdt>
                                                                                          </span></span></span></span></span></span></span></span></span></span></span>
                                                                                          </li>
                                                                                          </ul>
                                                                                  <div style="line-height: 1.5;">
                                                                                          <bdt
                                                                                                  class="block-component">
                                                                                                  <span
                                                                                                          style="font-size: 15px;"><span
                                                                                                                  data-custom-class="body_text"></span></span>
                                                                                          </bdt>
                                                                                  </div>
                                                                                  <ul>
                                                                                          <li
                                                                                                  style="line-height: 1.5;">
                                                                                                  <span
                                                                                                          style="font-size: 15px;"><span
                                                                                                                  data-custom-class="body_text"><strong>To
                                                                                                                          send
                                                                                                                          you
                                                                                                                          marketing
                                                                                                                          and
                                                                                                                          promotional
                                                                                                                          communications. </strong>We
                                                                                                                  may
                                                                                                                  process
                                                                                                                  the
                                                                                                                  personal
                                                                                                                  information
                                                                                                                  you
                                                                                                                  send
                                                                                                                  to
                                                                                                                  us
                                                                                                                  for
                                                                                                                  our
                                                                                                                  marketing
                                                                                                                  purposes,
                                                                                                                  if
                                                                                                                  this
                                                                                                                  is
                                                                                                                  in
                                                                                                                  accordance
                                                                                                                  with
                                                                                                                  your
                                                                                                                  marketing
                                                                                                                  preferences.
                                                                                                                  You
                                                                                                                  can
                                                                                                                  opt
                                                                                                                  out
                                                                                                                  of
                                                                                                                  our
                                                                                                                  marketing
                                                                                                                  emails
                                                                                                                  at
                                                                                                                  any
                                                                                                                  time.
                                                                                                                  For
                                                                                                                  more
                                                                                                                  information,
                                                                                                                  see
                                                                                                                  <bdt
                                                                                                                          class="block-component">
                                                                                                                  </bdt>
                                                                                                                  "
                                                                                                                  <bdt
                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                  </bdt>
                                                                                                                  </span></span><a
                                                                                                          href="#privacyrights"><span
                                                                                                                  data-custom-class="link"><span
                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                  data-custom-class="link">WHAT
                                                                                                                                  ARE
                                                                                                                                  YOUR
                                                                                                                                  PRIVACY
                                                                                                                                  RIGHTS?</span></span></span></a><span
                                                                                                          style="font-size: 15px;"><span
                                                                                                                  data-custom-class="body_text">
                                                                                                                  <bdt
                                                                                                                          class="block-component">
                                                                                                                  </bdt>
                                                                                                                  "
                                                                                                                  <bdt
                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                  </bdt>
                                                                                                                  below.
                                                                                                          </span>
                                                                                                          <bdt
                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                  <span
                                                                                                                          data-custom-class="body_text"></span>
                                                                                                          </bdt>
                                                                                                  </span></li>
                                                                                  </ul>
                                                                                  <div style="line-height: 1.5;">
                                                                                          <bdt
                                                                                                  class="block-component">
                                                                                                  <span
                                                                                                          style="font-size: 15px;">
                                                                                          </bdt>
                                                                                          </span></span></span>
                                                                                          </li>
                                                                                          </ul>
                                                                                          <div
                                                                                                  style="line-height: 1.5;">
                                                                                                  <bdt
                                                                                                          class="block-component">
                                                                                                          <span
                                                                                                                  style="font-size: 15px;"></span>
                                                                                                  </bdt>
                                                                                          </div>
                                                                                          <ul>
                                                                                                  <li
                                                                                                          style="line-height: 1.5;">
                                                                                                          <span
                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                  data-custom-class="body_text"><strong>To
                                                                                                                                          deliver
                                                                                                                                          targeted
                                                                                                                                          advertising
                                                                                                                                          to
                                                                                                                                          you. </strong>We
                                                                                                                                  may
                                                                                                                                  process
                                                                                                                                  your
                                                                                                                                  information
                                                                                                                                  to
                                                                                                                                  develop
                                                                                                                                  and
                                                                                                                                  display
                                                                                                                                  <bdt
                                                                                                                                          class="block-component">
                                                                                                                                  </bdt>
                                                                                                                                  personalized
                                                                                                                                  <bdt
                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                  </bdt>
                                                                                                                                  content
                                                                                                                                  and
                                                                                                                                  advertising
                                                                                                                                  tailored
                                                                                                                                  to
                                                                                                                                  your
                                                                                                                                  interests,
                                                                                                                                  location,
                                                                                                                                  and
                                                                                                                                  more.
                                                                                                                                  <bdt
                                                                                                                                          class="block-component">
                                                                                                                                  </bdt>
                                                                                                                                  </span></span></span>
                                                                                                  </li>
                                                                                          </ul>
                                                                                          <div
                                                                                                  style="line-height: 1.5;">
                                                                                                  <span
                                                                                                          style="font-size: 15px;">
                                                                                                          <bdt
                                                                                                                  class="block-component">
                                                                                                                  <span
                                                                                                                          data-custom-class="body_text">
                                                                                                          </bdt>
                                                                                                  </span></span>
                                                                                                  </li>
                                                                                                  </ul>
                                                                                                  <div
                                                                                                          style="line-height: 1.5;">
                                                                                                          <bdt
                                                                                                                  class="block-component">
                                                                                                                  <span
                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                  data-custom-class="body_text">
                                                                                                          </bdt>
                                                                                                          </span></span>
                                                                                                          </li>
                                                                                                          </ul>
                                                                                                          <div
                                                                                                                  style="line-height: 1.5;">
                                                                                                                  <bdt
                                                                                                                          class="block-component">
                                                                                                                          <span
                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                          data-custom-class="body_text"></span></span>
                                                                                                                  </bdt>
                                                                                                                  </li>
                                                                                                                  </ul>
                                                                                                                  <div
                                                                                                                          style="line-height: 1.5;">
                                                                                                                          <bdt
                                                                                                                                  class="block-component">
                                                                                                                                  <span
                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                  data-custom-class="body_text"></span></span>
                                                                                                                          </bdt>
                                                                                                                          </li>
                                                                                                                          </ul>
                                                                                                                          <div
                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                  <bdt
                                                                                                                                          class="block-component">
                                                                                                                                          <span
                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                          data-custom-class="body_text"></span></span>
                                                                                                                                  </bdt>
                                                                                                                                  </li>
                                                                                                                                  </ul>
                                                                                                                                  <div
                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                          <bdt
                                                                                                                                                  class="block-component">
                                                                                                                                                  <span
                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                  data-custom-class="body_text"></span></span>
                                                                                                                                          </bdt>
                                                                                                                                  </div>
                                                                                                                                  <ul>
                                                                                                                                          <li
                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                  <span
                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                  style="font-size: 15px;"><strong>To
                                                                                                                                                                          evaluate
                                                                                                                                                                          and
                                                                                                                                                                          improve
                                                                                                                                                                          our
                                                                                                                                                                          Services,
                                                                                                                                                                          products,
                                                                                                                                                                          marketing,
                                                                                                                                                                          and
                                                                                                                                                                          your
                                                                                                                                                                          experience.</strong>
                                                                                                                                                                  We
                                                                                                                                                                  may
                                                                                                                                                                  process
                                                                                                                                                                  your
                                                                                                                                                                  information
                                                                                                                                                                  when
                                                                                                                                                                  we
                                                                                                                                                                  believe
                                                                                                                                                                  it
                                                                                                                                                                  is
                                                                                                                                                                  necessary
                                                                                                                                                                  to
                                                                                                                                                                  identify
                                                                                                                                                                  usage
                                                                                                                                                                  trends,
                                                                                                                                                                  determine
                                                                                                                                                                  the
                                                                                                                                                                  effectiveness
                                                                                                                                                                  of
                                                                                                                                                                  our
                                                                                                                                                                  promotional
                                                                                                                                                                  campaigns,
                                                                                                                                                                  and
                                                                                                                                                                  to
                                                                                                                                                                  evaluate
                                                                                                                                                                  and
                                                                                                                                                                  improve
                                                                                                                                                                  our
                                                                                                                                                                  Services,
                                                                                                                                                                  products,
                                                                                                                                                                  marketing,
                                                                                                                                                                  and
                                                                                                                                                                  your
                                                                                                                                                                  experience.</span></span>
                                                                                                                                                  <bdt
                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                          <span
                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                          data-custom-class="body_text"></span></span>
                                                                                                                                                  </bdt>
                                                                                                                                          </li>
                                                                                                                                  </ul>
                                                                                                                                  <div
                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                          <bdt
                                                                                                                                                  class="block-component">
                                                                                                                                                  <span
                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                  data-custom-class="body_text"></span></span>
                                                                                                                                          </bdt>
                                                                                                                                  </div>
                                                                                                                                  <ul>
                                                                                                                                          <li
                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                  <span
                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                  style="font-size: 15px;"><strong>To
                                                                                                                                                                          identify
                                                                                                                                                                          usage
                                                                                                                                                                          trends.</strong>
                                                                                                                                                                  We
                                                                                                                                                                  may
                                                                                                                                                                  process
                                                                                                                                                                  information
                                                                                                                                                                  about
                                                                                                                                                                  how
                                                                                                                                                                  you
                                                                                                                                                                  use
                                                                                                                                                                  our
                                                                                                                                                                  Services
                                                                                                                                                                  to
                                                                                                                                                                  better
                                                                                                                                                                  understand
                                                                                                                                                                  how
                                                                                                                                                                  they
                                                                                                                                                                  are
                                                                                                                                                                  being
                                                                                                                                                                  used
                                                                                                                                                                  so
                                                                                                                                                                  we
                                                                                                                                                                  can
                                                                                                                                                                  improve
                                                                                                                                                                  them.</span></span>
                                                                                                                                                  <bdt
                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                          <span
                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                          data-custom-class="body_text"></span></span>
                                                                                                                                                  </bdt>
                                                                                                                                          </li>
                                                                                                                                  </ul>
                                                                                                                                  <div
                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                          <bdt
                                                                                                                                                  class="block-component">
                                                                                                                                                  <span
                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                  data-custom-class="body_text"></span></span>
                                                                                                                                          </bdt>
                                                                                                                                          </li>
                                                                                                                                          </ul>
                                                                                                                                          <div
                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                  <bdt
                                                                                                                                                          class="block-component">
                                                                                                                                                          <span
                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                          data-custom-class="body_text"></span></span>
                                                                                                                                                  </bdt>
                                                                                                                                          </div>
                                                                                                                                          <ul>
                                                                                                                                                  <li
                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                          <span
                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                          data-custom-class="body_text"><strong>To
                                                                                                                                                                                  determine
                                                                                                                                                                                  the
                                                                                                                                                                                  effectiveness
                                                                                                                                                                                  of
                                                                                                                                                                                  our
                                                                                                                                                                                  marketing
                                                                                                                                                                                  and
                                                                                                                                                                                  promotional
                                                                                                                                                                                  campaigns.</strong>
                                                                                                                                                                          We
                                                                                                                                                                          may
                                                                                                                                                                          process
                                                                                                                                                                          your
                                                                                                                                                                          information
                                                                                                                                                                          to
                                                                                                                                                                          better
                                                                                                                                                                          understand
                                                                                                                                                                          how
                                                                                                                                                                          to
                                                                                                                                                                          provide
                                                                                                                                                                          marketing
                                                                                                                                                                          and
                                                                                                                                                                          promotional
                                                                                                                                                                          campaigns
                                                                                                                                                                          that
                                                                                                                                                                          are
                                                                                                                                                                          most
                                                                                                                                                                          relevant
                                                                                                                                                                          to
                                                                                                                                                                          you.
                                                                                                                                                                          <bdt
                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                          </bdt>
                                                                                                                                                                          </span></span>
                                                                                                                                                  </li>
                                                                                                                                          </ul>
                                                                                                                                          <div
                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                  <bdt
                                                                                                                                                          class="block-component">
                                                                                                                                                          <span
                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                  </bdt>
                                                                                                                                                  </span></span>
                                                                                                                                                  </li>
                                                                                                                                                  </ul>
                                                                                                                                                  <div
                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                          <bdt
                                                                                                                                                                  class="block-component">
                                                                                                                                                                  <span
                                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                          </bdt>
                                                                                                                                                          </span></span>
                                                                                                                                                          </li>
                                                                                                                                                          </ul>
                                                                                                                                                          <div
                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                  <bdt
                                                                                                                                                                          class="block-component">
                                                                                                                                                                          <span
                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                          data-custom-class="body_text"></span></span>
                                                                                                                                                                  </bdt>
                                                                                                                                                                  </li>
                                                                                                                                                                  </ul>
                                                                                                                                                                  <div
                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                          <bdt
                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                  <span
                                                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                                                  data-custom-class="body_text"></span></span>
                                                                                                                                                                          </bdt>
                                                                                                                                                                          <bdt
                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                  <span
                                                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                          </bdt>
                                                                                                                                                                          </span></span>
                                                                                                                                                                          <bdt
                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                  <span
                                                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                                                  data-custom-class="body_text"></span></span>
                                                                                                                                                                          </bdt>
                                                                                                                                                                          <bdt
                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                  <span
                                                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                                                  data-custom-class="body_text"></span></span>
                                                                                                                                                                          </bdt>
                                                                                                                                                                  </div>
                                                                                                                                                                  <div
                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                          <br>
                                                                                                                                                                  </div>
                                                                                                                                                                  <div id="whoshare"
                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                          <span
                                                                                                                                                                                  style="color: rgb(127, 127, 127);"><span
                                                                                                                                                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                  id="control"
                                                                                                                                                                                                                  style="color: rgb(0, 0, 0);"><strong><span
                                                                                                                                                                                                                                  data-custom-class="heading_1">3.
                                                                                                                                                                                                                                  WHEN
                                                                                                                                                                                                                                  AND
                                                                                                                                                                                                                                  WITH
                                                                                                                                                                                                                                  WHOM
                                                                                                                                                                                                                                  DO
                                                                                                                                                                                                                                  WE
                                                                                                                                                                                                                                  SHARE
                                                                                                                                                                                                                                  YOUR
                                                                                                                                                                                                                                  PERSONAL
                                                                                                                                                                                                                                  INFORMATION?</span></strong></span></span></span></span></span>
                                                                                                                                                                  </div>
                                                                                                                                                                  <div
                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                          <br>
                                                                                                                                                                  </div>
                                                                                                                                                                  <div
                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                          <span
                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                  data-custom-class="body_text"><strong><em>In
                                                                                                                                                                                                                  Short:</em></strong><em> We
                                                                                                                                                                                                          may
                                                                                                                                                                                                          share
                                                                                                                                                                                                          information
                                                                                                                                                                                                          in
                                                                                                                                                                                                          specific
                                                                                                                                                                                                          situations
                                                                                                                                                                                                          described
                                                                                                                                                                                                          in
                                                                                                                                                                                                          this
                                                                                                                                                                                                          section
                                                                                                                                                                                                          and/or
                                                                                                                                                                                                          with
                                                                                                                                                                                                          the
                                                                                                                                                                                                          following
                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                          third
                                                                                                                                                                                                          parties.</em></span></span> </span>
                                                                                                                                                                  </div>
                                                                                                                                                                  <div
                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                          <span
                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                          </span>
                                                                                                                                                                  </div>
                                                                                                                                                                  <div
                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                          <br>
                                                                                                                                                                  </div>
                                                                                                                                                                  <div
                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                          <span
                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                          data-custom-class="body_text">We
                                                                                                                                                                                          <bdt
                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                          </bdt>
                                                                                                                                                                                          may
                                                                                                                                                                                          need
                                                                                                                                                                                          to
                                                                                                                                                                                          share
                                                                                                                                                                                          your
                                                                                                                                                                                          personal
                                                                                                                                                                                          information
                                                                                                                                                                                          in
                                                                                                                                                                                          the
                                                                                                                                                                                          following
                                                                                                                                                                                          situations:</span></span>
                                                                                                                                                                  </div>
                                                                                                                                                                  <ul>
                                                                                                                                                                          <li
                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                  <span
                                                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                                                  data-custom-class="body_text"><strong>Business
                                                                                                                                                                                                          Transfers.</strong>
                                                                                                                                                                                                  We
                                                                                                                                                                                                  may
                                                                                                                                                                                                  share
                                                                                                                                                                                                  or
                                                                                                                                                                                                  transfer
                                                                                                                                                                                                  your
                                                                                                                                                                                                  information
                                                                                                                                                                                                  in
                                                                                                                                                                                                  connection
                                                                                                                                                                                                  with,
                                                                                                                                                                                                  or
                                                                                                                                                                                                  during
                                                                                                                                                                                                  negotiations
                                                                                                                                                                                                  of,
                                                                                                                                                                                                  any
                                                                                                                                                                                                  merger,
                                                                                                                                                                                                  sale
                                                                                                                                                                                                  of
                                                                                                                                                                                                  company
                                                                                                                                                                                                  assets,
                                                                                                                                                                                                  financing,
                                                                                                                                                                                                  or
                                                                                                                                                                                                  acquisition
                                                                                                                                                                                                  of
                                                                                                                                                                                                  all
                                                                                                                                                                                                  or
                                                                                                                                                                                                  a
                                                                                                                                                                                                  portion
                                                                                                                                                                                                  of
                                                                                                                                                                                                  our
                                                                                                                                                                                                  business
                                                                                                                                                                                                  to
                                                                                                                                                                                                  another
                                                                                                                                                                                                  company.</span></span>
                                                                                                                                                                          </li>
                                                                                                                                                                  </ul>
                                                                                                                                                                  <div
                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                          <span
                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                          <bdt
                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                          </bdt>
                                                                                                                                                                                  </span></span>
                                                                                                                                                                  </div>
                                                                                                                                                                  <ul>
                                                                                                                                                                          <li
                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                  <span
                                                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                                                  data-custom-class="body_text"><strong>When
                                                                                                                                                                                                          we
                                                                                                                                                                                                          use
                                                                                                                                                                                                          Google
                                                                                                                                                                                                          Maps
                                                                                                                                                                                                          Platform
                                                                                                                                                                                                          APIs.</strong>
                                                                                                                                                                                                  We
                                                                                                                                                                                                  may
                                                                                                                                                                                                  share
                                                                                                                                                                                                  your
                                                                                                                                                                                                  information
                                                                                                                                                                                                  with
                                                                                                                                                                                                  certain
                                                                                                                                                                                                  Google
                                                                                                                                                                                                  Maps
                                                                                                                                                                                                  Platform
                                                                                                                                                                                                  APIs
                                                                                                                                                                                                  (e.g.
                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                  ,
                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                  Google
                                                                                                                                                                                                  Maps
                                                                                                                                                                                                  API,
                                                                                                                                                                                                  Places
                                                                                                                                                                                                  API).</span><span
                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                          </span></span>
                                                                                                                                                                          </li>
                                                                                                                                                                  </ul>
                                                                                                                                                                  <div
                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                          <span
                                                                                                                                                                                  style="font-size: 15px;">
                                                                                                                                                                                  <bdt
                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                          <span
                                                                                                                                                                                                  data-custom-class="body_text"></span>
                                                                                                                                                                                  </bdt>
                                                                                                                                                                          </span>
                                                                                                                                                                          </li>
                                                                                                                                                                          </ul>
                                                                                                                                                                          <div
                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                  <bdt
                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                          <span
                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                          data-custom-class="body_text"></span></span>
                                                                                                                                                                                  </bdt>
                                                                                                                                                                                  </li>
                                                                                                                                                                                  </ul>
                                                                                                                                                                                  <div
                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                          <bdt
                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                                                                  data-custom-class="body_text"></span></span>
                                                                                                                                                                                          </bdt>
                                                                                                                                                                                  </div>
                                                                                                                                                                                  <ul>
                                                                                                                                                                                          <li
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                                                                  data-custom-class="body_text"><strong>Other
                                                                                                                                                                                                                          Users.</strong>
                                                                                                                                                                                                                  When
                                                                                                                                                                                                                  you
                                                                                                                                                                                                                  share
                                                                                                                                                                                                                  personal
                                                                                                                                                                                                                  information
                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                  or
                                                                                                                                                                                                                  otherwise
                                                                                                                                                                                                                  interact
                                                                                                                                                                                                                  with
                                                                                                                                                                                                                  public
                                                                                                                                                                                                                  areas
                                                                                                                                                                                                                  of
                                                                                                                                                                                                                  the
                                                                                                                                                                                                                  Services,
                                                                                                                                                                                                                  such
                                                                                                                                                                                                                  personal
                                                                                                                                                                                                                  information
                                                                                                                                                                                                                  may
                                                                                                                                                                                                                  be
                                                                                                                                                                                                                  viewed
                                                                                                                                                                                                                  by
                                                                                                                                                                                                                  all
                                                                                                                                                                                                                  users
                                                                                                                                                                                                                  and
                                                                                                                                                                                                                  may
                                                                                                                                                                                                                  be
                                                                                                                                                                                                                  publicly
                                                                                                                                                                                                                  made
                                                                                                                                                                                                                  available
                                                                                                                                                                                                                  outside
                                                                                                                                                                                                                  the
                                                                                                                                                                                                                  Services
                                                                                                                                                                                                                  in
                                                                                                                                                                                                                  perpetuity.
                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                  If
                                                                                                                                                                                                                  you
                                                                                                                                                                                                                  interact
                                                                                                                                                                                                                  with
                                                                                                                                                                                                                  other
                                                                                                                                                                                                                  users
                                                                                                                                                                                                                  of
                                                                                                                                                                                                                  our
                                                                                                                                                                                                                  Services
                                                                                                                                                                                                                  and
                                                                                                                                                                                                                  register
                                                                                                                                                                                                                  for
                                                                                                                                                                                                                  our
                                                                                                                                                                                                                  Services
                                                                                                                                                                                                                  through
                                                                                                                                                                                                                  a
                                                                                                                                                                                                                  social
                                                                                                                                                                                                                  network
                                                                                                                                                                                                                  (such
                                                                                                                                                                                                                  as
                                                                                                                                                                                                                  Facebook),
                                                                                                                                                                                                                  your
                                                                                                                                                                                                                  contacts
                                                                                                                                                                                                                  on
                                                                                                                                                                                                                  the
                                                                                                                                                                                                                  social
                                                                                                                                                                                                                  network
                                                                                                                                                                                                                  will
                                                                                                                                                                                                                  see
                                                                                                                                                                                                                  your
                                                                                                                                                                                                                  name,
                                                                                                                                                                                                                  profile
                                                                                                                                                                                                                  photo,
                                                                                                                                                                                                                  and
                                                                                                                                                                                                                  descriptions
                                                                                                                                                                                                                  of
                                                                                                                                                                                                                  your
                                                                                                                                                                                                                  activity.
                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                  Similarly,
                                                                                                                                                                                                                  other
                                                                                                                                                                                                                  users
                                                                                                                                                                                                                  will
                                                                                                                                                                                                                  be
                                                                                                                                                                                                                  able
                                                                                                                                                                                                                  to
                                                                                                                                                                                                                  view
                                                                                                                                                                                                                  descriptions
                                                                                                                                                                                                                  of
                                                                                                                                                                                                                  your
                                                                                                                                                                                                                  activity,
                                                                                                                                                                                                                  communicate
                                                                                                                                                                                                                  with
                                                                                                                                                                                                                  you
                                                                                                                                                                                                                  within
                                                                                                                                                                                                                  our
                                                                                                                                                                                                                  Services,
                                                                                                                                                                                                                  and
                                                                                                                                                                                                                  view
                                                                                                                                                                                                                  your
                                                                                                                                                                                                                  profile.
                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                  </span></span>
                                                                                                                                                                                          </li>
                                                                                                                                                                                  </ul>
                                                                                                                                                                                  <div
                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                          <bdt
                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                                                                  data-custom-class="body_text"></span></span>
                                                                                                                                                                                          </bdt>
                                                                                                                                                                                          <span
                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                          style="font-size: 15px;">
                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                  </span></span>
                                                                                                                                                                                          <bdt
                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                                                                  data-custom-class="body_text"></span></span>
                                                                                                                                                                                          </bdt>
                                                                                                                                                                                          </li>
                                                                                                                                                                                          </ul>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                          <span
                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                          data-custom-class="body_text"></span></span>
                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                          style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89);">
                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                                          data-custom-class="heading_1"></span>
                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                  </span></span></span></span></span>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <br>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div id="cookies"
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          style="color: rgb(127, 127, 127);"><span
                                                                                                                                                                                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                          id="control"
                                                                                                                                                                                                                                          style="color: rgb(0, 0, 0);"><strong><span
                                                                                                                                                                                                                                                          data-custom-class="heading_1">4.
                                                                                                                                                                                                                                                          DO
                                                                                                                                                                                                                                                          WE
                                                                                                                                                                                                                                                          USE
                                                                                                                                                                                                                                                          COOKIES
                                                                                                                                                                                                                                                          AND
                                                                                                                                                                                                                                                          OTHER
                                                                                                                                                                                                                                                          TRACKING
                                                                                                                                                                                                                                                          TECHNOLOGIES?</span></strong></span></span></span></span></span>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <br>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                          data-custom-class="body_text"><strong><em>In
                                                                                                                                                                                                                                          Short:</em></strong><em> We
                                                                                                                                                                                                                                  may
                                                                                                                                                                                                                                  use
                                                                                                                                                                                                                                  cookies
                                                                                                                                                                                                                                  and
                                                                                                                                                                                                                                  other
                                                                                                                                                                                                                                  tracking
                                                                                                                                                                                                                                  technologies
                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                  collect
                                                                                                                                                                                                                                  and
                                                                                                                                                                                                                                  store
                                                                                                                                                                                                                                  your
                                                                                                                                                                                                                                  information.</em></span></span></span>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <br>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                          data-custom-class="body_text">We
                                                                                                                                                                                                                          may
                                                                                                                                                                                                                          use
                                                                                                                                                                                                                          cookies
                                                                                                                                                                                                                          and
                                                                                                                                                                                                                          similar
                                                                                                                                                                                                                          tracking
                                                                                                                                                                                                                          technologies
                                                                                                                                                                                                                          (like
                                                                                                                                                                                                                          web
                                                                                                                                                                                                                          beacons
                                                                                                                                                                                                                          and
                                                                                                                                                                                                                          pixels)
                                                                                                                                                                                                                          to
                                                                                                                                                                                                                          access
                                                                                                                                                                                                                          or
                                                                                                                                                                                                                          store
                                                                                                                                                                                                                          information.
                                                                                                                                                                                                                          Specific
                                                                                                                                                                                                                          information
                                                                                                                                                                                                                          about
                                                                                                                                                                                                                          how
                                                                                                                                                                                                                          we
                                                                                                                                                                                                                          use
                                                                                                                                                                                                                          such
                                                                                                                                                                                                                          technologies
                                                                                                                                                                                                                          and
                                                                                                                                                                                                                          how
                                                                                                                                                                                                                          you
                                                                                                                                                                                                                          can
                                                                                                                                                                                                                          refuse
                                                                                                                                                                                                                          certain
                                                                                                                                                                                                                          cookies
                                                                                                                                                                                                                          is
                                                                                                                                                                                                                          set
                                                                                                                                                                                                                          out
                                                                                                                                                                                                                          in
                                                                                                                                                                                                                          our
                                                                                                                                                                                                                          Cookie
                                                                                                                                                                                                                          Notice<span
                                                                                                                                                                                                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                          .
                                                                                                                                                                                                                                  </span><span
                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                          </span></span></span></span></span></span></span></span><span
                                                                                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                                                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                  style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                  style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                  </span></span></span></span></span></span></span></span></span></span></span>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <br>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div id="sociallogins"
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          style="color: rgb(127, 127, 127);"><span
                                                                                                                                                                                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                          id="control"
                                                                                                                                                                                                                                          style="color: rgb(0, 0, 0);"><strong><span
                                                                                                                                                                                                                                                          data-custom-class="heading_1">5.
                                                                                                                                                                                                                                                          HOW
                                                                                                                                                                                                                                                          DO
                                                                                                                                                                                                                                                          WE
                                                                                                                                                                                                                                                          HANDLE
                                                                                                                                                                                                                                                          YOUR
                                                                                                                                                                                                                                                          SOCIAL
                                                                                                                                                                                                                                                          LOGINS?</span></strong></span></span></span></span></span>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <br>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                          data-custom-class="body_text"><strong><em>In
                                                                                                                                                                                                                                          Short: </em></strong><em>If
                                                                                                                                                                                                                                  you
                                                                                                                                                                                                                                  choose
                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                  register
                                                                                                                                                                                                                                  or
                                                                                                                                                                                                                                  log
                                                                                                                                                                                                                                  in
                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                  our
                                                                                                                                                                                                                                  Services
                                                                                                                                                                                                                                  using
                                                                                                                                                                                                                                  a
                                                                                                                                                                                                                                  social
                                                                                                                                                                                                                                  media
                                                                                                                                                                                                                                  account,
                                                                                                                                                                                                                                  we
                                                                                                                                                                                                                                  may
                                                                                                                                                                                                                                  have
                                                                                                                                                                                                                                  access
                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                  certain
                                                                                                                                                                                                                                  information
                                                                                                                                                                                                                                  about
                                                                                                                                                                                                                                  you.</em></span></span></span>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <br>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                          data-custom-class="body_text">Our
                                                                                                                                                                                                                          Services
                                                                                                                                                                                                                          offer
                                                                                                                                                                                                                          you
                                                                                                                                                                                                                          the
                                                                                                                                                                                                                          ability
                                                                                                                                                                                                                          to
                                                                                                                                                                                                                          register
                                                                                                                                                                                                                          and
                                                                                                                                                                                                                          log
                                                                                                                                                                                                                          in
                                                                                                                                                                                                                          using
                                                                                                                                                                                                                          your
                                                                                                                                                                                                                          third-party
                                                                                                                                                                                                                          social
                                                                                                                                                                                                                          media
                                                                                                                                                                                                                          account
                                                                                                                                                                                                                          details
                                                                                                                                                                                                                          (like
                                                                                                                                                                                                                          your
                                                                                                                                                                                                                          Facebook
                                                                                                                                                                                                                          or
                                                                                                                                                                                                                          Twitter
                                                                                                                                                                                                                          logins).
                                                                                                                                                                                                                          Where
                                                                                                                                                                                                                          you
                                                                                                                                                                                                                          choose
                                                                                                                                                                                                                          to
                                                                                                                                                                                                                          do
                                                                                                                                                                                                                          this,
                                                                                                                                                                                                                          we
                                                                                                                                                                                                                          will
                                                                                                                                                                                                                          receive
                                                                                                                                                                                                                          certain
                                                                                                                                                                                                                          profile
                                                                                                                                                                                                                          information
                                                                                                                                                                                                                          about
                                                                                                                                                                                                                          you
                                                                                                                                                                                                                          from
                                                                                                                                                                                                                          your
                                                                                                                                                                                                                          social
                                                                                                                                                                                                                          media
                                                                                                                                                                                                                          provider.
                                                                                                                                                                                                                          The
                                                                                                                                                                                                                          profile
                                                                                                                                                                                                                          information
                                                                                                                                                                                                                          we
                                                                                                                                                                                                                          receive
                                                                                                                                                                                                                          may
                                                                                                                                                                                                                          vary
                                                                                                                                                                                                                          depending
                                                                                                                                                                                                                          on
                                                                                                                                                                                                                          the
                                                                                                                                                                                                                          social
                                                                                                                                                                                                                          media
                                                                                                                                                                                                                          provider
                                                                                                                                                                                                                          concerned,
                                                                                                                                                                                                                          but
                                                                                                                                                                                                                          will
                                                                                                                                                                                                                          often
                                                                                                                                                                                                                          include
                                                                                                                                                                                                                          your
                                                                                                                                                                                                                          name,
                                                                                                                                                                                                                          email
                                                                                                                                                                                                                          address,
                                                                                                                                                                                                                          friends
                                                                                                                                                                                                                          list,
                                                                                                                                                                                                                          and
                                                                                                                                                                                                                          profile
                                                                                                                                                                                                                          picture,
                                                                                                                                                                                                                          as
                                                                                                                                                                                                                          well
                                                                                                                                                                                                                          as
                                                                                                                                                                                                                          other
                                                                                                                                                                                                                          information
                                                                                                                                                                                                                          you
                                                                                                                                                                                                                          choose
                                                                                                                                                                                                                          to
                                                                                                                                                                                                                          make
                                                                                                                                                                                                                          public
                                                                                                                                                                                                                          on
                                                                                                                                                                                                                          such
                                                                                                                                                                                                                          a
                                                                                                                                                                                                                          social
                                                                                                                                                                                                                          media
                                                                                                                                                                                                                          platform.
                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                          </span></span></span></span></span></span></span></span></span></span>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <br>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                          data-custom-class="body_text">We
                                                                                                                                                                                                                          will
                                                                                                                                                                                                                          use
                                                                                                                                                                                                                          the
                                                                                                                                                                                                                          information
                                                                                                                                                                                                                          we
                                                                                                                                                                                                                          receive
                                                                                                                                                                                                                          only
                                                                                                                                                                                                                          for
                                                                                                                                                                                                                          the
                                                                                                                                                                                                                          purposes
                                                                                                                                                                                                                          that
                                                                                                                                                                                                                          are
                                                                                                                                                                                                                          described
                                                                                                                                                                                                                          in
                                                                                                                                                                                                                          this
                                                                                                                                                                                                                          privacy
                                                                                                                                                                                                                          notice
                                                                                                                                                                                                                          or
                                                                                                                                                                                                                          that
                                                                                                                                                                                                                          are
                                                                                                                                                                                                                          otherwise
                                                                                                                                                                                                                          made
                                                                                                                                                                                                                          clear
                                                                                                                                                                                                                          to
                                                                                                                                                                                                                          you
                                                                                                                                                                                                                          on
                                                                                                                                                                                                                          the
                                                                                                                                                                                                                          relevant
                                                                                                                                                                                                                          Services.
                                                                                                                                                                                                                          Please
                                                                                                                                                                                                                          note
                                                                                                                                                                                                                          that
                                                                                                                                                                                                                          we
                                                                                                                                                                                                                          do
                                                                                                                                                                                                                          not
                                                                                                                                                                                                                          control,
                                                                                                                                                                                                                          and
                                                                                                                                                                                                                          are
                                                                                                                                                                                                                          not
                                                                                                                                                                                                                          responsible
                                                                                                                                                                                                                          for,
                                                                                                                                                                                                                          other
                                                                                                                                                                                                                          uses
                                                                                                                                                                                                                          of
                                                                                                                                                                                                                          your
                                                                                                                                                                                                                          personal
                                                                                                                                                                                                                          information
                                                                                                                                                                                                                          by
                                                                                                                                                                                                                          your
                                                                                                                                                                                                                          third-party
                                                                                                                                                                                                                          social
                                                                                                                                                                                                                          media
                                                                                                                                                                                                                          provider.
                                                                                                                                                                                                                          We
                                                                                                                                                                                                                          recommend
                                                                                                                                                                                                                          that
                                                                                                                                                                                                                          you
                                                                                                                                                                                                                          review
                                                                                                                                                                                                                          their
                                                                                                                                                                                                                          privacy
                                                                                                                                                                                                                          notice
                                                                                                                                                                                                                          to
                                                                                                                                                                                                                          understand
                                                                                                                                                                                                                          how
                                                                                                                                                                                                                          they
                                                                                                                                                                                                                          collect,
                                                                                                                                                                                                                          use,
                                                                                                                                                                                                                          and
                                                                                                                                                                                                                          share
                                                                                                                                                                                                                          your
                                                                                                                                                                                                                          personal
                                                                                                                                                                                                                          information,
                                                                                                                                                                                                                          and
                                                                                                                                                                                                                          how
                                                                                                                                                                                                                          you
                                                                                                                                                                                                                          can
                                                                                                                                                                                                                          set
                                                                                                                                                                                                                          your
                                                                                                                                                                                                                          privacy
                                                                                                                                                                                                                          preferences
                                                                                                                                                                                                                          on
                                                                                                                                                                                                                          their
                                                                                                                                                                                                                          sites
                                                                                                                                                                                                                          and
                                                                                                                                                                                                                          apps.<span
                                                                                                                                                                                                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                          </span>
                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                  </span>
                                                                                                                                                                                                                                                                                  </span></span></span></span></span></span></span></span></span></span></span></span>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <br>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div id="inforetain"
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          style="color: rgb(127, 127, 127);"><span
                                                                                                                                                                                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                          id="control"
                                                                                                                                                                                                                                          style="color: rgb(0, 0, 0);"><strong><span
                                                                                                                                                                                                                                                          data-custom-class="heading_1">6.
                                                                                                                                                                                                                                                          HOW
                                                                                                                                                                                                                                                          LONG
                                                                                                                                                                                                                                                          DO
                                                                                                                                                                                                                                                          WE
                                                                                                                                                                                                                                                          KEEP
                                                                                                                                                                                                                                                          YOUR
                                                                                                                                                                                                                                                          INFORMATION?</span></strong></span></span></span></span></span>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <br>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                          data-custom-class="body_text"><strong><em>In
                                                                                                                                                                                                                                          Short: </em></strong><em>We
                                                                                                                                                                                                                                  keep
                                                                                                                                                                                                                                  your
                                                                                                                                                                                                                                  information
                                                                                                                                                                                                                                  for
                                                                                                                                                                                                                                  as
                                                                                                                                                                                                                                  long
                                                                                                                                                                                                                                  as
                                                                                                                                                                                                                                  necessary
                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                  fulfill
                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                  purposes
                                                                                                                                                                                                                                  outlined
                                                                                                                                                                                                                                  in
                                                                                                                                                                                                                                  this
                                                                                                                                                                                                                                  privacy
                                                                                                                                                                                                                                  notice
                                                                                                                                                                                                                                  unless
                                                                                                                                                                                                                                  otherwise
                                                                                                                                                                                                                                  required
                                                                                                                                                                                                                                  by
                                                                                                                                                                                                                                  law.</em></span></span></span>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <br>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                          data-custom-class="body_text">We
                                                                                                                                                                                                                          will
                                                                                                                                                                                                                          only
                                                                                                                                                                                                                          keep
                                                                                                                                                                                                                          your
                                                                                                                                                                                                                          personal
                                                                                                                                                                                                                          information
                                                                                                                                                                                                                          for
                                                                                                                                                                                                                          as
                                                                                                                                                                                                                          long
                                                                                                                                                                                                                          as
                                                                                                                                                                                                                          it
                                                                                                                                                                                                                          is
                                                                                                                                                                                                                          necessary
                                                                                                                                                                                                                          for
                                                                                                                                                                                                                          the
                                                                                                                                                                                                                          purposes
                                                                                                                                                                                                                          set
                                                                                                                                                                                                                          out
                                                                                                                                                                                                                          in
                                                                                                                                                                                                                          this
                                                                                                                                                                                                                          privacy
                                                                                                                                                                                                                          notice,
                                                                                                                                                                                                                          unless
                                                                                                                                                                                                                          a
                                                                                                                                                                                                                          longer
                                                                                                                                                                                                                          retention
                                                                                                                                                                                                                          period
                                                                                                                                                                                                                          is
                                                                                                                                                                                                                          required
                                                                                                                                                                                                                          or
                                                                                                                                                                                                                          permitted
                                                                                                                                                                                                                          by
                                                                                                                                                                                                                          law
                                                                                                                                                                                                                          (such
                                                                                                                                                                                                                          as
                                                                                                                                                                                                                          tax,
                                                                                                                                                                                                                          accounting,
                                                                                                                                                                                                                          or
                                                                                                                                                                                                                          other
                                                                                                                                                                                                                          legal
                                                                                                                                                                                                                          requirements).
                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                          No
                                                                                                                                                                                                                          purpose
                                                                                                                                                                                                                          in
                                                                                                                                                                                                                          this
                                                                                                                                                                                                                          notice
                                                                                                                                                                                                                          will
                                                                                                                                                                                                                          require
                                                                                                                                                                                                                          us
                                                                                                                                                                                                                          keeping
                                                                                                                                                                                                                          your
                                                                                                                                                                                                                          personal
                                                                                                                                                                                                                          information
                                                                                                                                                                                                                          for
                                                                                                                                                                                                                          longer
                                                                                                                                                                                                                          than
                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                          </span></span></span>
                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                  class="question">
                                                                                                                                                                                                                                  three
                                                                                                                                                                                                                                  (3)
                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                          months
                                                                                                                                                                                                                          past
                                                                                                                                                                                                                          the
                                                                                                                                                                                                                          termination
                                                                                                                                                                                                                          of
                                                                                                                                                                                                                          the
                                                                                                                                                                                                                          user's
                                                                                                                                                                                                                          account
                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                          class="else-block">
                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                          </span></span></span>.
                                                                                                                                                                                                                  </span></span></span>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <br>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                          data-custom-class="body_text">When
                                                                                                                                                                                                                          we
                                                                                                                                                                                                                          have
                                                                                                                                                                                                                          no
                                                                                                                                                                                                                          ongoing
                                                                                                                                                                                                                          legitimate
                                                                                                                                                                                                                          business
                                                                                                                                                                                                                          need
                                                                                                                                                                                                                          to
                                                                                                                                                                                                                          process
                                                                                                                                                                                                                          your
                                                                                                                                                                                                                          personal
                                                                                                                                                                                                                          information,
                                                                                                                                                                                                                          we
                                                                                                                                                                                                                          will
                                                                                                                                                                                                                          either
                                                                                                                                                                                                                          delete
                                                                                                                                                                                                                          or
                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                          anonymize
                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                          such
                                                                                                                                                                                                                          information,
                                                                                                                                                                                                                          or,
                                                                                                                                                                                                                          if
                                                                                                                                                                                                                          this
                                                                                                                                                                                                                          is
                                                                                                                                                                                                                          not
                                                                                                                                                                                                                          possible
                                                                                                                                                                                                                          (for
                                                                                                                                                                                                                          example,
                                                                                                                                                                                                                          because
                                                                                                                                                                                                                          your
                                                                                                                                                                                                                          personal
                                                                                                                                                                                                                          information
                                                                                                                                                                                                                          has
                                                                                                                                                                                                                          been
                                                                                                                                                                                                                          stored
                                                                                                                                                                                                                          in
                                                                                                                                                                                                                          backup
                                                                                                                                                                                                                          archives),
                                                                                                                                                                                                                          then
                                                                                                                                                                                                                          we
                                                                                                                                                                                                                          will
                                                                                                                                                                                                                          securely
                                                                                                                                                                                                                          store
                                                                                                                                                                                                                          your
                                                                                                                                                                                                                          personal
                                                                                                                                                                                                                          information
                                                                                                                                                                                                                          and
                                                                                                                                                                                                                          isolate
                                                                                                                                                                                                                          it
                                                                                                                                                                                                                          from
                                                                                                                                                                                                                          any
                                                                                                                                                                                                                          further
                                                                                                                                                                                                                          processing
                                                                                                                                                                                                                          until
                                                                                                                                                                                                                          deletion
                                                                                                                                                                                                                          is
                                                                                                                                                                                                                          possible.<span
                                                                                                                                                                                                                                  style="color: rgb(89, 89, 89);">
                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                          </span></span></span></span>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <br>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div id="infosafe"
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          style="color: rgb(127, 127, 127);"><span
                                                                                                                                                                                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                          id="control"
                                                                                                                                                                                                                                          style="color: rgb(0, 0, 0);"><strong><span
                                                                                                                                                                                                                                                          data-custom-class="heading_1">7.
                                                                                                                                                                                                                                                          HOW
                                                                                                                                                                                                                                                          DO
                                                                                                                                                                                                                                                          WE
                                                                                                                                                                                                                                                          KEEP
                                                                                                                                                                                                                                                          YOUR
                                                                                                                                                                                                                                                          INFORMATION
                                                                                                                                                                                                                                                          SAFE?</span></strong></span></span></span></span></span>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <br>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                          data-custom-class="body_text"><strong><em>In
                                                                                                                                                                                                                                          Short: </em></strong><em>We
                                                                                                                                                                                                                                  aim
                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                  protect
                                                                                                                                                                                                                                  your
                                                                                                                                                                                                                                  personal
                                                                                                                                                                                                                                  information
                                                                                                                                                                                                                                  through
                                                                                                                                                                                                                                  a
                                                                                                                                                                                                                                  system
                                                                                                                                                                                                                                  of
                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                  organizational
                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                  and
                                                                                                                                                                                                                                  technical
                                                                                                                                                                                                                                  security
                                                                                                                                                                                                                                  measures.</em></span></span></span>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <br>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                          data-custom-class="body_text">We
                                                                                                                                                                                                                          have
                                                                                                                                                                                                                          implemented
                                                                                                                                                                                                                          appropriate
                                                                                                                                                                                                                          and
                                                                                                                                                                                                                          reasonable
                                                                                                                                                                                                                          technical
                                                                                                                                                                                                                          and
                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                          organizational
                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                          security
                                                                                                                                                                                                                          measures
                                                                                                                                                                                                                          designed
                                                                                                                                                                                                                          to
                                                                                                                                                                                                                          protect
                                                                                                                                                                                                                          the
                                                                                                                                                                                                                          security
                                                                                                                                                                                                                          of
                                                                                                                                                                                                                          any
                                                                                                                                                                                                                          personal
                                                                                                                                                                                                                          information
                                                                                                                                                                                                                          we
                                                                                                                                                                                                                          process.
                                                                                                                                                                                                                          However,
                                                                                                                                                                                                                          despite
                                                                                                                                                                                                                          our
                                                                                                                                                                                                                          safeguards
                                                                                                                                                                                                                          and
                                                                                                                                                                                                                          efforts
                                                                                                                                                                                                                          to
                                                                                                                                                                                                                          secure
                                                                                                                                                                                                                          your
                                                                                                                                                                                                                          information,
                                                                                                                                                                                                                          no
                                                                                                                                                                                                                          electronic
                                                                                                                                                                                                                          transmission
                                                                                                                                                                                                                          over
                                                                                                                                                                                                                          the
                                                                                                                                                                                                                          Internet
                                                                                                                                                                                                                          or
                                                                                                                                                                                                                          information
                                                                                                                                                                                                                          storage
                                                                                                                                                                                                                          technology
                                                                                                                                                                                                                          can
                                                                                                                                                                                                                          be
                                                                                                                                                                                                                          guaranteed
                                                                                                                                                                                                                          to
                                                                                                                                                                                                                          be
                                                                                                                                                                                                                          100%
                                                                                                                                                                                                                          secure,
                                                                                                                                                                                                                          so
                                                                                                                                                                                                                          we
                                                                                                                                                                                                                          cannot
                                                                                                                                                                                                                          promise
                                                                                                                                                                                                                          or
                                                                                                                                                                                                                          guarantee
                                                                                                                                                                                                                          that
                                                                                                                                                                                                                          hackers,
                                                                                                                                                                                                                          cybercriminals,
                                                                                                                                                                                                                          or
                                                                                                                                                                                                                          other
                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                          login
                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                          third
                                                                                                                                                                                                                          parties
                                                                                                                                                                                                                          will
                                                                                                                                                                                                                          not
                                                                                                                                                                                                                          be
                                                                                                                                                                                                                          able
                                                                                                                                                                                                                          to
                                                                                                                                                                                                                          defeat
                                                                                                                                                                                                                          our
                                                                                                                                                                                                                          security
                                                                                                                                                                                                                          and
                                                                                                                                                                                                                          improperly
                                                                                                                                                                                                                          collect,
                                                                                                                                                                                                                          access,
                                                                                                                                                                                                                          steal,
                                                                                                                                                                                                                          or
                                                                                                                                                                                                                          modify
                                                                                                                                                                                                                          your
                                                                                                                                                                                                                          information.
                                                                                                                                                                                                                          Although
                                                                                                                                                                                                                          we
                                                                                                                                                                                                                          will
                                                                                                                                                                                                                          do
                                                                                                                                                                                                                          our
                                                                                                                                                                                                                          best
                                                                                                                                                                                                                          to
                                                                                                                                                                                                                          protect
                                                                                                                                                                                                                          your
                                                                                                                                                                                                                          personal
                                                                                                                                                                                                                          information,
                                                                                                                                                                                                                          transmission
                                                                                                                                                                                                                          of
                                                                                                                                                                                                                          personal
                                                                                                                                                                                                                          information
                                                                                                                                                                                                                          to
                                                                                                                                                                                                                          and
                                                                                                                                                                                                                          from
                                                                                                                                                                                                                          our
                                                                                                                                                                                                                          Services
                                                                                                                                                                                                                          is
                                                                                                                                                                                                                          at
                                                                                                                                                                                                                          your
                                                                                                                                                                                                                          own
                                                                                                                                                                                                                          risk.
                                                                                                                                                                                                                          You
                                                                                                                                                                                                                          should
                                                                                                                                                                                                                          only
                                                                                                                                                                                                                          access
                                                                                                                                                                                                                          the
                                                                                                                                                                                                                          Services
                                                                                                                                                                                                                          within
                                                                                                                                                                                                                          a
                                                                                                                                                                                                                          secure
                                                                                                                                                                                                                          environment.<span
                                                                                                                                                                                                                                  style="color: rgb(89, 89, 89);">
                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                          </span><span
                                                                                                                                                                                                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                  </span></span></span></span></span>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <br>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div id="infominors"
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          style="color: rgb(127, 127, 127);"><span
                                                                                                                                                                                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                          id="control"
                                                                                                                                                                                                                                          style="color: rgb(0, 0, 0);"><strong><span
                                                                                                                                                                                                                                                          data-custom-class="heading_1">8.
                                                                                                                                                                                                                                                          DO
                                                                                                                                                                                                                                                          WE
                                                                                                                                                                                                                                                          COLLECT
                                                                                                                                                                                                                                                          INFORMATION
                                                                                                                                                                                                                                                          FROM
                                                                                                                                                                                                                                                          MINORS?</span></strong></span></span></span></span></span>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <br>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                          data-custom-class="body_text"><strong><em>In
                                                                                                                                                                                                                                          Short:</em></strong><em> We
                                                                                                                                                                                                                                  do
                                                                                                                                                                                                                                  not
                                                                                                                                                                                                                                  knowingly
                                                                                                                                                                                                                                  collect
                                                                                                                                                                                                                                  data
                                                                                                                                                                                                                                  from
                                                                                                                                                                                                                                  or
                                                                                                                                                                                                                                  market
                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                  children
                                                                                                                                                                                                                                  under
                                                                                                                                                                                                                                  18
                                                                                                                                                                                                                                  years
                                                                                                                                                                                                                                  of
                                                                                                                                                                                                                                  age
                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                          class="else-block">
                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                  .</em>
                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                  </span></span></span>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <br>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                          data-custom-class="body_text">We
                                                                                                                                                                                                                          do
                                                                                                                                                                                                                          not
                                                                                                                                                                                                                          knowingly
                                                                                                                                                                                                                          solicit
                                                                                                                                                                                                                          data
                                                                                                                                                                                                                          from
                                                                                                                                                                                                                          or
                                                                                                                                                                                                                          market
                                                                                                                                                                                                                          to
                                                                                                                                                                                                                          children
                                                                                                                                                                                                                          under
                                                                                                                                                                                                                          18
                                                                                                                                                                                                                          years
                                                                                                                                                                                                                          of
                                                                                                                                                                                                                          age.
                                                                                                                                                                                                                          By
                                                                                                                                                                                                                          using
                                                                                                                                                                                                                          the
                                                                                                                                                                                                                          Services,
                                                                                                                                                                                                                          you
                                                                                                                                                                                                                          represent
                                                                                                                                                                                                                          that
                                                                                                                                                                                                                          you
                                                                                                                                                                                                                          are
                                                                                                                                                                                                                          at
                                                                                                                                                                                                                          least
                                                                                                                                                                                                                          18
                                                                                                                                                                                                                          or
                                                                                                                                                                                                                          that
                                                                                                                                                                                                                          you
                                                                                                                                                                                                                          are
                                                                                                                                                                                                                          the
                                                                                                                                                                                                                          parent
                                                                                                                                                                                                                          or
                                                                                                                                                                                                                          guardian
                                                                                                                                                                                                                          of
                                                                                                                                                                                                                          such
                                                                                                                                                                                                                          a
                                                                                                                                                                                                                          minor
                                                                                                                                                                                                                          and
                                                                                                                                                                                                                          consent
                                                                                                                                                                                                                          to
                                                                                                                                                                                                                          such
                                                                                                                                                                                                                          minor
                                                                                                                                                                                                                          dependent’s
                                                                                                                                                                                                                          use
                                                                                                                                                                                                                          of
                                                                                                                                                                                                                          the
                                                                                                                                                                                                                          Services.
                                                                                                                                                                                                                          If
                                                                                                                                                                                                                          we
                                                                                                                                                                                                                          learn
                                                                                                                                                                                                                          that
                                                                                                                                                                                                                          personal
                                                                                                                                                                                                                          information
                                                                                                                                                                                                                          from
                                                                                                                                                                                                                          users
                                                                                                                                                                                                                          less
                                                                                                                                                                                                                          than
                                                                                                                                                                                                                          18
                                                                                                                                                                                                                          years
                                                                                                                                                                                                                          of
                                                                                                                                                                                                                          age
                                                                                                                                                                                                                          has
                                                                                                                                                                                                                          been
                                                                                                                                                                                                                          collected,
                                                                                                                                                                                                                          we
                                                                                                                                                                                                                          will
                                                                                                                                                                                                                          deactivate
                                                                                                                                                                                                                          the
                                                                                                                                                                                                                          account
                                                                                                                                                                                                                          and
                                                                                                                                                                                                                          take
                                                                                                                                                                                                                          reasonable
                                                                                                                                                                                                                          measures
                                                                                                                                                                                                                          to
                                                                                                                                                                                                                          promptly
                                                                                                                                                                                                                          delete
                                                                                                                                                                                                                          such
                                                                                                                                                                                                                          data
                                                                                                                                                                                                                          from
                                                                                                                                                                                                                          our
                                                                                                                                                                                                                          records.
                                                                                                                                                                                                                          If
                                                                                                                                                                                                                          you
                                                                                                                                                                                                                          become
                                                                                                                                                                                                                          aware
                                                                                                                                                                                                                          of
                                                                                                                                                                                                                          any
                                                                                                                                                                                                                          data
                                                                                                                                                                                                                          we
                                                                                                                                                                                                                          may
                                                                                                                                                                                                                          have
                                                                                                                                                                                                                          collected
                                                                                                                                                                                                                          from
                                                                                                                                                                                                                          children
                                                                                                                                                                                                                          under
                                                                                                                                                                                                                          age
                                                                                                                                                                                                                          18,
                                                                                                                                                                                                                          please
                                                                                                                                                                                                                          contact
                                                                                                                                                                                                                          us
                                                                                                                                                                                                                          at
                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                  class="question">
                                                                                                                                                                                                                                                  hello@bookboos.com
                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                  class="else-block">
                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                  </span></span>.</span><span
                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                  class="else-block">
                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                  </span></span></span></span></span></span>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <br>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div id="privacyrights"
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          style="color: rgb(127, 127, 127);"><span
                                                                                                                                                                                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                          id="control"
                                                                                                                                                                                                                                          style="color: rgb(0, 0, 0);"><strong><span
                                                                                                                                                                                                                                                          data-custom-class="heading_1">9.
                                                                                                                                                                                                                                                          WHAT
                                                                                                                                                                                                                                                          ARE
                                                                                                                                                                                                                                                          YOUR
                                                                                                                                                                                                                                                          PRIVACY
                                                                                                                                                                                                                                                          RIGHTS?</span></strong></span></span></span></span></span>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <br>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                          data-custom-class="body_text"><strong><em>In
                                                                                                                                                                                                                                          Short:</em></strong><em> <span
                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                                          data-custom-class="body_text"><em>
                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                          </em></span></span> </span>You
                                                                                                                                                                                                                                  may
                                                                                                                                                                                                                                  review,
                                                                                                                                                                                                                                  change,
                                                                                                                                                                                                                                  or
                                                                                                                                                                                                                                  terminate
                                                                                                                                                                                                                                  your
                                                                                                                                                                                                                                  account
                                                                                                                                                                                                                                  at
                                                                                                                                                                                                                                  any
                                                                                                                                                                                                                                  time.</em><span
                                                                                                                                                                                                                                  style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                          style="font-size: 15px;">
                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                  </span></span></span>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <br>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div id="withdrawconsent"
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                          data-custom-class="body_text"><strong><u>Withdrawing
                                                                                                                                                                                                                                          your
                                                                                                                                                                                                                                          consent:</u></strong>
                                                                                                                                                                                                                          If
                                                                                                                                                                                                                          we
                                                                                                                                                                                                                          are
                                                                                                                                                                                                                          relying
                                                                                                                                                                                                                          on
                                                                                                                                                                                                                          your
                                                                                                                                                                                                                          consent
                                                                                                                                                                                                                          to
                                                                                                                                                                                                                          process
                                                                                                                                                                                                                          your
                                                                                                                                                                                                                          personal
                                                                                                                                                                                                                          information,
                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                          which
                                                                                                                                                                                                                          may
                                                                                                                                                                                                                          be
                                                                                                                                                                                                                          express
                                                                                                                                                                                                                          and/or
                                                                                                                                                                                                                          implied
                                                                                                                                                                                                                          consent
                                                                                                                                                                                                                          depending
                                                                                                                                                                                                                          on
                                                                                                                                                                                                                          the
                                                                                                                                                                                                                          applicable
                                                                                                                                                                                                                          law,
                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                          you
                                                                                                                                                                                                                          have
                                                                                                                                                                                                                          the
                                                                                                                                                                                                                          right
                                                                                                                                                                                                                          to
                                                                                                                                                                                                                          withdraw
                                                                                                                                                                                                                          your
                                                                                                                                                                                                                          consent
                                                                                                                                                                                                                          at
                                                                                                                                                                                                                          any
                                                                                                                                                                                                                          time.
                                                                                                                                                                                                                          You
                                                                                                                                                                                                                          can
                                                                                                                                                                                                                          withdraw
                                                                                                                                                                                                                          your
                                                                                                                                                                                                                          consent
                                                                                                                                                                                                                          at
                                                                                                                                                                                                                          any
                                                                                                                                                                                                                          time
                                                                                                                                                                                                                          by
                                                                                                                                                                                                                          contacting
                                                                                                                                                                                                                          us
                                                                                                                                                                                                                          by
                                                                                                                                                                                                                          using
                                                                                                                                                                                                                          the
                                                                                                                                                                                                                          contact
                                                                                                                                                                                                                          details
                                                                                                                                                                                                                          provided
                                                                                                                                                                                                                          in
                                                                                                                                                                                                                          the
                                                                                                                                                                                                                          section
                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                          "
                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                          </span></span></span><a
                                                                                                                                                                                                          data-custom-class="link"
                                                                                                                                                                                                          href="#contact"><span
                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                  data-custom-class="body_text">HOW
                                                                                                                                                                                                                                  CAN
                                                                                                                                                                                                                                  YOU
                                                                                                                                                                                                                                  CONTACT
                                                                                                                                                                                                                                  US
                                                                                                                                                                                                                                  ABOUT
                                                                                                                                                                                                                                  THIS
                                                                                                                                                                                                                                  NOTICE?</span></span></span></a><span
                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                          "
                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                          below
                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                          .
                                                                                                                                                                                                                  </span></span></span>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <br>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                                                                  data-custom-class="body_text">However,
                                                                                                                                                                                                                  please
                                                                                                                                                                                                                  note
                                                                                                                                                                                                                  that
                                                                                                                                                                                                                  this
                                                                                                                                                                                                                  will
                                                                                                                                                                                                                  not
                                                                                                                                                                                                                  affect
                                                                                                                                                                                                                  the
                                                                                                                                                                                                                  lawfulness
                                                                                                                                                                                                                  of
                                                                                                                                                                                                                  the
                                                                                                                                                                                                                  processing
                                                                                                                                                                                                                  before
                                                                                                                                                                                                                  its
                                                                                                                                                                                                                  withdrawal
                                                                                                                                                                                                                  nor,
                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                  when
                                                                                                                                                                                                                  applicable
                                                                                                                                                                                                                  law
                                                                                                                                                                                                                  allows,
                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                  will
                                                                                                                                                                                                                  it
                                                                                                                                                                                                                  affect
                                                                                                                                                                                                                  the
                                                                                                                                                                                                                  processing
                                                                                                                                                                                                                  of
                                                                                                                                                                                                                  your
                                                                                                                                                                                                                  personal
                                                                                                                                                                                                                  information
                                                                                                                                                                                                                  conducted
                                                                                                                                                                                                                  in
                                                                                                                                                                                                                  reliance
                                                                                                                                                                                                                  on
                                                                                                                                                                                                                  lawful
                                                                                                                                                                                                                  processing
                                                                                                                                                                                                                  grounds
                                                                                                                                                                                                                  other
                                                                                                                                                                                                                  than
                                                                                                                                                                                                                  consent.
                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                  </span></span>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <br>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                                                                  data-custom-class="body_text"><strong><u>Opting
                                                                                                                                                                                                                                  out
                                                                                                                                                                                                                                  of
                                                                                                                                                                                                                                  marketing
                                                                                                                                                                                                                                  and
                                                                                                                                                                                                                                  promotional
                                                                                                                                                                                                                                  communications:</u></strong><strong><u> </u></strong>You
                                                                                                                                                                                                                  can
                                                                                                                                                                                                                  unsubscribe
                                                                                                                                                                                                                  from
                                                                                                                                                                                                                  our
                                                                                                                                                                                                                  marketing
                                                                                                                                                                                                                  and
                                                                                                                                                                                                                  promotional
                                                                                                                                                                                                                  communications
                                                                                                                                                                                                                  at
                                                                                                                                                                                                                  any
                                                                                                                                                                                                                  time
                                                                                                                                                                                                                  by
                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                  clicking
                                                                                                                                                                                                                  on
                                                                                                                                                                                                                  the
                                                                                                                                                                                                                  unsubscribe
                                                                                                                                                                                                                  link
                                                                                                                                                                                                                  in
                                                                                                                                                                                                                  the
                                                                                                                                                                                                                  emails
                                                                                                                                                                                                                  that
                                                                                                                                                                                                                  we
                                                                                                                                                                                                                  send,
                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                  or
                                                                                                                                                                                                                  by
                                                                                                                                                                                                                  contacting
                                                                                                                                                                                                                  us
                                                                                                                                                                                                                  using
                                                                                                                                                                                                                  the
                                                                                                                                                                                                                  details
                                                                                                                                                                                                                  provided
                                                                                                                                                                                                                  in
                                                                                                                                                                                                                  the
                                                                                                                                                                                                                  section
                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                  "
                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                          </span></span><a
                                                                                                                                                                                                          data-custom-class="link"
                                                                                                                                                                                                          href="#contact"><span
                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                          data-custom-class="body_text">HOW
                                                                                                                                                                                                                          CAN
                                                                                                                                                                                                                          YOU
                                                                                                                                                                                                                          CONTACT
                                                                                                                                                                                                                          US
                                                                                                                                                                                                                          ABOUT
                                                                                                                                                                                                                          THIS
                                                                                                                                                                                                                          NOTICE?</span></span></a><span
                                                                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                  "
                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                  below.
                                                                                                                                                                                                                  You
                                                                                                                                                                                                                  will
                                                                                                                                                                                                                  then
                                                                                                                                                                                                                  be
                                                                                                                                                                                                                  removed
                                                                                                                                                                                                                  from
                                                                                                                                                                                                                  the
                                                                                                                                                                                                                  marketing
                                                                                                                                                                                                                  lists.
                                                                                                                                                                                                                  However,
                                                                                                                                                                                                                  we
                                                                                                                                                                                                                  may
                                                                                                                                                                                                                  still
                                                                                                                                                                                                                  communicate
                                                                                                                                                                                                                  with
                                                                                                                                                                                                                  you
                                                                                                                                                                                                                  —
                                                                                                                                                                                                                  for
                                                                                                                                                                                                                  example,
                                                                                                                                                                                                                  to
                                                                                                                                                                                                                  send
                                                                                                                                                                                                                  you
                                                                                                                                                                                                                  service-related
                                                                                                                                                                                                                  messages
                                                                                                                                                                                                                  that
                                                                                                                                                                                                                  are
                                                                                                                                                                                                                  necessary
                                                                                                                                                                                                                  for
                                                                                                                                                                                                                  the
                                                                                                                                                                                                                  administration
                                                                                                                                                                                                                  and
                                                                                                                                                                                                                  use
                                                                                                                                                                                                                  of
                                                                                                                                                                                                                  your
                                                                                                                                                                                                                  account,
                                                                                                                                                                                                                  to
                                                                                                                                                                                                                  respond
                                                                                                                                                                                                                  to
                                                                                                                                                                                                                  service
                                                                                                                                                                                                                  requests,
                                                                                                                                                                                                                  or
                                                                                                                                                                                                                  for
                                                                                                                                                                                                                  other
                                                                                                                                                                                                                  non-marketing
                                                                                                                                                                                                                  purposes.
                                                                                                                                                                                                          </span><span
                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                          </span></span>
                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                          <span
                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                          data-custom-class="body_text"></span></span>
                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <br>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                                                                  data-custom-class="heading_2"><strong>Account
                                                                                                                                                                                                                          Information</strong></span></span>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <br>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                                                                  style="font-size: 15px;">If
                                                                                                                                                                                                                  you
                                                                                                                                                                                                                  would
                                                                                                                                                                                                                  at
                                                                                                                                                                                                                  any
                                                                                                                                                                                                                  time
                                                                                                                                                                                                                  like
                                                                                                                                                                                                                  to
                                                                                                                                                                                                                  review
                                                                                                                                                                                                                  or
                                                                                                                                                                                                                  change
                                                                                                                                                                                                                  the
                                                                                                                                                                                                                  information
                                                                                                                                                                                                                  in
                                                                                                                                                                                                                  your
                                                                                                                                                                                                                  account
                                                                                                                                                                                                                  or
                                                                                                                                                                                                                  terminate
                                                                                                                                                                                                                  your
                                                                                                                                                                                                                  account,
                                                                                                                                                                                                                  you
                                                                                                                                                                                                                  can:
                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                          class="forloop-component">
                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                  </span></span>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <ul>
                                                                                                                                                                                                  <li
                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                          <span
                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                          style="font-size: 15px;">
                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                  class="question">
                                                                                                                                                                                                                                  Contact
                                                                                                                                                                                                                                  us
                                                                                                                                                                                                                                  using
                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                  contact
                                                                                                                                                                                                                                  information
                                                                                                                                                                                                                                  provided.
                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                  </span></span>
                                                                                                                                                                                                  </li>
                                                                                                                                                                                          </ul>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                                                                  style="font-size: 15px;">
                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                          class="forloop-component">
                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                          </span></span>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                                                                  data-custom-class="body_text">Upon
                                                                                                                                                                                                                  your
                                                                                                                                                                                                                  request
                                                                                                                                                                                                                  to
                                                                                                                                                                                                                  terminate
                                                                                                                                                                                                                  your
                                                                                                                                                                                                                  account,
                                                                                                                                                                                                                  we
                                                                                                                                                                                                                  will
                                                                                                                                                                                                                  deactivate
                                                                                                                                                                                                                  or
                                                                                                                                                                                                                  delete
                                                                                                                                                                                                                  your
                                                                                                                                                                                                                  account
                                                                                                                                                                                                                  and
                                                                                                                                                                                                                  information
                                                                                                                                                                                                                  from
                                                                                                                                                                                                                  our
                                                                                                                                                                                                                  active
                                                                                                                                                                                                                  databases.
                                                                                                                                                                                                                  However,
                                                                                                                                                                                                                  we
                                                                                                                                                                                                                  may
                                                                                                                                                                                                                  retain
                                                                                                                                                                                                                  some
                                                                                                                                                                                                                  information
                                                                                                                                                                                                                  in
                                                                                                                                                                                                                  our
                                                                                                                                                                                                                  files
                                                                                                                                                                                                                  to
                                                                                                                                                                                                                  prevent
                                                                                                                                                                                                                  fraud,
                                                                                                                                                                                                                  troubleshoot
                                                                                                                                                                                                                  problems,
                                                                                                                                                                                                                  assist
                                                                                                                                                                                                                  with
                                                                                                                                                                                                                  any
                                                                                                                                                                                                                  investigations,
                                                                                                                                                                                                                  enforce
                                                                                                                                                                                                                  our
                                                                                                                                                                                                                  legal
                                                                                                                                                                                                                  terms
                                                                                                                                                                                                                  and/or
                                                                                                                                                                                                                  comply
                                                                                                                                                                                                                  with
                                                                                                                                                                                                                  applicable
                                                                                                                                                                                                                  legal
                                                                                                                                                                                                                  requirements.</span></span>
                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                          <span
                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                          data-custom-class="body_text"></span></span> 
                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                  style="color: rgb(89, 89, 89);">
                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                          </span></span></span></span></span></span></span></span></span></span>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <br>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                          data-custom-class="body_text"><strong><u>Cookies
                                                                                                                                                                                                                                          and
                                                                                                                                                                                                                                          similar
                                                                                                                                                                                                                                          technologies:</u></strong>
                                                                                                                                                                                                                          Most
                                                                                                                                                                                                                          Web
                                                                                                                                                                                                                          browsers
                                                                                                                                                                                                                          are
                                                                                                                                                                                                                          set
                                                                                                                                                                                                                          to
                                                                                                                                                                                                                          accept
                                                                                                                                                                                                                          cookies
                                                                                                                                                                                                                          by
                                                                                                                                                                                                                          default.
                                                                                                                                                                                                                          If
                                                                                                                                                                                                                          you
                                                                                                                                                                                                                          prefer,
                                                                                                                                                                                                                          you
                                                                                                                                                                                                                          can
                                                                                                                                                                                                                          usually
                                                                                                                                                                                                                          choose
                                                                                                                                                                                                                          to
                                                                                                                                                                                                                          set
                                                                                                                                                                                                                          your
                                                                                                                                                                                                                          browser
                                                                                                                                                                                                                          to
                                                                                                                                                                                                                          remove
                                                                                                                                                                                                                          cookies
                                                                                                                                                                                                                          and
                                                                                                                                                                                                                          to
                                                                                                                                                                                                                          reject
                                                                                                                                                                                                                          cookies.
                                                                                                                                                                                                                          If
                                                                                                                                                                                                                          you
                                                                                                                                                                                                                          choose
                                                                                                                                                                                                                          to
                                                                                                                                                                                                                          remove
                                                                                                                                                                                                                          cookies
                                                                                                                                                                                                                          or
                                                                                                                                                                                                                          reject
                                                                                                                                                                                                                          cookies,
                                                                                                                                                                                                                          this
                                                                                                                                                                                                                          could
                                                                                                                                                                                                                          affect
                                                                                                                                                                                                                          certain
                                                                                                                                                                                                                          features
                                                                                                                                                                                                                          or
                                                                                                                                                                                                                          services
                                                                                                                                                                                                                          of
                                                                                                                                                                                                                          our
                                                                                                                                                                                                                          Services.
                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                                                                                                  style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89);">
                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                  </span></span></span></span></span></span>
                                                                                                                                                                                                                                                  </span></span></span></span></span></span>
                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                          <span
                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                          data-custom-class="body_text"></span></span>
                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <br>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div id="DNT"
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          style="color: rgb(127, 127, 127);"><span
                                                                                                                                                                                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                          id="control"
                                                                                                                                                                                                                                          style="color: rgb(0, 0, 0);"><strong><span
                                                                                                                                                                                                                                                          data-custom-class="heading_1">10.
                                                                                                                                                                                                                                                          CONTROLS
                                                                                                                                                                                                                                                          FOR
                                                                                                                                                                                                                                                          DO-NOT-TRACK
                                                                                                                                                                                                                                                          FEATURES</span></strong></span></span></span></span></span>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <br>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                          data-custom-class="body_text">Most
                                                                                                                                                                                                                          web
                                                                                                                                                                                                                          browsers
                                                                                                                                                                                                                          and
                                                                                                                                                                                                                          some
                                                                                                                                                                                                                          mobile
                                                                                                                                                                                                                          operating
                                                                                                                                                                                                                          systems
                                                                                                                                                                                                                          and
                                                                                                                                                                                                                          mobile
                                                                                                                                                                                                                          applications
                                                                                                                                                                                                                          include
                                                                                                                                                                                                                          a
                                                                                                                                                                                                                          Do-Not-Track
                                                                                                                                                                                                                          (
                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                          "DNT"
                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                          )
                                                                                                                                                                                                                          feature
                                                                                                                                                                                                                          or
                                                                                                                                                                                                                          setting
                                                                                                                                                                                                                          you
                                                                                                                                                                                                                          can
                                                                                                                                                                                                                          activate
                                                                                                                                                                                                                          to
                                                                                                                                                                                                                          signal
                                                                                                                                                                                                                          your
                                                                                                                                                                                                                          privacy
                                                                                                                                                                                                                          preference
                                                                                                                                                                                                                          not
                                                                                                                                                                                                                          to
                                                                                                                                                                                                                          have
                                                                                                                                                                                                                          data
                                                                                                                                                                                                                          about
                                                                                                                                                                                                                          your
                                                                                                                                                                                                                          online
                                                                                                                                                                                                                          browsing
                                                                                                                                                                                                                          activities
                                                                                                                                                                                                                          monitored
                                                                                                                                                                                                                          and
                                                                                                                                                                                                                          collected.
                                                                                                                                                                                                                          At
                                                                                                                                                                                                                          this
                                                                                                                                                                                                                          stage
                                                                                                                                                                                                                          no
                                                                                                                                                                                                                          uniform
                                                                                                                                                                                                                          technology
                                                                                                                                                                                                                          standard
                                                                                                                                                                                                                          for
                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                          recognizing
                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                          and
                                                                                                                                                                                                                          implementing
                                                                                                                                                                                                                          DNT
                                                                                                                                                                                                                          signals
                                                                                                                                                                                                                          has
                                                                                                                                                                                                                          been
                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                          finalized
                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                          .
                                                                                                                                                                                                                          As
                                                                                                                                                                                                                          such,
                                                                                                                                                                                                                          we
                                                                                                                                                                                                                          do
                                                                                                                                                                                                                          not
                                                                                                                                                                                                                          currently
                                                                                                                                                                                                                          respond
                                                                                                                                                                                                                          to
                                                                                                                                                                                                                          DNT
                                                                                                                                                                                                                          browser
                                                                                                                                                                                                                          signals
                                                                                                                                                                                                                          or
                                                                                                                                                                                                                          any
                                                                                                                                                                                                                          other
                                                                                                                                                                                                                          mechanism
                                                                                                                                                                                                                          that
                                                                                                                                                                                                                          automatically
                                                                                                                                                                                                                          communicates
                                                                                                                                                                                                                          your
                                                                                                                                                                                                                          choice
                                                                                                                                                                                                                          not
                                                                                                                                                                                                                          to
                                                                                                                                                                                                                          be
                                                                                                                                                                                                                          tracked
                                                                                                                                                                                                                          online.
                                                                                                                                                                                                                          If
                                                                                                                                                                                                                          a
                                                                                                                                                                                                                          standard
                                                                                                                                                                                                                          for
                                                                                                                                                                                                                          online
                                                                                                                                                                                                                          tracking
                                                                                                                                                                                                                          is
                                                                                                                                                                                                                          adopted
                                                                                                                                                                                                                          that
                                                                                                                                                                                                                          we
                                                                                                                                                                                                                          must
                                                                                                                                                                                                                          follow
                                                                                                                                                                                                                          in
                                                                                                                                                                                                                          the
                                                                                                                                                                                                                          future,
                                                                                                                                                                                                                          we
                                                                                                                                                                                                                          will
                                                                                                                                                                                                                          inform
                                                                                                                                                                                                                          you
                                                                                                                                                                                                                          about
                                                                                                                                                                                                                          that
                                                                                                                                                                                                                          practice
                                                                                                                                                                                                                          in
                                                                                                                                                                                                                          a
                                                                                                                                                                                                                          revised
                                                                                                                                                                                                                          version
                                                                                                                                                                                                                          of
                                                                                                                                                                                                                          this
                                                                                                                                                                                                                          privacy
                                                                                                                                                                                                                          notice.
                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                          </span></span></span>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <br>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div id="uslaws"
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          style="color: rgb(127, 127, 127);"><span
                                                                                                                                                                                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                          id="control"
                                                                                                                                                                                                                                          style="color: rgb(0, 0, 0);"><strong><span
                                                                                                                                                                                                                                                          data-custom-class="heading_1">11.
                                                                                                                                                                                                                                                          DO
                                                                                                                                                                                                                                                          UNITED
                                                                                                                                                                                                                                                          STATES
                                                                                                                                                                                                                                                          RESIDENTS
                                                                                                                                                                                                                                                          HAVE
                                                                                                                                                                                                                                                          SPECIFIC
                                                                                                                                                                                                                                                          PRIVACY
                                                                                                                                                                                                                                                          RIGHTS?</span></strong></span></span></span></span></span>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <br>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                          data-custom-class="body_text"><strong><em>In
                                                                                                                                                                                                                                          Short: </em></strong><em>If
                                                                                                                                                                                                                                  you
                                                                                                                                                                                                                                  are
                                                                                                                                                                                                                                  a
                                                                                                                                                                                                                                  resident
                                                                                                                                                                                                                                  of
                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                          class="forloop-component">
                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                          class="forloop-component">
                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                  California
                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                          class="forloop-component">
                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                          class="forloop-component">
                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                          class="forloop-component">
                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                          class="forloop-component">
                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                          class="forloop-component">
                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                  ,
                                                                                                                                                                                                                                  you
                                                                                                                                                                                                                                  are
                                                                                                                                                                                                                                  granted
                                                                                                                                                                                                                                  specific
                                                                                                                                                                                                                                  rights
                                                                                                                                                                                                                                  regarding
                                                                                                                                                                                                                                  access
                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                  your
                                                                                                                                                                                                                                  personal
                                                                                                                                                                                                                                  information.
                                                                                                                                                                                                                          </em></span></span></span>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <br>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                          data-custom-class="body_text"><strong>What
                                                                                                                                                                                                                                  categories
                                                                                                                                                                                                                                  of
                                                                                                                                                                                                                                  personal
                                                                                                                                                                                                                                  information
                                                                                                                                                                                                                                  do
                                                                                                                                                                                                                                  we
                                                                                                                                                                                                                                  collect?</strong></span></span></span>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <br>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                          data-custom-class="body_text">We
                                                                                                                                                                                                                          have
                                                                                                                                                                                                                          collected
                                                                                                                                                                                                                          the
                                                                                                                                                                                                                          following
                                                                                                                                                                                                                          categories
                                                                                                                                                                                                                          of
                                                                                                                                                                                                                          personal
                                                                                                                                                                                                                          information
                                                                                                                                                                                                                          in
                                                                                                                                                                                                                          the
                                                                                                                                                                                                                          past
                                                                                                                                                                                                                          twelve
                                                                                                                                                                                                                          (12)
                                                                                                                                                                                                                          months:</span></span></span>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <br>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <table
                                                                                                                                                                                                  style="width: 100%;">
                                                                                                                                                                                                  <tbody>
                                                                                                                                                                                                          <tr>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                  data-custom-class="body_text"><strong>Category</strong></span></span></span>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="width: 51.4385%; border-top: 1px solid black; border-right: 1px solid black;">
                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                  data-custom-class="body_text"><strong>Examples</strong></span></span></span>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="width: 14.9084%; border-right: 1px solid black; border-top: 1px solid black; text-align: center;">
                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                  data-custom-class="body_text"><strong>Collected</strong></span></span></span>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                          </tr>
                                                                                                                                                                                                          <tr>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                          data-custom-class="body_text">A.
                                                                                                                                                                                                                                                          Identifiers</span></span></span>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="width: 51.4385%; border-top: 1px solid black; border-right: 1px solid black;">
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                          data-custom-class="body_text">Contact
                                                                                                                                                                                                                                                          details,
                                                                                                                                                                                                                                                          such
                                                                                                                                                                                                                                                          as
                                                                                                                                                                                                                                                          real
                                                                                                                                                                                                                                                          name,
                                                                                                                                                                                                                                                          alias,
                                                                                                                                                                                                                                                          postal
                                                                                                                                                                                                                                                          address,
                                                                                                                                                                                                                                                          telephone
                                                                                                                                                                                                                                                          or
                                                                                                                                                                                                                                                          mobile
                                                                                                                                                                                                                                                          contact
                                                                                                                                                                                                                                                          number,
                                                                                                                                                                                                                                                          unique
                                                                                                                                                                                                                                                          personal
                                                                                                                                                                                                                                                          identifier,
                                                                                                                                                                                                                                                          online
                                                                                                                                                                                                                                                          identifier,
                                                                                                                                                                                                                                                          Internet
                                                                                                                                                                                                                                                          Protocol
                                                                                                                                                                                                                                                          address,
                                                                                                                                                                                                                                                          email
                                                                                                                                                                                                                                                          address,
                                                                                                                                                                                                                                                          and
                                                                                                                                                                                                                                                          account
                                                                                                                                                                                                                                                          name</span></span></span>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="width: 14.9084%; text-align: center; vertical-align: middle; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <br>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                          NO
                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                  </span></span></span>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <br>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                          </tr>
                                                                                                                                                                                                  </tbody>
                                                                                                                                                                                          </table>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <table
                                                                                                                                                                                                  style="width: 100%;">
                                                                                                                                                                                                  <tbody>
                                                                                                                                                                                                          <tr>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                          data-custom-class="body_text">B.
                                                                                                                                                                                                                                                          Personal
                                                                                                                                                                                                                                                          information
                                                                                                                                                                                                                                                          as
                                                                                                                                                                                                                                                          defined
                                                                                                                                                                                                                                                          in
                                                                                                                                                                                                                                                          the
                                                                                                                                                                                                                                                          California
                                                                                                                                                                                                                                                          Customer
                                                                                                                                                                                                                                                          Records
                                                                                                                                                                                                                                                          statute</span></span></span>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                          data-custom-class="body_text">Name,
                                                                                                                                                                                                                                                          contact
                                                                                                                                                                                                                                                          information,
                                                                                                                                                                                                                                                          education,
                                                                                                                                                                                                                                                          employment,
                                                                                                                                                                                                                                                          employment
                                                                                                                                                                                                                                                          history,
                                                                                                                                                                                                                                                          and
                                                                                                                                                                                                                                                          financial
                                                                                                                                                                                                                                                          information</span></span></span>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <br>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                  class="forloop-component">
                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                  NO
                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                  </span></span></span>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <br>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                          </tr>
                                                                                                                                                                                                  </tbody>
                                                                                                                                                                                          </table>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <table
                                                                                                                                                                                                  style="width: 100%;">
                                                                                                                                                                                                  <tbody>
                                                                                                                                                                                                          <tr>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                          C
                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                  class="else-block">
                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                          .
                                                                                                                                                                                                                                                          Protected
                                                                                                                                                                                                                                                          classification
                                                                                                                                                                                                                                                          characteristics
                                                                                                                                                                                                                                                          under
                                                                                                                                                                                                                                                          state
                                                                                                                                                                                                                                                          or
                                                                                                                                                                                                                                                          federal
                                                                                                                                                                                                                                                          law
                                                                                                                                                                                                                                                  </span></span></span>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                          data-custom-class="body_text">Gender
                                                                                                                                                                                                                                                          and
                                                                                                                                                                                                                                                          date
                                                                                                                                                                                                                                                          of
                                                                                                                                                                                                                                                          birth</span></span></span>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <br>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                          <div data-custom-class="body_text"
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                          class="forloop-component">
                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                  NO
                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                          </span>
                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <br>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                          </tr>
                                                                                                                                                                                                          <tr>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                          D
                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                  class="else-block">
                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                          .
                                                                                                                                                                                                                                                          Commercial
                                                                                                                                                                                                                                                          information
                                                                                                                                                                                                                                                  </span></span></span>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                          data-custom-class="body_text">Transaction
                                                                                                                                                                                                                                                          information,
                                                                                                                                                                                                                                                          purchase
                                                                                                                                                                                                                                                          history,
                                                                                                                                                                                                                                                          financial
                                                                                                                                                                                                                                                          details,
                                                                                                                                                                                                                                                          and
                                                                                                                                                                                                                                                          payment
                                                                                                                                                                                                                                                          information</span></span></span>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <br>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                          <div data-custom-class="body_text"
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                          class="forloop-component">
                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                  NO
                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                          </span>
                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <br>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                          </tr>
                                                                                                                                                                                                          <tr>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                          E
                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                  class="else-block">
                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                          .
                                                                                                                                                                                                                                                          Biometric
                                                                                                                                                                                                                                                          information
                                                                                                                                                                                                                                                  </span></span></span>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                          data-custom-class="body_text">Fingerprints
                                                                                                                                                                                                                                                          and
                                                                                                                                                                                                                                                          voiceprints</span></span></span>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <br>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                          <div data-custom-class="body_text"
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                          class="forloop-component">
                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                  NO
                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                          </span>
                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <br>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                          </tr>
                                                                                                                                                                                                          <tr>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                          F
                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                  class="else-block">
                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                          .
                                                                                                                                                                                                                                                          Internet
                                                                                                                                                                                                                                                          or
                                                                                                                                                                                                                                                          other
                                                                                                                                                                                                                                                          similar
                                                                                                                                                                                                                                                          network
                                                                                                                                                                                                                                                          activity
                                                                                                                                                                                                                                                  </span></span></span>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                          data-custom-class="body_text">Browsing
                                                                                                                                                                                                                                                          history,
                                                                                                                                                                                                                                                          search
                                                                                                                                                                                                                                                          history,
                                                                                                                                                                                                                                                          online
                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                          behavior
                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                          ,
                                                                                                                                                                                                                                                          interest
                                                                                                                                                                                                                                                          data,
                                                                                                                                                                                                                                                          and
                                                                                                                                                                                                                                                          interactions
                                                                                                                                                                                                                                                          with
                                                                                                                                                                                                                                                          our
                                                                                                                                                                                                                                                          and
                                                                                                                                                                                                                                                          other
                                                                                                                                                                                                                                                          websites,
                                                                                                                                                                                                                                                          applications,
                                                                                                                                                                                                                                                          systems,
                                                                                                                                                                                                                                                          and
                                                                                                                                                                                                                                                          advertisements</span></span></span>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <br>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                          <div data-custom-class="body_text"
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                          class="forloop-component">
                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                  NO
                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                          </span>
                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <br>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                          </tr>
                                                                                                                                                                                                          <tr>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                          G
                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                  class="else-block">
                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                          .
                                                                                                                                                                                                                                                          Geolocation
                                                                                                                                                                                                                                                          data
                                                                                                                                                                                                                                                  </span></span></span>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                          data-custom-class="body_text">Device
                                                                                                                                                                                                                                                          location</span></span></span>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <br>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                          <div data-custom-class="body_text"
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                          class="forloop-component">
                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                  NO
                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                          </span>
                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <br>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                          </tr>
                                                                                                                                                                                                          <tr>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                          H
                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                  class="else-block">
                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                          .
                                                                                                                                                                                                                                                          Audio,
                                                                                                                                                                                                                                                          electronic,
                                                                                                                                                                                                                                                          visual,
                                                                                                                                                                                                                                                          thermal,
                                                                                                                                                                                                                                                          olfactory,
                                                                                                                                                                                                                                                          or
                                                                                                                                                                                                                                                          similar
                                                                                                                                                                                                                                                          information
                                                                                                                                                                                                                                                  </span></span></span>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                          data-custom-class="body_text">Images
                                                                                                                                                                                                                                                          and
                                                                                                                                                                                                                                                          audio,
                                                                                                                                                                                                                                                          video
                                                                                                                                                                                                                                                          or
                                                                                                                                                                                                                                                          call
                                                                                                                                                                                                                                                          recordings
                                                                                                                                                                                                                                                          created
                                                                                                                                                                                                                                                          in
                                                                                                                                                                                                                                                          connection
                                                                                                                                                                                                                                                          with
                                                                                                                                                                                                                                                          our
                                                                                                                                                                                                                                                          business
                                                                                                                                                                                                                                                          activities</span></span></span>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <br>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                          <div data-custom-class="body_text"
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                          class="forloop-component">
                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                  NO
                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                          </span>
                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <br>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                          </tr>
                                                                                                                                                                                                          <tr>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                          I
                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                  class="else-block">
                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                          .
                                                                                                                                                                                                                                                          Professional
                                                                                                                                                                                                                                                          or
                                                                                                                                                                                                                                                          employment-related
                                                                                                                                                                                                                                                          information
                                                                                                                                                                                                                                                  </span></span></span>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                          data-custom-class="body_text">Business
                                                                                                                                                                                                                                                          contact
                                                                                                                                                                                                                                                          details
                                                                                                                                                                                                                                                          in
                                                                                                                                                                                                                                                          order
                                                                                                                                                                                                                                                          to
                                                                                                                                                                                                                                                          provide
                                                                                                                                                                                                                                                          you
                                                                                                                                                                                                                                                          our
                                                                                                                                                                                                                                                          Services
                                                                                                                                                                                                                                                          at
                                                                                                                                                                                                                                                          a
                                                                                                                                                                                                                                                          business
                                                                                                                                                                                                                                                          level
                                                                                                                                                                                                                                                          or
                                                                                                                                                                                                                                                          job
                                                                                                                                                                                                                                                          title,
                                                                                                                                                                                                                                                          work
                                                                                                                                                                                                                                                          history,
                                                                                                                                                                                                                                                          and
                                                                                                                                                                                                                                                          professional
                                                                                                                                                                                                                                                          qualifications
                                                                                                                                                                                                                                                          if
                                                                                                                                                                                                                                                          you
                                                                                                                                                                                                                                                          apply
                                                                                                                                                                                                                                                          for
                                                                                                                                                                                                                                                          a
                                                                                                                                                                                                                                                          job
                                                                                                                                                                                                                                                          with
                                                                                                                                                                                                                                                          us</span></span></span>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <br>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                          <div data-custom-class="body_text"
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                          class="forloop-component">
                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                  NO
                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                          </span>
                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <br>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                          </tr>
                                                                                                                                                                                                          <tr>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black; width: 33.8274%;">
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                          J
                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                  class="else-block">
                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                          .
                                                                                                                                                                                                                                                          Education
                                                                                                                                                                                                                                                          Information
                                                                                                                                                                                                                                                  </span></span></span>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="border-right: 1px solid black; border-top: 1px solid black; width: 51.4385%;">
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                          data-custom-class="body_text">Student
                                                                                                                                                                                                                                                          records
                                                                                                                                                                                                                                                          and
                                                                                                                                                                                                                                                          directory
                                                                                                                                                                                                                                                          information</span></span></span>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="text-align: center; border-right: 1px solid black; border-top: 1px solid black; width: 14.9084%;">
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <br>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                          <div data-custom-class="body_text"
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                          class="forloop-component">
                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                  NO
                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                          </span>
                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <br>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                          </tr>
                                                                                                                                                                                                          <tr>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="border-width: 1px; border-color: black; border-style: solid; width: 33.8274%;">
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                          K
                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                  class="else-block">
                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                          .
                                                                                                                                                                                                                                                          Inferences
                                                                                                                                                                                                                                                          drawn
                                                                                                                                                                                                                                                          from
                                                                                                                                                                                                                                                          collected
                                                                                                                                                                                                                                                          personal
                                                                                                                                                                                                                                                          information
                                                                                                                                                                                                                                                  </span></span></span>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="border-bottom: 1px solid black; border-top: 1px solid black; border-right: 1px solid black; width: 51.4385%;">
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                          data-custom-class="body_text">Inferences
                                                                                                                                                                                                                                                          drawn
                                                                                                                                                                                                                                                          from
                                                                                                                                                                                                                                                          any
                                                                                                                                                                                                                                                          of
                                                                                                                                                                                                                                                          the
                                                                                                                                                                                                                                                          collected
                                                                                                                                                                                                                                                          personal
                                                                                                                                                                                                                                                          information
                                                                                                                                                                                                                                                          listed
                                                                                                                                                                                                                                                          above
                                                                                                                                                                                                                                                          to
                                                                                                                                                                                                                                                          create
                                                                                                                                                                                                                                                          a
                                                                                                                                                                                                                                                          profile
                                                                                                                                                                                                                                                          or
                                                                                                                                                                                                                                                          summary
                                                                                                                                                                                                                                                          about,
                                                                                                                                                                                                                                                          for
                                                                                                                                                                                                                                                          example,
                                                                                                                                                                                                                                                          an
                                                                                                                                                                                                                                                          individual’s
                                                                                                                                                                                                                                                          preferences
                                                                                                                                                                                                                                                          and
                                                                                                                                                                                                                                                          characteristics</span></span></span>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="text-align: center; border-right: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black; width: 14.9084%;">
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <br>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                          <div data-custom-class="body_text"
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                          NO<span
                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                          </span></span></span></span></span>
                                                                                                                                                                                                                                  </span></span></span>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <br>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                          </tr>
                                                                                                                                                                                                          <tr>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="border-left: 1px solid black; border-right: 1px solid black; border-bottom: 1px solid black; line-height: 1.5;">
                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                  L
                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                          class="else-block">
                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                  .
                                                                                                                                                                                                                                  Sensitive
                                                                                                                                                                                                                                  personal
                                                                                                                                                                                                                                  Information
                                                                                                                                                                                                                          </span>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="border-right: 1px solid black; border-bottom: 1px solid black; line-height: 1.5;">
                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                          data-custom-class="body_text"></span>
                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                                  <td
                                                                                                                                                                                                                          style="border-right: 1px solid black; border-bottom: 1px solid black;">
                                                                                                                                                                                                                          <div data-empty="true"
                                                                                                                                                                                                                                  style="text-align: center;">
                                                                                                                                                                                                                                  <br>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                          <div data-custom-class="body_text"
                                                                                                                                                                                                                                  data-empty="true"
                                                                                                                                                                                                                                  style="text-align: center; line-height: 1.5;">
                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                  NO</span>
                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                  data-custom-class="body_text"></span>
                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                          <div data-empty="true"
                                                                                                                                                                                                                                  style="text-align: center;">
                                                                                                                                                                                                                                  <br>
                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                  </td>
                                                                                                                                                                                                          </tr>
                                                                                                                                                                                                  </tbody>
                                                                                                                                                                                          </table>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <br>
                                                                                                                                                                                          </div>
                                                                                                                                                                                          <div
                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                          <span
                                                                                                                                                                                                                  data-custom-class="body_text"></span>
                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                  <span
                                                                                                                                                                                                          data-custom-class="body_text">We
                                                                                                                                                                                                          will
                                                                                                                                                                                                          use
                                                                                                                                                                                                          and
                                                                                                                                                                                                          retain
                                                                                                                                                                                                          the
                                                                                                                                                                                                          collected
                                                                                                                                                                                                          personal
                                                                                                                                                                                                          information
                                                                                                                                                                                                          as
                                                                                                                                                                                                          needed
                                                                                                                                                                                                          to
                                                                                                                                                                                                          provide
                                                                                                                                                                                                          the
                                                                                                                                                                                                          Services
                                                                                                                                                                                                          or
                                                                                                                                                                                                          for:
                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                          </span>
                                                                                                                                                                                                  </li>
                                                                                                                                                                                                  </ul>
                                                                                                                                                                                                  <div
                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                          <span
                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                          </span>
                                                                                                                                                                                                  </div>
                                                                                                                                                                                                  <ul>
                                                                                                                                                                                                          <li
                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                          data-custom-class="body_text">Category
                                                                                                                                                                                                                          B
                                                                                                                                                                                                                          -
                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                  class="question">
                                                                                                                                                                                                                                  Not
                                                                                                                                                                                                                                  Applicable
                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                  </span>
                                                                                                                                                                                                          </li>
                                                                                                                                                                                                  </ul>
                                                                                                                                                                                                  <div
                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                          <span
                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                          </span>
                                                                                                                                                                                                          </li>
                                                                                                                                                                                                          </ul>
                                                                                                                                                                                                          <div
                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                  </span>
                                                                                                                                                                                                                  </li>
                                                                                                                                                                                                                  </ul>
                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                          </span>
                                                                                                                                                                                                                          </li>
                                                                                                                                                                                                                          </ul>
                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                  </span>
                                                                                                                                                                                                                                  </li>
                                                                                                                                                                                                                                  </ul>
                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                          </span>
                                                                                                                                                                                                                                          </li>
                                                                                                                                                                                                                                          </ul>
                                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                  </span>
                                                                                                                                                                                                                                                  </li>
                                                                                                                                                                                                                                                  </ul>
                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                          </span>
                                                                                                                                                                                                                                                          </li>
                                                                                                                                                                                                                                                          </ul>
                                                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                  </span>
                                                                                                                                                                                                                                                                  </li>
                                                                                                                                                                                                                                                                  </ul>
                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                          </span>
                                                                                                                                                                                                                                                                          </li>
                                                                                                                                                                                                                                                                          </ul>
                                                                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                  </span>
                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"></span>
                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                  </li>
                                                                                                                                                                                                                                                                                  </ul>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                          </span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">We
                                                                                                                                                                                                                                                                                                                  may
                                                                                                                                                                                                                                                                                                                  also
                                                                                                                                                                                                                                                                                                                  collect
                                                                                                                                                                                                                                                                                                                  other
                                                                                                                                                                                                                                                                                                                  personal
                                                                                                                                                                                                                                                                                                                  information
                                                                                                                                                                                                                                                                                                                  outside
                                                                                                                                                                                                                                                                                                                  of
                                                                                                                                                                                                                                                                                                                  these
                                                                                                                                                                                                                                                                                                                  categories
                                                                                                                                                                                                                                                                                                                  through
                                                                                                                                                                                                                                                                                                                  instances
                                                                                                                                                                                                                                                                                                                  where
                                                                                                                                                                                                                                                                                                                  you
                                                                                                                                                                                                                                                                                                                  interact
                                                                                                                                                                                                                                                                                                                  with
                                                                                                                                                                                                                                                                                                                  us
                                                                                                                                                                                                                                                                                                                  in
                                                                                                                                                                                                                                                                                                                  person,
                                                                                                                                                                                                                                                                                                                  online,
                                                                                                                                                                                                                                                                                                                  or
                                                                                                                                                                                                                                                                                                                  by
                                                                                                                                                                                                                                                                                                                  phone
                                                                                                                                                                                                                                                                                                                  or
                                                                                                                                                                                                                                                                                                                  mail
                                                                                                                                                                                                                                                                                                                  in
                                                                                                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                                                                                                  context
                                                                                                                                                                                                                                                                                                                  of:<span
                                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                                                                  </span></span></span></span></span></span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <ul>
                                                                                                                                                                                                                                                                                          <li
                                                                                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">Receiving
                                                                                                                                                                                                                                                                                                                          help
                                                                                                                                                                                                                                                                                                                          through
                                                                                                                                                                                                                                                                                                                          our
                                                                                                                                                                                                                                                                                                                          customer
                                                                                                                                                                                                                                                                                                                          support
                                                                                                                                                                                                                                                                                                                          channels;</span><span
                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                                                  style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                                                                                                                  </span></span></span></span></span></span></span></span></span></span></span></span></span>
                                                                                                                                                                                                                                                                                          </li>
                                                                                                                                                                                                                                                                                  </ul>
                                                                                                                                                                                                                                                                                  <div><span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                                                                  </span></span></span></span></span></span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <ul>
                                                                                                                                                                                                                                                                                          <li
                                                                                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">Participation
                                                                                                                                                                                                                                                                                                                          in
                                                                                                                                                                                                                                                                                                                          customer
                                                                                                                                                                                                                                                                                                                          surveys
                                                                                                                                                                                                                                                                                                                          or
                                                                                                                                                                                                                                                                                                                          contests;
                                                                                                                                                                                                                                                                                                                          and</span><span
                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                                                  style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                                                                                                                  </span></span></span></span></span></span></span></span></span></span></span></span></span>
                                                                                                                                                                                                                                                                                          </li>
                                                                                                                                                                                                                                                                                  </ul>
                                                                                                                                                                                                                                                                                  <div><span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                                                                  </span></span></span></span></span></span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <ul>
                                                                                                                                                                                                                                                                                          <li
                                                                                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">Facilitation
                                                                                                                                                                                                                                                                                                                          in
                                                                                                                                                                                                                                                                                                                          the
                                                                                                                                                                                                                                                                                                                          delivery
                                                                                                                                                                                                                                                                                                                          of
                                                                                                                                                                                                                                                                                                                          our
                                                                                                                                                                                                                                                                                                                          Services
                                                                                                                                                                                                                                                                                                                          and
                                                                                                                                                                                                                                                                                                                          to
                                                                                                                                                                                                                                                                                                                          respond
                                                                                                                                                                                                                                                                                                                          to
                                                                                                                                                                                                                                                                                                                          your
                                                                                                                                                                                                                                                                                                                          inquiries.</span><span
                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                                                  style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                                                                                                                  </span></span></span></span></span></span></span></span></span></span></span></span></span>
                                                                                                                                                                                                                                                                                          </li>
                                                                                                                                                                                                                                                                                  </ul>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><strong>How
                                                                                                                                                                                                                                                                                                                          do
                                                                                                                                                                                                                                                                                                                          we
                                                                                                                                                                                                                                                                                                                          use
                                                                                                                                                                                                                                                                                                                          and
                                                                                                                                                                                                                                                                                                                          share
                                                                                                                                                                                                                                                                                                                          your
                                                                                                                                                                                                                                                                                                                          personal
                                                                                                                                                                                                                                                                                                                          information?</strong></span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <br>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span data-custom-class="body_text"
                                                                                                                                                                                                                                                                                                  style="font-size: 15px;">Learn
                                                                                                                                                                                                                                                                                                  about
                                                                                                                                                                                                                                                                                                  how
                                                                                                                                                                                                                                                                                                  we
                                                                                                                                                                                                                                                                                                  use
                                                                                                                                                                                                                                                                                                  your
                                                                                                                                                                                                                                                                                                  personal
                                                                                                                                                                                                                                                                                                  information
                                                                                                                                                                                                                                                                                                  in
                                                                                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                                                                                  section,
                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                  "
                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                  </span><a
                                                                                                                                                                                                                                                                                                  href="#infouse"><span
                                                                                                                                                                                                                                                                                                          data-custom-class="link"
                                                                                                                                                                                                                                                                                                          style="font-size: 15px;">HOW
                                                                                                                                                                                                                                                                                                          DO
                                                                                                                                                                                                                                                                                                          WE
                                                                                                                                                                                                                                                                                                          PROCESS
                                                                                                                                                                                                                                                                                                          YOUR
                                                                                                                                                                                                                                                                                                          INFORMATION?</span></a><span
                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"
                                                                                                                                                                                                                                                                                                  style="font-size: 15px;">
                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                  "
                                                                                                                                                                                                                                                                                          </span>
                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                  <span data-custom-class="body_text"
                                                                                                                                                                                                                                                                                                          style="font-size: 15px;"></span>
                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <br>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                          </span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span>
                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                          </span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><strong>Will
                                                                                                                                                                                                                                                                                                                          your
                                                                                                                                                                                                                                                                                                                          information
                                                                                                                                                                                                                                                                                                                          be
                                                                                                                                                                                                                                                                                                                          shared
                                                                                                                                                                                                                                                                                                                          with
                                                                                                                                                                                                                                                                                                                          anyone
                                                                                                                                                                                                                                                                                                                          else?</strong></span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <br>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">We
                                                                                                                                                                                                                                                                                                                  may
                                                                                                                                                                                                                                                                                                                  disclose
                                                                                                                                                                                                                                                                                                                  your
                                                                                                                                                                                                                                                                                                                  personal
                                                                                                                                                                                                                                                                                                                  information
                                                                                                                                                                                                                                                                                                                  with
                                                                                                                                                                                                                                                                                                                  our
                                                                                                                                                                                                                                                                                                                  service
                                                                                                                                                                                                                                                                                                                  providers
                                                                                                                                                                                                                                                                                                                  pursuant
                                                                                                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                                                                                                  a
                                                                                                                                                                                                                                                                                                                  written
                                                                                                                                                                                                                                                                                                                  contract
                                                                                                                                                                                                                                                                                                                  between
                                                                                                                                                                                                                                                                                                                  us
                                                                                                                                                                                                                                                                                                                  and
                                                                                                                                                                                                                                                                                                                  each
                                                                                                                                                                                                                                                                                                                  service
                                                                                                                                                                                                                                                                                                                  provider.
                                                                                                                                                                                                                                                                                                                  Learn
                                                                                                                                                                                                                                                                                                                  more
                                                                                                                                                                                                                                                                                                                  about
                                                                                                                                                                                                                                                                                                                  how
                                                                                                                                                                                                                                                                                                                  we
                                                                                                                                                                                                                                                                                                                  disclose
                                                                                                                                                                                                                                                                                                                  personal
                                                                                                                                                                                                                                                                                                                  information
                                                                                                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                                                                                                  in
                                                                                                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                                                                                                  section,
                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                  "
                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                  </span></span></span><a
                                                                                                                                                                                                                                                                                                  href="#whoshare"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                          data-custom-class="link">WHEN
                                                                                                                                                                                                                                                                                                                          AND
                                                                                                                                                                                                                                                                                                                          WITH
                                                                                                                                                                                                                                                                                                                          WHOM
                                                                                                                                                                                                                                                                                                                          DO
                                                                                                                                                                                                                                                                                                                          WE
                                                                                                                                                                                                                                                                                                                          SHARE
                                                                                                                                                                                                                                                                                                                          YOUR
                                                                                                                                                                                                                                                                                                                          PERSONAL
                                                                                                                                                                                                                                                                                                                          INFORMATION?</span></span></span></a><span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                  "
                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                          </span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <br>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">We
                                                                                                                                                                                                                                                                                                                  may
                                                                                                                                                                                                                                                                                                                  use
                                                                                                                                                                                                                                                                                                                  your
                                                                                                                                                                                                                                                                                                                  personal
                                                                                                                                                                                                                                                                                                                  information
                                                                                                                                                                                                                                                                                                                  for
                                                                                                                                                                                                                                                                                                                  our
                                                                                                                                                                                                                                                                                                                  own
                                                                                                                                                                                                                                                                                                                  business
                                                                                                                                                                                                                                                                                                                  purposes,
                                                                                                                                                                                                                                                                                                                  such
                                                                                                                                                                                                                                                                                                                  as
                                                                                                                                                                                                                                                                                                                  for
                                                                                                                                                                                                                                                                                                                  undertaking
                                                                                                                                                                                                                                                                                                                  internal
                                                                                                                                                                                                                                                                                                                  research
                                                                                                                                                                                                                                                                                                                  for
                                                                                                                                                                                                                                                                                                                  technological
                                                                                                                                                                                                                                                                                                                  development
                                                                                                                                                                                                                                                                                                                  and
                                                                                                                                                                                                                                                                                                                  demonstration.
                                                                                                                                                                                                                                                                                                                  This
                                                                                                                                                                                                                                                                                                                  is
                                                                                                                                                                                                                                                                                                                  not
                                                                                                                                                                                                                                                                                                                  considered
                                                                                                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                                                                                                  be
                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                  "selling"
                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                  of
                                                                                                                                                                                                                                                                                                                  your
                                                                                                                                                                                                                                                                                                                  personal
                                                                                                                                                                                                                                                                                                                  information.<span
                                                                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                          </span></span></span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <br>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">We
                                                                                                                                                                                                                                                                                                                  have
                                                                                                                                                                                                                                                                                                                  not
                                                                                                                                                                                                                                                                                                                  disclosed,
                                                                                                                                                                                                                                                                                                                  sold,
                                                                                                                                                                                                                                                                                                                  or
                                                                                                                                                                                                                                                                                                                  shared
                                                                                                                                                                                                                                                                                                                  any
                                                                                                                                                                                                                                                                                                                  personal
                                                                                                                                                                                                                                                                                                                  information
                                                                                                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                                                                                                  third
                                                                                                                                                                                                                                                                                                                  parties
                                                                                                                                                                                                                                                                                                                  for
                                                                                                                                                                                                                                                                                                                  a
                                                                                                                                                                                                                                                                                                                  business
                                                                                                                                                                                                                                                                                                                  or
                                                                                                                                                                                                                                                                                                                  commercial
                                                                                                                                                                                                                                                                                                                  purpose
                                                                                                                                                                                                                                                                                                                  in
                                                                                                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                                                                                                  preceding
                                                                                                                                                                                                                                                                                                                  twelve
                                                                                                                                                                                                                                                                                                                  (12)
                                                                                                                                                                                                                                                                                                                  months.
                                                                                                                                                                                                                                                                                                                  We<span
                                                                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89);"> </span>will
                                                                                                                                                                                                                                                                                                                  not
                                                                                                                                                                                                                                                                                                                  sell
                                                                                                                                                                                                                                                                                                                  or
                                                                                                                                                                                                                                                                                                                  share
                                                                                                                                                                                                                                                                                                                  personal
                                                                                                                                                                                                                                                                                                                  information
                                                                                                                                                                                                                                                                                                                  in
                                                                                                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                                                                                                  future
                                                                                                                                                                                                                                                                                                                  belonging
                                                                                                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                                                                                                  website
                                                                                                                                                                                                                                                                                                                  visitors,
                                                                                                                                                                                                                                                                                                                  users,
                                                                                                                                                                                                                                                                                                                  and
                                                                                                                                                                                                                                                                                                                  other
                                                                                                                                                                                                                                                                                                                  consumers.<span
                                                                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                                                          </span></span></span></span>
                                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                          </span></span></span></span></span></span></span></span></span>
                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                          </span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span><span
                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                          style="color: rgb(0, 0, 0);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                          </span></span></span></span></span>
                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <br>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <strong><span data-custom-class="heading_2"
                                                                                                                                                                                                                                                                                                          style="font-size: 15px;">California
                                                                                                                                                                                                                                                                                                          Residents</span></strong>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <br>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span data-custom-class="body_text"
                                                                                                                                                                                                                                                                                                  style="font-size: 15px;">California
                                                                                                                                                                                                                                                                                                  Civil
                                                                                                                                                                                                                                                                                                  Code
                                                                                                                                                                                                                                                                                                  Section
                                                                                                                                                                                                                                                                                                  1798.83,
                                                                                                                                                                                                                                                                                                  also
                                                                                                                                                                                                                                                                                                  known
                                                                                                                                                                                                                                                                                                  as
                                                                                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                  "Shine
                                                                                                                                                                                                                                                                                                  The
                                                                                                                                                                                                                                                                                                  Light"
                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                  law
                                                                                                                                                                                                                                                                                                  permits
                                                                                                                                                                                                                                                                                                  our
                                                                                                                                                                                                                                                                                                  users
                                                                                                                                                                                                                                                                                                  who
                                                                                                                                                                                                                                                                                                  are
                                                                                                                                                                                                                                                                                                  California
                                                                                                                                                                                                                                                                                                  residents
                                                                                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                                                                                  request
                                                                                                                                                                                                                                                                                                  and
                                                                                                                                                                                                                                                                                                  obtain
                                                                                                                                                                                                                                                                                                  from
                                                                                                                                                                                                                                                                                                  us,
                                                                                                                                                                                                                                                                                                  once
                                                                                                                                                                                                                                                                                                  a
                                                                                                                                                                                                                                                                                                  year
                                                                                                                                                                                                                                                                                                  and
                                                                                                                                                                                                                                                                                                  free
                                                                                                                                                                                                                                                                                                  of
                                                                                                                                                                                                                                                                                                  charge,
                                                                                                                                                                                                                                                                                                  information
                                                                                                                                                                                                                                                                                                  about
                                                                                                                                                                                                                                                                                                  categories
                                                                                                                                                                                                                                                                                                  of
                                                                                                                                                                                                                                                                                                  personal
                                                                                                                                                                                                                                                                                                  information
                                                                                                                                                                                                                                                                                                  (if
                                                                                                                                                                                                                                                                                                  any)
                                                                                                                                                                                                                                                                                                  we
                                                                                                                                                                                                                                                                                                  disclosed
                                                                                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                                                                                  third
                                                                                                                                                                                                                                                                                                  parties
                                                                                                                                                                                                                                                                                                  for
                                                                                                                                                                                                                                                                                                  direct
                                                                                                                                                                                                                                                                                                  marketing
                                                                                                                                                                                                                                                                                                  purposes
                                                                                                                                                                                                                                                                                                  and
                                                                                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                                                                                  names
                                                                                                                                                                                                                                                                                                  and
                                                                                                                                                                                                                                                                                                  addresses
                                                                                                                                                                                                                                                                                                  of
                                                                                                                                                                                                                                                                                                  all
                                                                                                                                                                                                                                                                                                  third
                                                                                                                                                                                                                                                                                                  parties
                                                                                                                                                                                                                                                                                                  with
                                                                                                                                                                                                                                                                                                  which
                                                                                                                                                                                                                                                                                                  we
                                                                                                                                                                                                                                                                                                  shared
                                                                                                                                                                                                                                                                                                  personal
                                                                                                                                                                                                                                                                                                  information
                                                                                                                                                                                                                                                                                                  in
                                                                                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                                                                                  immediately
                                                                                                                                                                                                                                                                                                  preceding
                                                                                                                                                                                                                                                                                                  calendar
                                                                                                                                                                                                                                                                                                  year.
                                                                                                                                                                                                                                                                                                  If
                                                                                                                                                                                                                                                                                                  you
                                                                                                                                                                                                                                                                                                  are
                                                                                                                                                                                                                                                                                                  a
                                                                                                                                                                                                                                                                                                  California
                                                                                                                                                                                                                                                                                                  resident
                                                                                                                                                                                                                                                                                                  and
                                                                                                                                                                                                                                                                                                  would
                                                                                                                                                                                                                                                                                                  like
                                                                                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                                                                                  make
                                                                                                                                                                                                                                                                                                  such
                                                                                                                                                                                                                                                                                                  a
                                                                                                                                                                                                                                                                                                  request,
                                                                                                                                                                                                                                                                                                  please
                                                                                                                                                                                                                                                                                                  submit
                                                                                                                                                                                                                                                                                                  your
                                                                                                                                                                                                                                                                                                  request
                                                                                                                                                                                                                                                                                                  in
                                                                                                                                                                                                                                                                                                  writing
                                                                                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                                                                                  us
                                                                                                                                                                                                                                                                                                  using
                                                                                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                                                                                  contact
                                                                                                                                                                                                                                                                                                  information
                                                                                                                                                                                                                                                                                                  provided
                                                                                                                                                                                                                                                                                                  below.</span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"><br></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span data-custom-class="body_text"
                                                                                                                                                                                                                                                                                                  style="font-size: 15px;">If
                                                                                                                                                                                                                                                                                                  you
                                                                                                                                                                                                                                                                                                  are
                                                                                                                                                                                                                                                                                                  under
                                                                                                                                                                                                                                                                                                  18
                                                                                                                                                                                                                                                                                                  years
                                                                                                                                                                                                                                                                                                  of
                                                                                                                                                                                                                                                                                                  age,
                                                                                                                                                                                                                                                                                                  reside
                                                                                                                                                                                                                                                                                                  in
                                                                                                                                                                                                                                                                                                  California,
                                                                                                                                                                                                                                                                                                  and
                                                                                                                                                                                                                                                                                                  have
                                                                                                                                                                                                                                                                                                  a
                                                                                                                                                                                                                                                                                                  registered
                                                                                                                                                                                                                                                                                                  account
                                                                                                                                                                                                                                                                                                  with
                                                                                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                                                                                  Services,
                                                                                                                                                                                                                                                                                                  you
                                                                                                                                                                                                                                                                                                  have
                                                                                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                                                                                  right
                                                                                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                                                                                  request
                                                                                                                                                                                                                                                                                                  removal
                                                                                                                                                                                                                                                                                                  of
                                                                                                                                                                                                                                                                                                  unwanted
                                                                                                                                                                                                                                                                                                  data
                                                                                                                                                                                                                                                                                                  that
                                                                                                                                                                                                                                                                                                  you
                                                                                                                                                                                                                                                                                                  publicly
                                                                                                                                                                                                                                                                                                  post
                                                                                                                                                                                                                                                                                                  on
                                                                                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                                                                                  Services.
                                                                                                                                                                                                                                                                                                  To
                                                                                                                                                                                                                                                                                                  request
                                                                                                                                                                                                                                                                                                  removal
                                                                                                                                                                                                                                                                                                  of
                                                                                                                                                                                                                                                                                                  such
                                                                                                                                                                                                                                                                                                  data,
                                                                                                                                                                                                                                                                                                  please
                                                                                                                                                                                                                                                                                                  contact
                                                                                                                                                                                                                                                                                                  us
                                                                                                                                                                                                                                                                                                  using
                                                                                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                                                                                  contact
                                                                                                                                                                                                                                                                                                  information
                                                                                                                                                                                                                                                                                                  provided
                                                                                                                                                                                                                                                                                                  below
                                                                                                                                                                                                                                                                                                  and
                                                                                                                                                                                                                                                                                                  include
                                                                                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                                                                                  email
                                                                                                                                                                                                                                                                                                  address
                                                                                                                                                                                                                                                                                                  associated
                                                                                                                                                                                                                                                                                                  with
                                                                                                                                                                                                                                                                                                  your
                                                                                                                                                                                                                                                                                                  account
                                                                                                                                                                                                                                                                                                  and
                                                                                                                                                                                                                                                                                                  a
                                                                                                                                                                                                                                                                                                  statement
                                                                                                                                                                                                                                                                                                  that
                                                                                                                                                                                                                                                                                                  you
                                                                                                                                                                                                                                                                                                  reside
                                                                                                                                                                                                                                                                                                  in
                                                                                                                                                                                                                                                                                                  California.
                                                                                                                                                                                                                                                                                                  We
                                                                                                                                                                                                                                                                                                  will
                                                                                                                                                                                                                                                                                                  make
                                                                                                                                                                                                                                                                                                  sure
                                                                                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                                                                                  data
                                                                                                                                                                                                                                                                                                  is
                                                                                                                                                                                                                                                                                                  not
                                                                                                                                                                                                                                                                                                  publicly
                                                                                                                                                                                                                                                                                                  displayed
                                                                                                                                                                                                                                                                                                  on
                                                                                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                                                                                  Services,
                                                                                                                                                                                                                                                                                                  but
                                                                                                                                                                                                                                                                                                  please
                                                                                                                                                                                                                                                                                                  be
                                                                                                                                                                                                                                                                                                  aware
                                                                                                                                                                                                                                                                                                  that
                                                                                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                                                                                  data
                                                                                                                                                                                                                                                                                                  may
                                                                                                                                                                                                                                                                                                  not
                                                                                                                                                                                                                                                                                                  be
                                                                                                                                                                                                                                                                                                  completely
                                                                                                                                                                                                                                                                                                  or
                                                                                                                                                                                                                                                                                                  comprehensively
                                                                                                                                                                                                                                                                                                  removed
                                                                                                                                                                                                                                                                                                  from
                                                                                                                                                                                                                                                                                                  all
                                                                                                                                                                                                                                                                                                  our
                                                                                                                                                                                                                                                                                                  systems
                                                                                                                                                                                                                                                                                                  (e.g.
                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                  ,
                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                  backups,
                                                                                                                                                                                                                                                                                                  etc.).</span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px;"><br></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"><strong><u>CCPA
                                                                                                                                                                                                                                                                                                                          Privacy
                                                                                                                                                                                                                                                                                                                          Notice</u></strong></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"><br></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">This
                                                                                                                                                                                                                                                                                                          section
                                                                                                                                                                                                                                                                                                          applies
                                                                                                                                                                                                                                                                                                          only
                                                                                                                                                                                                                                                                                                          to
                                                                                                                                                                                                                                                                                                          California
                                                                                                                                                                                                                                                                                                          residents.
                                                                                                                                                                                                                                                                                                          Under
                                                                                                                                                                                                                                                                                                          the
                                                                                                                                                                                                                                                                                                          California
                                                                                                                                                                                                                                                                                                          Consumer
                                                                                                                                                                                                                                                                                                          Privacy
                                                                                                                                                                                                                                                                                                          Act
                                                                                                                                                                                                                                                                                                          (CCPA),
                                                                                                                                                                                                                                                                                                          you
                                                                                                                                                                                                                                                                                                          have
                                                                                                                                                                                                                                                                                                          the
                                                                                                                                                                                                                                                                                                          rights
                                                                                                                                                                                                                                                                                                          listed
                                                                                                                                                                                                                                                                                                          below.</span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"><br></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">The
                                                                                                                                                                                                                                                                                                          California
                                                                                                                                                                                                                                                                                                          Code
                                                                                                                                                                                                                                                                                                          of
                                                                                                                                                                                                                                                                                                          Regulations
                                                                                                                                                                                                                                                                                                          defines
                                                                                                                                                                                                                                                                                                          a
                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                          "residents"
                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                          as:</span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"><br></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="margin-left: 20px; line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">(1)
                                                                                                                                                                                                                                                                                                          every
                                                                                                                                                                                                                                                                                                          individual
                                                                                                                                                                                                                                                                                                          who
                                                                                                                                                                                                                                                                                                          is
                                                                                                                                                                                                                                                                                                          in
                                                                                                                                                                                                                                                                                                          the
                                                                                                                                                                                                                                                                                                          State
                                                                                                                                                                                                                                                                                                          of
                                                                                                                                                                                                                                                                                                          California
                                                                                                                                                                                                                                                                                                          for
                                                                                                                                                                                                                                                                                                          other
                                                                                                                                                                                                                                                                                                          than
                                                                                                                                                                                                                                                                                                          a
                                                                                                                                                                                                                                                                                                          temporary
                                                                                                                                                                                                                                                                                                          or
                                                                                                                                                                                                                                                                                                          transitory
                                                                                                                                                                                                                                                                                                          purpose
                                                                                                                                                                                                                                                                                                          and</span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="margin-left: 20px; line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">(2)
                                                                                                                                                                                                                                                                                                          every
                                                                                                                                                                                                                                                                                                          individual
                                                                                                                                                                                                                                                                                                          who
                                                                                                                                                                                                                                                                                                          is
                                                                                                                                                                                                                                                                                                          domiciled
                                                                                                                                                                                                                                                                                                          in
                                                                                                                                                                                                                                                                                                          the
                                                                                                                                                                                                                                                                                                          State
                                                                                                                                                                                                                                                                                                          of
                                                                                                                                                                                                                                                                                                          California
                                                                                                                                                                                                                                                                                                          who
                                                                                                                                                                                                                                                                                                          is
                                                                                                                                                                                                                                                                                                          outside
                                                                                                                                                                                                                                                                                                          the
                                                                                                                                                                                                                                                                                                          State
                                                                                                                                                                                                                                                                                                          of
                                                                                                                                                                                                                                                                                                          California
                                                                                                                                                                                                                                                                                                          for
                                                                                                                                                                                                                                                                                                          a
                                                                                                                                                                                                                                                                                                          temporary
                                                                                                                                                                                                                                                                                                          or
                                                                                                                                                                                                                                                                                                          transitory
                                                                                                                                                                                                                                                                                                          purpose</span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"><br></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">All
                                                                                                                                                                                                                                                                                                          other
                                                                                                                                                                                                                                                                                                          individuals
                                                                                                                                                                                                                                                                                                          are
                                                                                                                                                                                                                                                                                                          defined
                                                                                                                                                                                                                                                                                                          as
                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                          "non-residents."
                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                          </span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"><br></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">If
                                                                                                                                                                                                                                                                                                          this
                                                                                                                                                                                                                                                                                                          definition
                                                                                                                                                                                                                                                                                                          of
                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                          "resident"
                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                          applies
                                                                                                                                                                                                                                                                                                          to
                                                                                                                                                                                                                                                                                                          you,
                                                                                                                                                                                                                                                                                                          we
                                                                                                                                                                                                                                                                                                          must
                                                                                                                                                                                                                                                                                                          adhere
                                                                                                                                                                                                                                                                                                          to
                                                                                                                                                                                                                                                                                                          certain
                                                                                                                                                                                                                                                                                                          rights
                                                                                                                                                                                                                                                                                                          and
                                                                                                                                                                                                                                                                                                          obligations
                                                                                                                                                                                                                                                                                                          regarding
                                                                                                                                                                                                                                                                                                          your
                                                                                                                                                                                                                                                                                                          personal
                                                                                                                                                                                                                                                                                                          information.</span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <br>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><strong>Your
                                                                                                                                                                                                                                                                                                                          rights
                                                                                                                                                                                                                                                                                                                          with
                                                                                                                                                                                                                                                                                                                          respect
                                                                                                                                                                                                                                                                                                                          to
                                                                                                                                                                                                                                                                                                                          your
                                                                                                                                                                                                                                                                                                                          personal
                                                                                                                                                                                                                                                                                                                          data</strong></span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <br>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><u>Right
                                                                                                                                                                                                                                                                                                                          to
                                                                                                                                                                                                                                                                                                                          request
                                                                                                                                                                                                                                                                                                                          deletion
                                                                                                                                                                                                                                                                                                                          of
                                                                                                                                                                                                                                                                                                                          the
                                                                                                                                                                                                                                                                                                                          data
                                                                                                                                                                                                                                                                                                                          —
                                                                                                                                                                                                                                                                                                                          Request
                                                                                                                                                                                                                                                                                                                          to
                                                                                                                                                                                                                                                                                                                          delete</u></span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <br>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">You
                                                                                                                                                                                                                                                                                                                  can
                                                                                                                                                                                                                                                                                                                  ask
                                                                                                                                                                                                                                                                                                                  for
                                                                                                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                                                                                                  deletion
                                                                                                                                                                                                                                                                                                                  of
                                                                                                                                                                                                                                                                                                                  your
                                                                                                                                                                                                                                                                                                                  personal
                                                                                                                                                                                                                                                                                                                  information.
                                                                                                                                                                                                                                                                                                                  If
                                                                                                                                                                                                                                                                                                                  you
                                                                                                                                                                                                                                                                                                                  ask
                                                                                                                                                                                                                                                                                                                  us
                                                                                                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                                                                                                  delete
                                                                                                                                                                                                                                                                                                                  your
                                                                                                                                                                                                                                                                                                                  personal
                                                                                                                                                                                                                                                                                                                  information,
                                                                                                                                                                                                                                                                                                                  we
                                                                                                                                                                                                                                                                                                                  will
                                                                                                                                                                                                                                                                                                                  respect
                                                                                                                                                                                                                                                                                                                  your
                                                                                                                                                                                                                                                                                                                  request
                                                                                                                                                                                                                                                                                                                  and
                                                                                                                                                                                                                                                                                                                  delete
                                                                                                                                                                                                                                                                                                                  your
                                                                                                                                                                                                                                                                                                                  personal
                                                                                                                                                                                                                                                                                                                  information,
                                                                                                                                                                                                                                                                                                                  subject
                                                                                                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                                                                                                  certain
                                                                                                                                                                                                                                                                                                                  exceptions
                                                                                                                                                                                                                                                                                                                  provided
                                                                                                                                                                                                                                                                                                                  by
                                                                                                                                                                                                                                                                                                                  law,
                                                                                                                                                                                                                                                                                                                  such
                                                                                                                                                                                                                                                                                                                  as
                                                                                                                                                                                                                                                                                                                  (but
                                                                                                                                                                                                                                                                                                                  not
                                                                                                                                                                                                                                                                                                                  limited
                                                                                                                                                                                                                                                                                                                  to)
                                                                                                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                                                                                                  exercise
                                                                                                                                                                                                                                                                                                                  by
                                                                                                                                                                                                                                                                                                                  another
                                                                                                                                                                                                                                                                                                                  consumer
                                                                                                                                                                                                                                                                                                                  of
                                                                                                                                                                                                                                                                                                                  his
                                                                                                                                                                                                                                                                                                                  or
                                                                                                                                                                                                                                                                                                                  her
                                                                                                                                                                                                                                                                                                                  right
                                                                                                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                                                                                                  free
                                                                                                                                                                                                                                                                                                                  speech,
                                                                                                                                                                                                                                                                                                                  our
                                                                                                                                                                                                                                                                                                                  compliance
                                                                                                                                                                                                                                                                                                                  requirements
                                                                                                                                                                                                                                                                                                                  resulting
                                                                                                                                                                                                                                                                                                                  from
                                                                                                                                                                                                                                                                                                                  a
                                                                                                                                                                                                                                                                                                                  legal
                                                                                                                                                                                                                                                                                                                  obligation,
                                                                                                                                                                                                                                                                                                                  or
                                                                                                                                                                                                                                                                                                                  any
                                                                                                                                                                                                                                                                                                                  processing
                                                                                                                                                                                                                                                                                                                  that
                                                                                                                                                                                                                                                                                                                  may
                                                                                                                                                                                                                                                                                                                  be
                                                                                                                                                                                                                                                                                                                  required
                                                                                                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                                                                                                  protect
                                                                                                                                                                                                                                                                                                                  against
                                                                                                                                                                                                                                                                                                                  illegal
                                                                                                                                                                                                                                                                                                                  activities.</span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <br>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><u>Right
                                                                                                                                                                                                                                                                                                                          to
                                                                                                                                                                                                                                                                                                                          be
                                                                                                                                                                                                                                                                                                                          informed
                                                                                                                                                                                                                                                                                                                          —
                                                                                                                                                                                                                                                                                                                          Request
                                                                                                                                                                                                                                                                                                                          to
                                                                                                                                                                                                                                                                                                                          know</u></span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <br>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">Depending
                                                                                                                                                                                                                                                                                                                  on
                                                                                                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                                                                                                  circumstances,
                                                                                                                                                                                                                                                                                                                  you
                                                                                                                                                                                                                                                                                                                  have
                                                                                                                                                                                                                                                                                                                  a
                                                                                                                                                                                                                                                                                                                  right
                                                                                                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                                                                                                  know:<span
                                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                                                                          </span></span></span></span></span></span></span></span></span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <ul>
                                                                                                                                                                                                                                                                                          <li
                                                                                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">whether
                                                                                                                                                                                                                                                                                                                          we
                                                                                                                                                                                                                                                                                                                          collect
                                                                                                                                                                                                                                                                                                                          and
                                                                                                                                                                                                                                                                                                                          use
                                                                                                                                                                                                                                                                                                                          your
                                                                                                                                                                                                                                                                                                                          personal
                                                                                                                                                                                                                                                                                                                          information;<span
                                                                                                                                                                                                                                                                                                                                  style="color: rgb(0, 0, 0);"><span
                                                                                                                                                                                                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                          style="color: rgb(0, 0, 0);"><span
                                                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                                                                                  </span></span></span></span></span></span></span></span></span>
                                                                                                                                                                                                                                                                                          </li>
                                                                                                                                                                                                                                                                                  </ul>
                                                                                                                                                                                                                                                                                  <div><span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                                                                          </span></span></span></span></span></span></span></span></span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <ul>
                                                                                                                                                                                                                                                                                          <li
                                                                                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">the
                                                                                                                                                                                                                                                                                                                          categories
                                                                                                                                                                                                                                                                                                                          of
                                                                                                                                                                                                                                                                                                                          personal
                                                                                                                                                                                                                                                                                                                          information
                                                                                                                                                                                                                                                                                                                          that
                                                                                                                                                                                                                                                                                                                          we
                                                                                                                                                                                                                                                                                                                          collect;<span
                                                                                                                                                                                                                                                                                                                                  style="color: rgb(0, 0, 0);"><span
                                                                                                                                                                                                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                          style="color: rgb(0, 0, 0);"><span
                                                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                                                                                  </span></span></span></span></span></span></span></span></span>
                                                                                                                                                                                                                                                                                          </li>
                                                                                                                                                                                                                                                                                  </ul>
                                                                                                                                                                                                                                                                                  <div><span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                                                                          </span></span></span></span></span></span></span></span></span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <ul>
                                                                                                                                                                                                                                                                                          <li
                                                                                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">the
                                                                                                                                                                                                                                                                                                                          purposes
                                                                                                                                                                                                                                                                                                                          for
                                                                                                                                                                                                                                                                                                                          which
                                                                                                                                                                                                                                                                                                                          the
                                                                                                                                                                                                                                                                                                                          collected
                                                                                                                                                                                                                                                                                                                          personal
                                                                                                                                                                                                                                                                                                                          information
                                                                                                                                                                                                                                                                                                                          is
                                                                                                                                                                                                                                                                                                                          used;<span
                                                                                                                                                                                                                                                                                                                                  style="color: rgb(0, 0, 0);"><span
                                                                                                                                                                                                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                          style="color: rgb(0, 0, 0);"><span
                                                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                                                                                  </span></span></span></span></span></span></span></span></span>
                                                                                                                                                                                                                                                                                          </li>
                                                                                                                                                                                                                                                                                  </ul>
                                                                                                                                                                                                                                                                                  <div><span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                                                                          </span></span></span></span></span></span></span></span></span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <ul>
                                                                                                                                                                                                                                                                                          <li
                                                                                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">whether
                                                                                                                                                                                                                                                                                                                          we
                                                                                                                                                                                                                                                                                                                          sell
                                                                                                                                                                                                                                                                                                                          or
                                                                                                                                                                                                                                                                                                                          share
                                                                                                                                                                                                                                                                                                                          personal
                                                                                                                                                                                                                                                                                                                          information
                                                                                                                                                                                                                                                                                                                          to
                                                                                                                                                                                                                                                                                                                          third
                                                                                                                                                                                                                                                                                                                          parties;<span
                                                                                                                                                                                                                                                                                                                                  style="color: rgb(0, 0, 0);"><span
                                                                                                                                                                                                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                          style="color: rgb(0, 0, 0);"><span
                                                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                                                                                  </span></span></span></span></span></span></span></span></span>
                                                                                                                                                                                                                                                                                          </li>
                                                                                                                                                                                                                                                                                  </ul>
                                                                                                                                                                                                                                                                                  <div><span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                                                                          </span></span></span></span></span></span></span></span></span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <ul>
                                                                                                                                                                                                                                                                                          <li
                                                                                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">the
                                                                                                                                                                                                                                                                                                                          categories
                                                                                                                                                                                                                                                                                                                          of
                                                                                                                                                                                                                                                                                                                          personal
                                                                                                                                                                                                                                                                                                                          information
                                                                                                                                                                                                                                                                                                                          that
                                                                                                                                                                                                                                                                                                                          we
                                                                                                                                                                                                                                                                                                                          sold,
                                                                                                                                                                                                                                                                                                                          shared,
                                                                                                                                                                                                                                                                                                                          or
                                                                                                                                                                                                                                                                                                                          disclosed
                                                                                                                                                                                                                                                                                                                          for
                                                                                                                                                                                                                                                                                                                          a
                                                                                                                                                                                                                                                                                                                          business
                                                                                                                                                                                                                                                                                                                          purpose;<span
                                                                                                                                                                                                                                                                                                                                  style="color: rgb(0, 0, 0);"><span
                                                                                                                                                                                                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                          style="color: rgb(0, 0, 0);"><span
                                                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                                                                                  </span></span></span></span></span></span></span></span></span>
                                                                                                                                                                                                                                                                                          </li>
                                                                                                                                                                                                                                                                                  </ul>
                                                                                                                                                                                                                                                                                  <div><span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                                                                          </span></span></span></span></span></span></span></span></span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <ul>
                                                                                                                                                                                                                                                                                          <li
                                                                                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">the
                                                                                                                                                                                                                                                                                                                          categories
                                                                                                                                                                                                                                                                                                                          of
                                                                                                                                                                                                                                                                                                                          third
                                                                                                                                                                                                                                                                                                                          parties
                                                                                                                                                                                                                                                                                                                          to
                                                                                                                                                                                                                                                                                                                          whom
                                                                                                                                                                                                                                                                                                                          the
                                                                                                                                                                                                                                                                                                                          personal
                                                                                                                                                                                                                                                                                                                          information
                                                                                                                                                                                                                                                                                                                          was
                                                                                                                                                                                                                                                                                                                          sold,
                                                                                                                                                                                                                                                                                                                          shared,
                                                                                                                                                                                                                                                                                                                          or
                                                                                                                                                                                                                                                                                                                          disclosed
                                                                                                                                                                                                                                                                                                                          for
                                                                                                                                                                                                                                                                                                                          a
                                                                                                                                                                                                                                                                                                                          business
                                                                                                                                                                                                                                                                                                                          purpose;<span
                                                                                                                                                                                                                                                                                                                                  style="color: rgb(0, 0, 0);"><span
                                                                                                                                                                                                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                          style="color: rgb(0, 0, 0);"><span
                                                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                                                                                  </span></span></span></span></span></span></span></span></span>
                                                                                                                                                                                                                                                                                          </li>
                                                                                                                                                                                                                                                                                  </ul>
                                                                                                                                                                                                                                                                                  <div><span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                                                                          </span></span></span></span></span></span></span></span></span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <ul>
                                                                                                                                                                                                                                                                                          <li
                                                                                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">the
                                                                                                                                                                                                                                                                                                                          business
                                                                                                                                                                                                                                                                                                                          or
                                                                                                                                                                                                                                                                                                                          commercial
                                                                                                                                                                                                                                                                                                                          purpose
                                                                                                                                                                                                                                                                                                                          for
                                                                                                                                                                                                                                                                                                                          collecting,
                                                                                                                                                                                                                                                                                                                          selling,
                                                                                                                                                                                                                                                                                                                          or
                                                                                                                                                                                                                                                                                                                          sharing
                                                                                                                                                                                                                                                                                                                          personal
                                                                                                                                                                                                                                                                                                                          information;
                                                                                                                                                                                                                                                                                                                          and<span
                                                                                                                                                                                                                                                                                                                                  style="color: rgb(0, 0, 0);"><span
                                                                                                                                                                                                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                          style="color: rgb(0, 0, 0);"><span
                                                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                                                                                  </span></span></span></span></span></span></span></span></span>
                                                                                                                                                                                                                                                                                          </li>
                                                                                                                                                                                                                                                                                  </ul>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"></span>
                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <ul>
                                                                                                                                                                                                                                                                                          <li
                                                                                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">the
                                                                                                                                                                                                                                                                                                          specific
                                                                                                                                                                                                                                                                                                          pieces
                                                                                                                                                                                                                                                                                                          of
                                                                                                                                                                                                                                                                                                          personal
                                                                                                                                                                                                                                                                                                          information
                                                                                                                                                                                                                                                                                                          we
                                                                                                                                                                                                                                                                                                          collected
                                                                                                                                                                                                                                                                                                          about
                                                                                                                                                                                                                                                                                                          you.
                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                          </span>
                                                                                                                                                                                                                                                                                          </li>
                                                                                                                                                                                                                                                                                  </ul>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">In
                                                                                                                                                                                                                                                                                                                  accordance
                                                                                                                                                                                                                                                                                                                  with
                                                                                                                                                                                                                                                                                                                  applicable
                                                                                                                                                                                                                                                                                                                  law,
                                                                                                                                                                                                                                                                                                                  we
                                                                                                                                                                                                                                                                                                                  are
                                                                                                                                                                                                                                                                                                                  not
                                                                                                                                                                                                                                                                                                                  obligated
                                                                                                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                                                                                                  provide
                                                                                                                                                                                                                                                                                                                  or
                                                                                                                                                                                                                                                                                                                  delete
                                                                                                                                                                                                                                                                                                                  consumer
                                                                                                                                                                                                                                                                                                                  information
                                                                                                                                                                                                                                                                                                                  that
                                                                                                                                                                                                                                                                                                                  is
                                                                                                                                                                                                                                                                                                                  de-identified
                                                                                                                                                                                                                                                                                                                  in
                                                                                                                                                                                                                                                                                                                  response
                                                                                                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                                                                                                  a
                                                                                                                                                                                                                                                                                                                  consumer
                                                                                                                                                                                                                                                                                                                  request
                                                                                                                                                                                                                                                                                                                  or
                                                                                                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                                                                                                  re-identify
                                                                                                                                                                                                                                                                                                                  individual
                                                                                                                                                                                                                                                                                                                  data
                                                                                                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                                                                                                  verify
                                                                                                                                                                                                                                                                                                                  a
                                                                                                                                                                                                                                                                                                                  consumer
                                                                                                                                                                                                                                                                                                                  request.</span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <br>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><u>Right
                                                                                                                                                                                                                                                                                                                          to
                                                                                                                                                                                                                                                                                                                          Non-Discrimination
                                                                                                                                                                                                                                                                                                                          for
                                                                                                                                                                                                                                                                                                                          the
                                                                                                                                                                                                                                                                                                                          Exercise
                                                                                                                                                                                                                                                                                                                          of
                                                                                                                                                                                                                                                                                                                          a
                                                                                                                                                                                                                                                                                                                          Consumer’s
                                                                                                                                                                                                                                                                                                                          Privacy
                                                                                                                                                                                                                                                                                                                          Rights</u></span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <br>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">We
                                                                                                                                                                                                                                                                                                                  will
                                                                                                                                                                                                                                                                                                                  not
                                                                                                                                                                                                                                                                                                                  discriminate
                                                                                                                                                                                                                                                                                                                  against
                                                                                                                                                                                                                                                                                                                  you
                                                                                                                                                                                                                                                                                                                  if
                                                                                                                                                                                                                                                                                                                  you
                                                                                                                                                                                                                                                                                                                  exercise
                                                                                                                                                                                                                                                                                                                  your
                                                                                                                                                                                                                                                                                                                  privacy
                                                                                                                                                                                                                                                                                                                  rights.</span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <br>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <u><span data-custom-class="body_text">Right
                                                                                                                                                                                                                                                                                                          to
                                                                                                                                                                                                                                                                                                          Limit
                                                                                                                                                                                                                                                                                                          Use
                                                                                                                                                                                                                                                                                                          and
                                                                                                                                                                                                                                                                                                          Disclosure
                                                                                                                                                                                                                                                                                                          of
                                                                                                                                                                                                                                                                                                          Sensitive
                                                                                                                                                                                                                                                                                                          Personal
                                                                                                                                                                                                                                                                                                          Information</span></u>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                          </span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <br>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">We
                                                                                                                                                                                                                                                                                                  do
                                                                                                                                                                                                                                                                                                  not
                                                                                                                                                                                                                                                                                                  process
                                                                                                                                                                                                                                                                                                  consumer's
                                                                                                                                                                                                                                                                                                  sensitive
                                                                                                                                                                                                                                                                                                  personal
                                                                                                                                                                                                                                                                                                  information.</span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <br>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"></span>
                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><u>Verification
                                                                                                                                                                                                                                                                                                                          process</u></span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <br>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">Upon
                                                                                                                                                                                                                                                                                                                  receiving
                                                                                                                                                                                                                                                                                                                  your
                                                                                                                                                                                                                                                                                                                  request,
                                                                                                                                                                                                                                                                                                                  we
                                                                                                                                                                                                                                                                                                                  will
                                                                                                                                                                                                                                                                                                                  need
                                                                                                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                                                                                                  verify
                                                                                                                                                                                                                                                                                                                  your
                                                                                                                                                                                                                                                                                                                  identity
                                                                                                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                                                                                                  determine
                                                                                                                                                                                                                                                                                                                  you
                                                                                                                                                                                                                                                                                                                  are
                                                                                                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                                                                                                  same
                                                                                                                                                                                                                                                                                                                  person
                                                                                                                                                                                                                                                                                                                  about
                                                                                                                                                                                                                                                                                                                  whom
                                                                                                                                                                                                                                                                                                                  we
                                                                                                                                                                                                                                                                                                                  have
                                                                                                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                                                                                                  information
                                                                                                                                                                                                                                                                                                                  in
                                                                                                                                                                                                                                                                                                                  our
                                                                                                                                                                                                                                                                                                                  system.
                                                                                                                                                                                                                                                                                                                  These
                                                                                                                                                                                                                                                                                                                  verification
                                                                                                                                                                                                                                                                                                                  efforts
                                                                                                                                                                                                                                                                                                                  require
                                                                                                                                                                                                                                                                                                                  us
                                                                                                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                                                                                                  ask
                                                                                                                                                                                                                                                                                                                  you
                                                                                                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                                                                                                  provide
                                                                                                                                                                                                                                                                                                                  information
                                                                                                                                                                                                                                                                                                                  so
                                                                                                                                                                                                                                                                                                                  that
                                                                                                                                                                                                                                                                                                                  we
                                                                                                                                                                                                                                                                                                                  can
                                                                                                                                                                                                                                                                                                                  match
                                                                                                                                                                                                                                                                                                                  it
                                                                                                                                                                                                                                                                                                                  with
                                                                                                                                                                                                                                                                                                                  information
                                                                                                                                                                                                                                                                                                                  you
                                                                                                                                                                                                                                                                                                                  have
                                                                                                                                                                                                                                                                                                                  previously
                                                                                                                                                                                                                                                                                                                  provided
                                                                                                                                                                                                                                                                                                                  us.
                                                                                                                                                                                                                                                                                                                  For
                                                                                                                                                                                                                                                                                                                  instance,
                                                                                                                                                                                                                                                                                                                  depending
                                                                                                                                                                                                                                                                                                                  on
                                                                                                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                                                                                                  type
                                                                                                                                                                                                                                                                                                                  of
                                                                                                                                                                                                                                                                                                                  request
                                                                                                                                                                                                                                                                                                                  you
                                                                                                                                                                                                                                                                                                                  submit,
                                                                                                                                                                                                                                                                                                                  we
                                                                                                                                                                                                                                                                                                                  may
                                                                                                                                                                                                                                                                                                                  ask
                                                                                                                                                                                                                                                                                                                  you
                                                                                                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                                                                                                  provide
                                                                                                                                                                                                                                                                                                                  certain
                                                                                                                                                                                                                                                                                                                  information
                                                                                                                                                                                                                                                                                                                  so
                                                                                                                                                                                                                                                                                                                  that
                                                                                                                                                                                                                                                                                                                  we
                                                                                                                                                                                                                                                                                                                  can
                                                                                                                                                                                                                                                                                                                  match
                                                                                                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                                                                                                  information
                                                                                                                                                                                                                                                                                                                  you
                                                                                                                                                                                                                                                                                                                  provide
                                                                                                                                                                                                                                                                                                                  with
                                                                                                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                                                                                                  information
                                                                                                                                                                                                                                                                                                                  we
                                                                                                                                                                                                                                                                                                                  already
                                                                                                                                                                                                                                                                                                                  have
                                                                                                                                                                                                                                                                                                                  on
                                                                                                                                                                                                                                                                                                                  file,
                                                                                                                                                                                                                                                                                                                  or
                                                                                                                                                                                                                                                                                                                  we
                                                                                                                                                                                                                                                                                                                  may
                                                                                                                                                                                                                                                                                                                  contact
                                                                                                                                                                                                                                                                                                                  you
                                                                                                                                                                                                                                                                                                                  through
                                                                                                                                                                                                                                                                                                                  a
                                                                                                                                                                                                                                                                                                                  communication
                                                                                                                                                                                                                                                                                                                  method
                                                                                                                                                                                                                                                                                                                  (e.g.
                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                  ,
                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                  phone
                                                                                                                                                                                                                                                                                                                  or
                                                                                                                                                                                                                                                                                                                  email)
                                                                                                                                                                                                                                                                                                                  that
                                                                                                                                                                                                                                                                                                                  you
                                                                                                                                                                                                                                                                                                                  have
                                                                                                                                                                                                                                                                                                                  previously
                                                                                                                                                                                                                                                                                                                  provided
                                                                                                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                                                                                                  us.
                                                                                                                                                                                                                                                                                                                  We
                                                                                                                                                                                                                                                                                                                  may
                                                                                                                                                                                                                                                                                                                  also
                                                                                                                                                                                                                                                                                                                  use
                                                                                                                                                                                                                                                                                                                  other
                                                                                                                                                                                                                                                                                                                  verification
                                                                                                                                                                                                                                                                                                                  methods
                                                                                                                                                                                                                                                                                                                  as
                                                                                                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                                                                                                  circumstances
                                                                                                                                                                                                                                                                                                                  dictate.</span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <br>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">We
                                                                                                                                                                                                                                                                                                                  will
                                                                                                                                                                                                                                                                                                                  only
                                                                                                                                                                                                                                                                                                                  use
                                                                                                                                                                                                                                                                                                                  personal
                                                                                                                                                                                                                                                                                                                  information
                                                                                                                                                                                                                                                                                                                  provided
                                                                                                                                                                                                                                                                                                                  in
                                                                                                                                                                                                                                                                                                                  your
                                                                                                                                                                                                                                                                                                                  request
                                                                                                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                                                                                                  verify
                                                                                                                                                                                                                                                                                                                  your
                                                                                                                                                                                                                                                                                                                  identity
                                                                                                                                                                                                                                                                                                                  or
                                                                                                                                                                                                                                                                                                                  authority
                                                                                                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                                                                                                  make
                                                                                                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                                                                                                  request.
                                                                                                                                                                                                                                                                                                                  To
                                                                                                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                                                                                                  extent
                                                                                                                                                                                                                                                                                                                  possible,
                                                                                                                                                                                                                                                                                                                  we
                                                                                                                                                                                                                                                                                                                  will
                                                                                                                                                                                                                                                                                                                  avoid
                                                                                                                                                                                                                                                                                                                  requesting
                                                                                                                                                                                                                                                                                                                  additional
                                                                                                                                                                                                                                                                                                                  information
                                                                                                                                                                                                                                                                                                                  from
                                                                                                                                                                                                                                                                                                                  you
                                                                                                                                                                                                                                                                                                                  for
                                                                                                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                                                                                                  purposes
                                                                                                                                                                                                                                                                                                                  of
                                                                                                                                                                                                                                                                                                                  verification.
                                                                                                                                                                                                                                                                                                                  However,
                                                                                                                                                                                                                                                                                                                  if
                                                                                                                                                                                                                                                                                                                  we
                                                                                                                                                                                                                                                                                                                  cannot
                                                                                                                                                                                                                                                                                                                  verify
                                                                                                                                                                                                                                                                                                                  your
                                                                                                                                                                                                                                                                                                                  identity
                                                                                                                                                                                                                                                                                                                  from
                                                                                                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                                                                                                  information
                                                                                                                                                                                                                                                                                                                  already
                                                                                                                                                                                                                                                                                                                  maintained
                                                                                                                                                                                                                                                                                                                  by
                                                                                                                                                                                                                                                                                                                  us,
                                                                                                                                                                                                                                                                                                                  we
                                                                                                                                                                                                                                                                                                                  may
                                                                                                                                                                                                                                                                                                                  request
                                                                                                                                                                                                                                                                                                                  that
                                                                                                                                                                                                                                                                                                                  you
                                                                                                                                                                                                                                                                                                                  provide
                                                                                                                                                                                                                                                                                                                  additional
                                                                                                                                                                                                                                                                                                                  information
                                                                                                                                                                                                                                                                                                                  for
                                                                                                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                                                                                                  purposes
                                                                                                                                                                                                                                                                                                                  of
                                                                                                                                                                                                                                                                                                                  verifying
                                                                                                                                                                                                                                                                                                                  your
                                                                                                                                                                                                                                                                                                                  identity
                                                                                                                                                                                                                                                                                                                  and
                                                                                                                                                                                                                                                                                                                  for
                                                                                                                                                                                                                                                                                                                  security
                                                                                                                                                                                                                                                                                                                  or
                                                                                                                                                                                                                                                                                                                  fraud-prevention
                                                                                                                                                                                                                                                                                                                  purposes.
                                                                                                                                                                                                                                                                                                                  We
                                                                                                                                                                                                                                                                                                                  will
                                                                                                                                                                                                                                                                                                                  delete
                                                                                                                                                                                                                                                                                                                  such
                                                                                                                                                                                                                                                                                                                  additionally
                                                                                                                                                                                                                                                                                                                  provided
                                                                                                                                                                                                                                                                                                                  information
                                                                                                                                                                                                                                                                                                                  as
                                                                                                                                                                                                                                                                                                                  soon
                                                                                                                                                                                                                                                                                                                  as
                                                                                                                                                                                                                                                                                                                  we
                                                                                                                                                                                                                                                                                                                  finish
                                                                                                                                                                                                                                                                                                                  verifying
                                                                                                                                                                                                                                                                                                                  you.</span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <br>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><u>Other
                                                                                                                                                                                                                                                                                                                          privacy
                                                                                                                                                                                                                                                                                                                          rights</u></span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div><span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                                                                          </span></span></span></span></span></span></span></span></span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <ul>
                                                                                                                                                                                                                                                                                          <li
                                                                                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">You
                                                                                                                                                                                                                                                                                                                          may
                                                                                                                                                                                                                                                                                                                          object
                                                                                                                                                                                                                                                                                                                          to
                                                                                                                                                                                                                                                                                                                          the
                                                                                                                                                                                                                                                                                                                          processing
                                                                                                                                                                                                                                                                                                                          of
                                                                                                                                                                                                                                                                                                                          your
                                                                                                                                                                                                                                                                                                                          personal
                                                                                                                                                                                                                                                                                                                          information.<span
                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                          style="color: rgb(0, 0, 0);"><span
                                                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                                                  style="color: rgb(0, 0, 0);"><span
                                                                                                                                                                                                                                                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                                                                                          </span></span></span></span></span></span></span></span></span></span></span></span>
                                                                                                                                                                                                                                                                                          </li>
                                                                                                                                                                                                                                                                                  </ul>
                                                                                                                                                                                                                                                                                  <div><span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                                                                          </span></span></span></span></span></span></span></span></span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <ul>
                                                                                                                                                                                                                                                                                          <li
                                                                                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">You
                                                                                                                                                                                                                                                                                                                          may
                                                                                                                                                                                                                                                                                                                          request
                                                                                                                                                                                                                                                                                                                          correction
                                                                                                                                                                                                                                                                                                                          of
                                                                                                                                                                                                                                                                                                                          your
                                                                                                                                                                                                                                                                                                                          personal
                                                                                                                                                                                                                                                                                                                          data
                                                                                                                                                                                                                                                                                                                          if
                                                                                                                                                                                                                                                                                                                          it
                                                                                                                                                                                                                                                                                                                          is
                                                                                                                                                                                                                                                                                                                          incorrect
                                                                                                                                                                                                                                                                                                                          or
                                                                                                                                                                                                                                                                                                                          no
                                                                                                                                                                                                                                                                                                                          longer
                                                                                                                                                                                                                                                                                                                          relevant,
                                                                                                                                                                                                                                                                                                                          or
                                                                                                                                                                                                                                                                                                                          ask
                                                                                                                                                                                                                                                                                                                          to
                                                                                                                                                                                                                                                                                                                          restrict
                                                                                                                                                                                                                                                                                                                          the
                                                                                                                                                                                                                                                                                                                          processing
                                                                                                                                                                                                                                                                                                                          of
                                                                                                                                                                                                                                                                                                                          the
                                                                                                                                                                                                                                                                                                                          information.<span
                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                          style="color: rgb(0, 0, 0);"><span
                                                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                                                  style="color: rgb(0, 0, 0);"><span
                                                                                                                                                                                                                                                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                                                                                          </span></span></span></span></span></span></span></span></span></span></span></span>
                                                                                                                                                                                                                                                                                          </li>
                                                                                                                                                                                                                                                                                  </ul>
                                                                                                                                                                                                                                                                                  <div><span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                                                                          </span></span></span></span></span></span></span></span></span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <ul>
                                                                                                                                                                                                                                                                                          <li
                                                                                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">You
                                                                                                                                                                                                                                                                                                                          can
                                                                                                                                                                                                                                                                                                                          designate
                                                                                                                                                                                                                                                                                                                          an
                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                                          authorized
                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                                          agent
                                                                                                                                                                                                                                                                                                                          to
                                                                                                                                                                                                                                                                                                                          make
                                                                                                                                                                                                                                                                                                                          a
                                                                                                                                                                                                                                                                                                                          request
                                                                                                                                                                                                                                                                                                                          under
                                                                                                                                                                                                                                                                                                                          the
                                                                                                                                                                                                                                                                                                                          CCPA
                                                                                                                                                                                                                                                                                                                          on
                                                                                                                                                                                                                                                                                                                          your
                                                                                                                                                                                                                                                                                                                          behalf.
                                                                                                                                                                                                                                                                                                                          We
                                                                                                                                                                                                                                                                                                                          may
                                                                                                                                                                                                                                                                                                                          deny
                                                                                                                                                                                                                                                                                                                          a
                                                                                                                                                                                                                                                                                                                          request
                                                                                                                                                                                                                                                                                                                          from
                                                                                                                                                                                                                                                                                                                          an
                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                                          authorized
                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                                          agent
                                                                                                                                                                                                                                                                                                                          that
                                                                                                                                                                                                                                                                                                                          does
                                                                                                                                                                                                                                                                                                                          not
                                                                                                                                                                                                                                                                                                                          submit
                                                                                                                                                                                                                                                                                                                          proof
                                                                                                                                                                                                                                                                                                                          that
                                                                                                                                                                                                                                                                                                                          they
                                                                                                                                                                                                                                                                                                                          have
                                                                                                                                                                                                                                                                                                                          been
                                                                                                                                                                                                                                                                                                                          validly
                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                                          authorized
                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                                          to
                                                                                                                                                                                                                                                                                                                          act
                                                                                                                                                                                                                                                                                                                          on
                                                                                                                                                                                                                                                                                                                          your
                                                                                                                                                                                                                                                                                                                          behalf
                                                                                                                                                                                                                                                                                                                          in
                                                                                                                                                                                                                                                                                                                          accordance
                                                                                                                                                                                                                                                                                                                          with
                                                                                                                                                                                                                                                                                                                          the
                                                                                                                                                                                                                                                                                                                          CCPA.<span
                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                          style="color: rgb(0, 0, 0);"><span
                                                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                                                  style="color: rgb(0, 0, 0);"><span
                                                                                                                                                                                                                                                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                                                                                          </span></span></span></span></span></span></span></span></span></span></span></span>
                                                                                                                                                                                                                                                                                          </li>
                                                                                                                                                                                                                                                                                  </ul>
                                                                                                                                                                                                                                                                                  <div><span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                                                                          </span></span></span></span></span></span></span></span></span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <ul>
                                                                                                                                                                                                                                                                                          <li
                                                                                                                                                                                                                                                                                                  style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">You
                                                                                                                                                                                                                                                                                                                          may
                                                                                                                                                                                                                                                                                                                          request
                                                                                                                                                                                                                                                                                                                          to
                                                                                                                                                                                                                                                                                                                          opt
                                                                                                                                                                                                                                                                                                                          out
                                                                                                                                                                                                                                                                                                                          from
                                                                                                                                                                                                                                                                                                                          future
                                                                                                                                                                                                                                                                                                                          selling
                                                                                                                                                                                                                                                                                                                          or
                                                                                                                                                                                                                                                                                                                          sharing
                                                                                                                                                                                                                                                                                                                          of
                                                                                                                                                                                                                                                                                                                          your
                                                                                                                                                                                                                                                                                                                          personal
                                                                                                                                                                                                                                                                                                                          information
                                                                                                                                                                                                                                                                                                                          to
                                                                                                                                                                                                                                                                                                                          third
                                                                                                                                                                                                                                                                                                                          parties.
                                                                                                                                                                                                                                                                                                                          Upon
                                                                                                                                                                                                                                                                                                                          receiving
                                                                                                                                                                                                                                                                                                                          an
                                                                                                                                                                                                                                                                                                                          opt-out
                                                                                                                                                                                                                                                                                                                          request,
                                                                                                                                                                                                                                                                                                                          we
                                                                                                                                                                                                                                                                                                                          will
                                                                                                                                                                                                                                                                                                                          act
                                                                                                                                                                                                                                                                                                                          upon
                                                                                                                                                                                                                                                                                                                          the
                                                                                                                                                                                                                                                                                                                          request
                                                                                                                                                                                                                                                                                                                          as
                                                                                                                                                                                                                                                                                                                          soon
                                                                                                                                                                                                                                                                                                                          as
                                                                                                                                                                                                                                                                                                                          feasibly
                                                                                                                                                                                                                                                                                                                          possible,
                                                                                                                                                                                                                                                                                                                          but
                                                                                                                                                                                                                                                                                                                          no
                                                                                                                                                                                                                                                                                                                          later
                                                                                                                                                                                                                                                                                                                          than
                                                                                                                                                                                                                                                                                                                          fifteen
                                                                                                                                                                                                                                                                                                                          (15)
                                                                                                                                                                                                                                                                                                                          days
                                                                                                                                                                                                                                                                                                                          from
                                                                                                                                                                                                                                                                                                                          the
                                                                                                                                                                                                                                                                                                                          date
                                                                                                                                                                                                                                                                                                                          of
                                                                                                                                                                                                                                                                                                                          the
                                                                                                                                                                                                                                                                                                                          request
                                                                                                                                                                                                                                                                                                                          submission.<span
                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                          style="color: rgb(0, 0, 0);"><span
                                                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                                                  style="color: rgb(0, 0, 0);"><span
                                                                                                                                                                                                                                                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                                                                                          </span></span></span></span></span></span></span></span></span></span></span></span>
                                                                                                                                                                                                                                                                                          </li>
                                                                                                                                                                                                                                                                                  </ul>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px;">
                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                          </span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">To
                                                                                                                                                                                                                                                                                                                  exercise
                                                                                                                                                                                                                                                                                                                  these
                                                                                                                                                                                                                                                                                                                  rights,
                                                                                                                                                                                                                                                                                                                  you
                                                                                                                                                                                                                                                                                                                  can
                                                                                                                                                                                                                                                                                                                  contact
                                                                                                                                                                                                                                                                                                                  us
                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                  by
                                                                                                                                                                                                                                                                                                                  visiting
                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                          class="question">
                                                                                                                                                                                                                                                                                                                          <a href="https://bookboos.com/account"
                                                                                                                                                                                                                                                                                                                                  target="_blank"
                                                                                                                                                                                                                                                                                                                                  data-custom-class="link">https://bookboos.com/account</a>
                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                  ,
                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                          class="else-block">
                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                  </span><span
                                                                                                                                                                                                                                                                                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                                                          by
                                                                                                                                                                                                                                                                                                                                          email
                                                                                                                                                                                                                                                                                                                                          at
                                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                                  class="question">
                                                                                                                                                                                                                                                                                                                                                  hello@bookboos.com
                                                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                                                          ,
                                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                                                  </span><span
                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                                                  </span></span></span></span></span></span></span></span></span></span></span></span><span
                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">or
                                                                                                                                                                                                                                                                                                  by
                                                                                                                                                                                                                                                                                                  referring
                                                                                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                                                                                  contact
                                                                                                                                                                                                                                                                                                  details
                                                                                                                                                                                                                                                                                                  at
                                                                                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                                                                                  bottom
                                                                                                                                                                                                                                                                                                  of
                                                                                                                                                                                                                                                                                                  this
                                                                                                                                                                                                                                                                                                  document.
                                                                                                                                                                                                                                                                                                  If
                                                                                                                                                                                                                                                                                                  you
                                                                                                                                                                                                                                                                                                  have
                                                                                                                                                                                                                                                                                                  a
                                                                                                                                                                                                                                                                                                  complaint
                                                                                                                                                                                                                                                                                                  about
                                                                                                                                                                                                                                                                                                  how
                                                                                                                                                                                                                                                                                                  we
                                                                                                                                                                                                                                                                                                  handle
                                                                                                                                                                                                                                                                                                  your
                                                                                                                                                                                                                                                                                                  data,
                                                                                                                                                                                                                                                                                                  we
                                                                                                                                                                                                                                                                                                  would
                                                                                                                                                                                                                                                                                                  like
                                                                                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                                                                                  hear
                                                                                                                                                                                                                                                                                                  from
                                                                                                                                                                                                                                                                                                  you.</span><span
                                                                                                                                                                                                                                                                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                                                  </span>
                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                  </span></span></span></span></span></span></span></span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px;">
                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                          </span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px;"></span>
                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px;">
                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                                                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                                                                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                                                                                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                                                                                                                                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                  </span></span></span></span></span></span></span></span></span></span></span>
                                                                                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                                                                                  </span></span></span></span></span></span></span></span></span></span>
                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px;">
                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                          </span>
                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px;"></span>
                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <br>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div id="policyupdates"
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="color: rgb(127, 127, 127);"><span
                                                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                  id="control"
                                                                                                                                                                                                                                                                                                                                  style="color: rgb(0, 0, 0);"><strong><span
                                                                                                                                                                                                                                                                                                                                                  data-custom-class="heading_1">12.
                                                                                                                                                                                                                                                                                                                                                  DO
                                                                                                                                                                                                                                                                                                                                                  WE
                                                                                                                                                                                                                                                                                                                                                  MAKE
                                                                                                                                                                                                                                                                                                                                                  UPDATES
                                                                                                                                                                                                                                                                                                                                                  TO
                                                                                                                                                                                                                                                                                                                                                  THIS
                                                                                                                                                                                                                                                                                                                                                  NOTICE?</span></strong></span></span></span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <br>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><em><strong>In
                                                                                                                                                                                                                                                                                                                                  Short: </strong>Yes,
                                                                                                                                                                                                                                                                                                                          we
                                                                                                                                                                                                                                                                                                                          will
                                                                                                                                                                                                                                                                                                                          update
                                                                                                                                                                                                                                                                                                                          this
                                                                                                                                                                                                                                                                                                                          notice
                                                                                                                                                                                                                                                                                                                          as
                                                                                                                                                                                                                                                                                                                          necessary
                                                                                                                                                                                                                                                                                                                          to
                                                                                                                                                                                                                                                                                                                          stay
                                                                                                                                                                                                                                                                                                                          compliant
                                                                                                                                                                                                                                                                                                                          with
                                                                                                                                                                                                                                                                                                                          relevant
                                                                                                                                                                                                                                                                                                                          laws.</em></span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <br>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">We
                                                                                                                                                                                                                                                                                                                  may
                                                                                                                                                                                                                                                                                                                  update
                                                                                                                                                                                                                                                                                                                  this
                                                                                                                                                                                                                                                                                                                  privacy
                                                                                                                                                                                                                                                                                                                  notice
                                                                                                                                                                                                                                                                                                                  from
                                                                                                                                                                                                                                                                                                                  time
                                                                                                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                                                                                                  time.
                                                                                                                                                                                                                                                                                                                  The
                                                                                                                                                                                                                                                                                                                  updated
                                                                                                                                                                                                                                                                                                                  version
                                                                                                                                                                                                                                                                                                                  will
                                                                                                                                                                                                                                                                                                                  be
                                                                                                                                                                                                                                                                                                                  indicated
                                                                                                                                                                                                                                                                                                                  by
                                                                                                                                                                                                                                                                                                                  an
                                                                                                                                                                                                                                                                                                                  updated
                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                  "Revised"
                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                  date
                                                                                                                                                                                                                                                                                                                  and
                                                                                                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                                                                                                  updated
                                                                                                                                                                                                                                                                                                                  version
                                                                                                                                                                                                                                                                                                                  will
                                                                                                                                                                                                                                                                                                                  be
                                                                                                                                                                                                                                                                                                                  effective
                                                                                                                                                                                                                                                                                                                  as
                                                                                                                                                                                                                                                                                                                  soon
                                                                                                                                                                                                                                                                                                                  as
                                                                                                                                                                                                                                                                                                                  it
                                                                                                                                                                                                                                                                                                                  is
                                                                                                                                                                                                                                                                                                                  accessible.
                                                                                                                                                                                                                                                                                                                  If
                                                                                                                                                                                                                                                                                                                  we
                                                                                                                                                                                                                                                                                                                  make
                                                                                                                                                                                                                                                                                                                  material
                                                                                                                                                                                                                                                                                                                  changes
                                                                                                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                                                                                                  this
                                                                                                                                                                                                                                                                                                                  privacy
                                                                                                                                                                                                                                                                                                                  notice,
                                                                                                                                                                                                                                                                                                                  we
                                                                                                                                                                                                                                                                                                                  may
                                                                                                                                                                                                                                                                                                                  notify
                                                                                                                                                                                                                                                                                                                  you
                                                                                                                                                                                                                                                                                                                  either
                                                                                                                                                                                                                                                                                                                  by
                                                                                                                                                                                                                                                                                                                  prominently
                                                                                                                                                                                                                                                                                                                  posting
                                                                                                                                                                                                                                                                                                                  a
                                                                                                                                                                                                                                                                                                                  notice
                                                                                                                                                                                                                                                                                                                  of
                                                                                                                                                                                                                                                                                                                  such
                                                                                                                                                                                                                                                                                                                  changes
                                                                                                                                                                                                                                                                                                                  or
                                                                                                                                                                                                                                                                                                                  by
                                                                                                                                                                                                                                                                                                                  directly
                                                                                                                                                                                                                                                                                                                  sending
                                                                                                                                                                                                                                                                                                                  you
                                                                                                                                                                                                                                                                                                                  a
                                                                                                                                                                                                                                                                                                                  notification.
                                                                                                                                                                                                                                                                                                                  We
                                                                                                                                                                                                                                                                                                                  encourage
                                                                                                                                                                                                                                                                                                                  you
                                                                                                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                                                                                                  review
                                                                                                                                                                                                                                                                                                                  this
                                                                                                                                                                                                                                                                                                                  privacy
                                                                                                                                                                                                                                                                                                                  notice
                                                                                                                                                                                                                                                                                                                  frequently
                                                                                                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                                                                                                  be
                                                                                                                                                                                                                                                                                                                  informed
                                                                                                                                                                                                                                                                                                                  of
                                                                                                                                                                                                                                                                                                                  how
                                                                                                                                                                                                                                                                                                                  we
                                                                                                                                                                                                                                                                                                                  are
                                                                                                                                                                                                                                                                                                                  protecting
                                                                                                                                                                                                                                                                                                                  your
                                                                                                                                                                                                                                                                                                                  information.</span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <br>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div id="contact"
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="color: rgb(127, 127, 127);"><span
                                                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                  id="control"
                                                                                                                                                                                                                                                                                                                                  style="color: rgb(0, 0, 0);"><strong><span
                                                                                                                                                                                                                                                                                                                                                  data-custom-class="heading_1">13.
                                                                                                                                                                                                                                                                                                                                                  HOW
                                                                                                                                                                                                                                                                                                                                                  CAN
                                                                                                                                                                                                                                                                                                                                                  YOU
                                                                                                                                                                                                                                                                                                                                                  CONTACT
                                                                                                                                                                                                                                                                                                                                                  US
                                                                                                                                                                                                                                                                                                                                                  ABOUT
                                                                                                                                                                                                                                                                                                                                                  THIS
                                                                                                                                                                                                                                                                                                                                                  NOTICE?</span></strong></span></span></span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <br>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">If
                                                                                                                                                                                                                                                                                                                  you
                                                                                                                                                                                                                                                                                                                  have
                                                                                                                                                                                                                                                                                                                  questions
                                                                                                                                                                                                                                                                                                                  or
                                                                                                                                                                                                                                                                                                                  comments
                                                                                                                                                                                                                                                                                                                  about
                                                                                                                                                                                                                                                                                                                  this
                                                                                                                                                                                                                                                                                                                  notice,
                                                                                                                                                                                                                                                                                                                  you
                                                                                                                                                                                                                                                                                                                  may
                                                                                                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                                  email
                                                                                                                                                                                                                                                                                                                                  us
                                                                                                                                                                                                                                                                                                                                  at
                                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                                          class="question">
                                                                                                                                                                                                                                                                                                                                          hello@bookboos.com
                                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                                          class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                          </span></span><span
                                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"></span></span></span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <br>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                  style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                          </span></span></span></span></span><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                          </span></span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                                  style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                          style="font-size: 15px;">
                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                                  </span></span>
                                                                                                                                                                                                                                                                                                  </span>
                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                  </span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                                  style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                          style="font-size: 15px;">
                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                                  </span></span>
                                                                                                                                                                                                                                                                                                  </span><span
                                                                                                                                                                                                                                                                                                          data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                  style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                          style="font-size: 15px;">
                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                                  </span></span></span>
                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                  </span></span></span></span><span
                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89);">
                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                                                  </span></span></span>
                                                                                                                                                                                                                                                                                                                  </span></span></span>
                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                                  style="font-size: 15px;"></span>
                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text"><span
                                                                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                  style="font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                                  class="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                                  </span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <br>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div id="request"
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="color: rgb(127, 127, 127);"><span
                                                                                                                                                                                                                                                                                                          style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                                  id="control"
                                                                                                                                                                                                                                                                                                                                  style="color: rgb(0, 0, 0);"><strong><span
                                                                                                                                                                                                                                                                                                                                                  data-custom-class="heading_1">14.
                                                                                                                                                                                                                                                                                                                                                  HOW
                                                                                                                                                                                                                                                                                                                                                  CAN
                                                                                                                                                                                                                                                                                                                                                  YOU
                                                                                                                                                                                                                                                                                                                                                  REVIEW,
                                                                                                                                                                                                                                                                                                                                                  UPDATE,
                                                                                                                                                                                                                                                                                                                                                  OR
                                                                                                                                                                                                                                                                                                                                                  DELETE
                                                                                                                                                                                                                                                                                                                                                  THE
                                                                                                                                                                                                                                                                                                                                                  DATA
                                                                                                                                                                                                                                                                                                                                                  WE
                                                                                                                                                                                                                                                                                                                                                  COLLECT
                                                                                                                                                                                                                                                                                                                                                  FROM
                                                                                                                                                                                                                                                                                                                                                  YOU?</span></strong></span></span></span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <br>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                          style="line-height: 1.5;">
                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">
                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                          class="block-component">
                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                  Based
                                                                                                                                                                                                                                                                                                                  on
                                                                                                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                                                                                                  applicable
                                                                                                                                                                                                                                                                                                                  laws
                                                                                                                                                                                                                                                                                                                  of
                                                                                                                                                                                                                                                                                                                  your
                                                                                                                                                                                                                                                                                                                  country,
                                                                                                                                                                                                                                                                                                                  you
                                                                                                                                                                                                                                                                                                                  may
                                                                                                                                                                                                                                                                                                                  have
                                                                                                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                                                                                                  right
                                                                                                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                                                                                                  request
                                                                                                                                                                                                                                                                                                                  access
                                                                                                                                                                                                                                                                                                                  to
                                                                                                                                                                                                                                                                                                                  the
                                                                                                                                                                                                                                                                                                                  personal
                                                                                                                                                                                                                                                                                                                  information
                                                                                                                                                                                                                                                                                                                  we
                                                                                                                                                                                                                                                                                                                  collect
                                                                                                                                                                                                                                                                                                                  from
                                                                                                                                                                                                                                                                                                                  you,
                                                                                                                                                                                                                                                                                                                  change
                                                                                                                                                                                                                                                                                                                  that
                                                                                                                                                                                                                                                                                                                  information,
                                                                                                                                                                                                                                                                                                                  or
                                                                                                                                                                                                                                                                                                                  delete
                                                                                                                                                                                                                                                                                                                  it.
                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                          class="else-block">
                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                                          To
                                                                                                                                                                                                                                                                                                                          request
                                                                                                                                                                                                                                                                                                                          to
                                                                                                                                                                                                                                                                                                                          review,
                                                                                                                                                                                                                                                                                                                          update,
                                                                                                                                                                                                                                                                                                                          or
                                                                                                                                                                                                                                                                                                                          delete
                                                                                                                                                                                                                                                                                                                          your
                                                                                                                                                                                                                                                                                                                          personal
                                                                                                                                                                                                                                                                                                                          information,
                                                                                                                                                                                                                                                                                                                          please
                                                                                                                                                                                                                                                                                                                          <bdt
                                                                                                                                                                                                                                                                                                                                  class="block-component">
                                                                                                                                                                                                                                                                                                          </span>
                                                                                                                                                                                                                                                                                                          </bdt>
                                                                                                                                                                                                                                                                                                          <span
                                                                                                                                                                                                                                                                                                                  data-custom-class="body_text">visit:
                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                          class="question">
                                                                                                                                                                                                                                                                                                                          <a href="https://bookboos.com/account"
                                                                                                                                                                                                                                                                                                                                  target="_blank"
                                                                                                                                                                                                                                                                                                                                  data-custom-class="link">https://bookboos.com/account</a>
                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                                  <bdt
                                                                                                                                                                                                                                                                                                                          class="else-block">
                                                                                                                                                                                                                                                                                                                  </bdt>
                                                                                                                                                                                                                                                                                                          </span></span><span
                                                                                                                                                                                                                                                                                                          data-custom-class="body_text">.</span></span></span>
                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                  <style>
                                                                                                                                                                                                                                                                                          ul {
                                                                                                                                                                                                                                                                                                  list-style-type: square;
                                                                                                                                                                                                                                                                                          }

                                                                                                                                                                                                                                                                                          ul>li>ul {
                                                                                                                                                                                                                                                                                                  list-style-type: circle;
                                                                                                                                                                                                                                                                                          }

                                                                                                                                                                                                                                                                                          ul>li>ul>li>ul {
                                                                                                                                                                                                                                                                                                  list-style-type: square;
                                                                                                                                                                                                                                                                                          }

                                                                                                                                                                                                                                                                                          ol li {
                                                                                                                                                                                                                                                                                                  font-family: Arial;
                                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                                  </style>
                                                                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                                                                  style="color: #595959;font-size: 14px;font-family: Arial;padding-top:16px;">
                                                                                                                                                                                                                                                                                  This
                                                                                                                                                                                                                                                                                  privacy
                                                                                                                                                                                                                                                                                  policy
                                                                                                                                                                                                                                                                                  was
                                                                                                                                                                                                                                                                                  created
                                                                                                                                                                                                                                                                                  using
                                                                                                                                                                                                                                                                                  Termly's
                                                                                                                                                                                                                                                                                  <a style="color: rgb(48, 48, 241) !important;"
                                                                                                                                                                                                                                                                                          href="https://termly.io/products/privacy-policy-generator/">Privacy
                                                                                                                                                                                                                                                                                          Policy
                                                                                                                                                                                                                                                                                          Generator</a>.
                                                                                                                                                                                                                                                                          </div>
  `,
                                }}
                        />
                </Wrapper>
        );
};