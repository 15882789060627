import styled from "styled-components";
import axios from 'axios'

import { colors } from "utils/colors";
import { SafeArea } from "capacitor-plugin-safe-area";
import { Capacitor } from "@capacitor/core";
import { createContext, useContext, useState, useEffect } from "react";
import { useMutation } from "urql";
import { RefreshAuthMutation } from "queries/auth";
import { useNavigate } from "react-router-dom";

import { TopBar } from "./topbar";
import { Loader } from "./loader";
import { HTTPHost } from "components/api"
import { SecureStoragePlugin } from "capacitor-secure-storage-plugin";

const Wrapper = styled.div.attrs(({ $backgroundColor }) => ({
  $backgroundColor
}))`
  width: 100%;
  height: 100vh;
  position: absolute;
  color: ${colors.blue};
  overflow: auto;
  font-size: 1rem;
  background-color: ${({ $backgroundColor }) => $backgroundColor || 'unset'};
`;

const Content = styled.div.attrs(({ $top, $bottom, $allowScroll }) => ({
  $top,
  $bottom,
  $allowScroll
}))`
  position: relative;
  width: 100%;
  height: calc(100vh - 50px - ${({ $bottom }) => $bottom}px);
  overflow: ${({ $allowScroll }) => $allowScroll ? 'auto' : 'hidden'};
`;

export let _accessToken
let attempted

export const AppContext = createContext({})

export const useAppContext = () => useContext(AppContext)

export const AppProvider = (props) => {
  const { children } = props;
  const [username, setUsername] = useState(false)
  const [accessToken, setAccessToken] = useState(false);
  const [safearea, setSafearea] = useState({ top: 0, bottom: 0 });
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  const [topAreaColor, setTopAreaColor] = useState(colors.transparent);
  const [bottomAreaColor, setBottomAreaColor] = useState(colors.blue);
  const navigate = useNavigate()

  const logout = () => {
    SecureStoragePlugin.clear()
    setUserIsLoggedIn(false)
    setAccessToken(false)
    _accessToken = false
    navigate('/home')
  }

  useEffect(() => {
    SafeArea.getSafeAreaInsets().then(({ insets }) => {
      if (Capacitor.getPlatform() === "ios") {
        setSafearea(insets);
      }
    });
  }, []);

  useEffect(() => {
    if (!accessToken) return
    _accessToken = accessToken
    setUserIsLoggedIn(true)
  }, [accessToken])

  return <AppContext.Provider value={{
    safearea,
    setAccessToken,
    username, setUsername,
    userIsLoggedIn, setUserIsLoggedIn,
    topAreaColor, setTopAreaColor,
    bottomAreaColor, setBottomAreaColor,
    logout
  }}>
    {children}
  </AppContext.Provider>
}

export const Page = ({ children, loadingText, requireSignIn, backgroundColor, hasUpdates, refetchHasNewNotifs, allowScroll }) => {
  const { safearea, setAccessToken, setUsername } = useAppContext();
  const [, refreshAuth] = useMutation(RefreshAuthMutation);
  const navigate = useNavigate()

  useEffect(() => {
    if (_accessToken) return
    SecureStoragePlugin.get({ key: 'refreshToken' }).then((result) => {
      if (attempted) return
      attempted = true
      refreshAuth({ input: { refreshToken: result.value } }).then(resp => {
        if (resp.error && requireSignIn) {
          navigate('/login')
          return
        }
        if (!resp.data?.refreshAuth?.refreshToken) return
        setUsername(resp.data?.refreshAuth?.username)
        SecureStoragePlugin.set({ key: 'refreshToken', value: resp.data?.refreshAuth?.refreshToken })
        setAccessToken(resp.data?.refreshAuth?.accessToken)
      })
    }).catch(err => { })
  }, [])

  return (
    <Wrapper className="cmp-page" $backgroundColor={backgroundColor}>
      {loadingText ? <Loader loadingText={loadingText} /> : ""}
      <TopBar hasUpdates={hasUpdates} refetchHasNewNotifs={refetchHasNewNotifs} />
      <Content $top={safearea.top} $bottom={safearea.bottom} $allowScroll={allowScroll}>
        {children}
      </Content>
    </Wrapper>
  );
};

export const UploadFile = ({ path, name, binary }) => {
  const formData = new FormData();
  formData.append(name, new File([binary], name))
  return axios.post(`${HTTPHost}/${path}`, formData, {
    headers: {
      'Authorization': _accessToken,
      'content-type': 'multipart/form-data'
    }
  })
}