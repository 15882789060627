import { useState, useEffect } from "react";
import { useQuery } from "urql";
import intl from "react-intl-universal";
import styled from "styled-components";

import { colors } from "utils/colors";
import { AutocompleteBooksQuery, ListBooksQuery } from 'queries/book';

import { Page, useAppContext } from "components/page";
import { ApiProvider } from "components/api";
import { Caption, Label } from "components/label";
import { Container, Row, Col } from 'react-grid-system';
import { Input } from "components/input";
import { BookDetails } from "components/book/search";
import { Modal } from "components/modal";
import { Story } from "components/story";

import PfpPlaceholderImg from 'assets/imgs/pfp-placeholder.jpeg'
import { GetFriendsUpdatesQuery } from "queries/person";
import { HasNewNotificationsQuery } from "queries/notification";
import { MyBook } from "components/book/my";

const Content = styled.div`
  max-width: 1200px;
  width: 100%;
  text-align: center;
  margin: auto;
`

const BG = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${colors.lightgrey};
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
`

const UpdatesWrapper = styled.div.attrs(({ $top, $bottom }) => ({
  $top, $bottom
}))`
  position: fixed;
  top: calc(50px + ${({ $top }) => $top}px);
  z-index: 2;
  background-color: ${colors.white};

  left: 0;
  white-space: nowrap;
  width: 100%;
  overflow: auto;
  display: inline-flex;
  height: calc(50px + 2em);
  padding: 0.5em 0;

  @media (min-width: 600px) {
      width: 100px;
      left: unset;
      right: 0;
      display: block;
      padding-top: 1em;
      padding-bottom: 1em;
      height: calc(100vh - 50px - 2em - ${({ $top, $bottom }) => $top + $bottom}px);
  }
`

const UpdateWrapper = styled.div`
  margin: 0.5em;
  cursor: pointer;
  transition: opacity 0.2s;
  width: fit-content;

  &:hover {
    opacity: 0.9;
  }
`

const Pfp = styled.img.attrs(({ $hasUpdates }) => ({
  $hasUpdates
}))`
  border-radius: 50px;
  width: 50px;
  height: 50px;
  outline: ${({ $hasUpdates }) => $hasUpdates ? `solid 5px ${colors.cyan}` : 'none'};
  outline-style: double;
`

const Username = styled.div`
  text-align: center;
  font-size: 14px;
  margin-top: 5px;
  width: 80px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const HeaderWrapper = styled.div`
  padding: 2em;
  margin-bottom: 1em;
`

const Spacer = styled.div`
  height: 0.5em;
  width: 100%;
`

const FeedWrapper = styled.div`
`

const ViewWrapper = styled.div.attrs(({ $top, $bottom }) => ({
  $top, $bottom
}))`
  overflow: auto;
  @media (min-width: 600px) {
    width: calc(100% - 100px);
    height: calc(100vh - 50px - ${({ $top }) => $top}px);
  }
  @media (max-width: 600px) {
    height: calc(100vh - 150px - ${({ $top, $bottom }) => $top + $bottom}px);
    margin-top: calc(100px + ${({ $top }) => $top}px);
  }
`

const NoFriendsWrapper = styled.div`
  white-space: normal;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  padding: 1em;
`

export const MyBooksPage = () => {
  const { safearea, userIsLoggedIn, username } = useAppContext();
  const [modal, setModal] = useState();
  const [story, setStory] = useState(false);
  const [books, setBooks] = useState([])

  const [keywords, setKeywords] = useState("");
  const [searchResult, refetchSearch] = useQuery({
    query: AutocompleteBooksQuery,
    variables: {
      input: {
        keywords
      }
    },
    pause: keywords === ''
  });

  const [cursor, setCursor] = useState(0)
  const [booksResult, refetchBooks] = useQuery({
    query: ListBooksQuery,
    variables: {
      input: {
        cursor
      }
    },
    pause: !userIsLoggedIn,
    requestPolicy: 'cache-and-network',
  });
  const newBooks = booksResult?.data?.listBooks?.books
  const hasMore = booksResult?.data?.listBooks?.has_more

  useEffect(() => {
    if (!newBooks || newBooks?.length === 0) return
    setBooks([...books, ...newBooks])
  }, [newBooks])

  const [friendIdx, setFriendIdx] = useState(-1)
  const [friendsCursor, setFriendsCursor] = useState(0)
  const [friendsResult, refetchFriends] = useQuery({
    query: GetFriendsUpdatesQuery,
    variables: {
      input: {
        cursor: friendsCursor
      }
    },
    pause: !userIsLoggedIn,
    requestPolicy: 'cache-and-network',
  });
  const friends = friendsResult?.data?.topFriends?.users

  const [hasNewNotifs, refetchHasNewNotifs] = useQuery({
    query: HasNewNotificationsQuery,
    variables: {
      input: {}
    },
    pause: !userIsLoggedIn,
    requestPolicy: 'cache-and-network',
  });
  const hasUpdates = hasNewNotifs?.data?.hasNewNotifications?.ok

  useEffect(() => {
    if (username) {
      refetchFriends()
    }
  }, [username])

  useEffect(() => {
    if (friendIdx < 0) return
    setStory({ friend: friends[friendIdx], lastFriend: friendIdx === friends.length - 1 })
  }, [friendIdx])

  return (
    <ApiProvider>
      <Page hasUpdates={hasUpdates} refetchHasNewNotifs={refetchHasNewNotifs}>
        {modal ? <Modal onClose={() => { setModal(false); }}>
          <BookDetails book_id={modal} onAction={() => {
            refetchBooks()
          }} />
        </Modal> : ''}
        {story ? <Story {...story}
          onClose={() => setStory(false)}
          onNext={() => {
            setFriendIdx(Math.min(friendIdx + 1, friends.length - 1))
          }}
          onPrev={() => {
            setFriendIdx(Math.max(friendIdx - 1, 0))
          }}
          lastFriend={friendIdx === friends.length - 1}
        /> : ''}
        <BG />
        <Content>
          <UpdatesWrapper $top={safearea.top} $bottom={safearea.bottom}>
            {friends?.length > 0 ? friends?.map((friend, i) => {
              return <UpdateWrapper key={i} onClick={() => {
                setStory({ friend })
                setFriendIdx(i)
              }}>
                <Pfp $hasUpdates={friend.has_updates} src={friend.pfp_url || PfpPlaceholderImg} />
                <Username>{friend.username}</Username>
              </UpdateWrapper>
            }) : <NoFriendsWrapper>
              {intl.get('labels.noFriendsYet')}
            </NoFriendsWrapper>}
          </UpdatesWrapper>
          <ViewWrapper $top={safearea.top} $bottom={safearea.bottom} onScroll={(e) => {
            if (e.target.scrollTop + 100 > e.target.scrollHeight - e.target.clientHeight && hasMore) {
              setCursor(books.length)
            }
          }}>
            <HeaderWrapper>
              <Container>
                <Row>
                  <Col sm={4}>
                    <Label>{intl.get('mybooks.title')}</Label>
                    <Spacer />
                    <Caption>{intl.get('mybooks.caption')}</Caption>
                  </Col>
                  <Col sm={8}>
                    <Input debounce placeholder={intl.get('feed.searchBook')} onChange={(e, v) => {
                      setKeywords(v)
                    }} autocompleteList={searchResult?.data?.searchBooks?.books.map((book, i) => {
                      return {
                        id: book.book_id,
                        img_url: book.img_url,
                        text: `${book.title}${book.authors ? ` - ${book.authors.join(', ')}` : ''}`
                      }
                    })} onAutocomplete={(id, i) => {
                      setModal(id)
                    }} />
                  </Col>
                </Row>
              </Container>
            </HeaderWrapper>
            <FeedWrapper>
              {books?.map((item, i) => {
                return <MyBook key={i} refetch={refetchBooks} {...item.book} {...item} />
              })}
            </FeedWrapper>
          </ViewWrapper>
        </Content>
      </Page>
    </ApiProvider>
  );
};
