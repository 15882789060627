import { useEffect, useState } from "react";
import { useMutation, useQuery } from "urql";
import intl from "react-intl-universal";
import styled from "styled-components";

import { colors } from "utils/colors";

import { AddPersonMutation, BlockPersonMutation, GetPersonQuery, UnaddPersonMutation, UnblockPersonMutation, UnfriendPersonMutation } from "queries/person";

import PfpPlaceholderImg from 'assets/imgs/pfp-placeholder.jpeg'
import { shortenCount } from "utils/common";
import { SecondaryButton } from "components/buttons";
import { useAppContext } from "./page";

const ModalContent = styled.div`
    padding: 2em;
    text-align: center;
`

const Buttons = styled.div`
    & * {
        margin: 0.5em;
    }
`

const Pfp = styled.img`
    width: 160px;
    height: 160px;
    max-width: 80vw;
    max-height: 80vw;
    border-radius: 80vw;
    margin: 1em;
    -webkit-box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.25);
`

const Username = styled.div`
    font-size: 20px;
    text-align: center;
`

export const PersonModal = ({ username, onAction }) => {
    const { userIsLoggedIn } = useAppContext();
    const [, addPerson] = useMutation(AddPersonMutation)
    const [, unaddPerson] = useMutation(UnaddPersonMutation)
    const [, unfriendPerson] = useMutation(UnfriendPersonMutation)
    const [, blockPerson] = useMutation(BlockPersonMutation)
    const [, unblockPerson] = useMutation(UnblockPersonMutation)

    const [userResult, refetchUser] = useQuery({
        query: GetPersonQuery,
        variables: {
            input: {
                username
            }
        },
        pause: !userIsLoggedIn,
        requestPolicy: 'cache-and-network',
    });
    const user = userResult?.data?.getPerson?.person

    return <ModalContent>
        <Pfp src={user?.pfp_url || PfpPlaceholderImg} />
        <Username>{username} • {shortenCount(user?.friends_count || 0)} {intl.get('labels.friends')}</Username>
        <Buttons>
            {<SecondaryButton onClick={() => {
                if (user?.connected) {
                    unfriendPerson({ input: { username } }).then((resp) => {
                        if (resp.error) {
                            // TODO show toast
                            console.log('failed', resp.error)
                            return
                        }
                        refetchUser()
                        if (onAction) onAction('unfriend')
                    })
                } else if (user?.added) {
                    unaddPerson({ input: { username } }).then((resp) => {
                        if (resp.error) {
                            // TODO show toast
                            console.log('failed', resp.error)
                            return
                        }
                        refetchUser()
                        if (onAction) onAction('unadd')
                    })
                } else {
                    addPerson({ input: { username } }).then((resp) => {
                        if (resp.error) {
                            // TODO show toast
                            console.log('failed', resp.error)
                            return
                        }
                        refetchUser()
                        if (onAction) onAction('add')
                    })
                }
            }}>{user?.connected ? intl.get('profile.unfriend') : user?.added ? intl.get('profile.unfollow') : intl.get('profile.follow')}</SecondaryButton>}
            {<SecondaryButton onClick={() => {
                if (user?.blocked) {
                    unblockPerson({ input: { username } }).then((resp) => {
                        if (resp.error) {
                            // TODO show toast
                            console.log('failed', resp.error)
                            return
                        }
                        refetchUser()
                        if (onAction) onAction('unblock')
                    })
                } else {
                    blockPerson({ input: { username } }).then((resp) => {
                        if (resp.error) {
                            // TODO show toast
                            console.log('failed', resp.error)
                            return
                        }
                        refetchUser()
                        if (onAction) onAction('block')
                    })
                }
            }}>{user?.blocked ? intl.get('profile.unblock') : intl.get('profile.block')}</SecondaryButton>}
        </Buttons>
    </ModalContent>
}