import intl from "react-intl-universal";
import styled from "styled-components";
import { useMutation } from "urql";
import { Container, Row, Col } from 'react-grid-system';

import { colors, seasonalPalette, selectColorWithHash } from "utils/colors";

import { DeleteCommentMutation, EditCommentMutation, LeaveCommentMutation } from "queries/comment";

import { useAppContext } from "components/page";
import { BarRating } from "components/rating";

import PfpPlaceholderImg from 'assets/imgs/pfp-placeholder.jpeg'
import { CommentsSection } from "components/comment";
import { useState } from "react";
import { Modal } from "components/modal";
import { PersonModal } from "components/person";

const Wrapper = styled.div.attrs(({ $background }) => ({
    $background
}))`
    width: calc(100% - 1em - 80px);
    background-color: ${({ $background }) => $background || colors.lightgrey};
    display: inline-block;
    padding-bottom: 10px;
    margin: calc(0.5em + 40px);
    border-radius: 30px;
    transition: 0.2s background-color;
    position: relative;
`

const Frame = styled.div`
    margin: 10px auto;
    margin-bottom: 0;
    padding: 10px;
    max-width: 100%;
    width: 210px;
    height: 210px;
    background-color: ${colors.white};
    position: relative;
    text-align: center;
    border-radius: 30px;
    overflow: hidden;
`
const BookImage = styled.div`
    background-image: url(${({ src }) => src});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 210px;
    width: 100%;
`

const FriendPfp = styled.img`
    width: 80px;
    height: 80px;
    position: absolute;
    left: -40px;
    top: -40px;
    z-index: 1;
    border-radius: 80px;
    outline: solid 2px ${colors.cyan};
`

const FriendWrapper = styled.div`
    position: relative;
    cursor: pointer;
`

const FriendUsername = styled.div`
    position: absolute;
    left: 50px;
    top: -25px;
`

const TitleWrapper = styled.div`
    text-align: center;
    @media (min-width: 600px) {
        padding: 1em 0;
        text-align: left;
    }
`

const StatsWrapper = styled.div`
    @media (min-width: 600px) {
        padding: 0.75em 0;
        text-align: right;
    }
`

const NameText = styled.div`
    font-family: Nourd-Bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 0.25em;
    max-width: 100%;
`

const AuthorText = styled.div`
    font-family: Nourd-Light;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 0.25em;
    max-width: 100%;
`

const GenereText = styled.div`
    font-size: 14px;
    border-radius: 6px;
    background-color: ${colors.darkgrey};
    color: ${colors.white};
    padding: 0.25em 0.5em;
    margin: 0.25em auto;
    width: fit-content;
    display: inline-block;
    max-width: calc(100% - 2em);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`

const FriendRating = styled.div``

export const FriendBook = ({ book_id, img_url, title, authors, personal_rating, genres, person }) => {
    const [modal, setModal] = useState(false)
    return <Wrapper $background={selectColorWithHash(title, seasonalPalette)}>
        {modal ? <Modal onClose={() => { setModal(false) }}>
            <PersonModal username={modal} />
        </Modal> : ''}
        <FriendWrapper onClick={() => {
            setModal(person.username)
        }}>
            <FriendPfp src={person.pfp_url || PfpPlaceholderImg} />
            <FriendUsername>{person.username}</FriendUsername>
        </FriendWrapper>
        <Container>
            <Row>
                <Col md={3}>
                    <Frame>
                        <BookImage src={img_url} />
                    </Frame>
                </Col>
                <Col md={9}>
                    <Container>
                        <Row>
                            <Col md={6}>
                                <TitleWrapper>
                                    <NameText>{title}</NameText>
                                    <AuthorText>{authors?.join(', ')}</AuthorText>
                                </TitleWrapper>
                            </Col>
                            <Col md={6}>
                                <StatsWrapper>
                                    {(genres || [intl.get('labels.unknown')]).map((genre, i) => {
                                        return <GenereText key={i}>{genre}</GenereText>
                                    })}
                                    <FriendRating>
                                        <BarRating rating={personal_rating || 0} />
                                    </FriendRating>
                                </StatsWrapper>
                            </Col>
                        </Row>
                        <Row>
                            <CommentsSection book_id={book_id} person={person} />
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    </Wrapper>
}