import { gql } from 'urql';

export const GetPersonQuery = gql`
query GetPerson($input: GetPersonRequest!){
    getPerson(input: $input){
        person {
            pfp_url
            friends_count
            connected
            added
            blocked
        }
    }
}
`
export const SearchUsersQuery = gql`
query SearchUsers($input: SearchUsersRequest!){
    searchUsers(input: $input){
        users {
            username
            pfp_url           
        }
    }
}
`
export const GetFriendsQuery = gql`
query GetFriends($input: GetFriendsRequest!){
    getFriends(input: $input){
        users {
            username
            pfp_url           
        }
        has_more
    }
}
`
export const GetFriendsUpdatesQuery = gql`
query TopFriends($input: TopFriendsRequest!){
    topFriends(input: $input){
        users {
            username
            pfp_url      
            has_updates     
        }
    }
}
`
export const AddPersonMutation = gql`
mutation addPerson($input: AddPersonRequest!){
    addPerson(input: $input){
        ok
    }
}
`
export const UnaddPersonMutation = gql`
mutation unaddPerson($input: UnaddPersonRequest!){
    unaddPerson(input: $input){
        ok
    }
}
`
export const UnfriendPersonMutation = gql`
mutation unfriendPerson($input: UnfriendPersonRequest!){
    unfriendPerson(input: $input){
        ok
    }
}
`
export const BlockPersonMutation = gql`
mutation blockPerson($input: BlockPersonRequest!){
    blockPerson(input: $input){
        ok
    }
}
`
export const UnblockPersonMutation = gql`
mutation unblockPerson($input: UnblockPersonRequest!){
    unblockPerson(input: $input){
        ok
    }
}
`
