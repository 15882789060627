export const CompressImage = (
    img,
    width,
    height,
    quality = 1.0,
    fileFormat = 'image/jpeg'
) => {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')

    canvas.width = width
    canvas.height = height

    const resizingFactor =
        img.width < img.height ? width / img.width : height / img.height
    let resizedWidth = img.width * resizingFactor
    let resizedHeight = img.height * resizingFactor

    if (width > height && resizedWidth < width) {
        resizedHeight *= width / resizedWidth
        resizedWidth = width
    } else if (width <= height && resizedHeight < height) {
        resizedWidth *= height / resizedHeight
        resizedHeight = height
    }

    const cropX =
        resizedWidth > resizedHeight ? -(resizedWidth - width) / 2 : 0
    const cropY =
        resizedHeight > resizedWidth ? -(resizedHeight - height) / 2 : 0

    context.drawImage(
        img, cropX, cropY,
        resizedWidth,
        resizedHeight
    )

    return new Promise((resolve, reject) => {
        canvas.toBlob(resolve, fileFormat, quality)
    })
}

export const ImageUriToElement = blob => {
    return new Promise((resolve, reject) => {
        const image = new Image()
        image.src = blob
        image.onload = () => {
            resolve(image)
        }
    })
}
