import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import reportWebVitals from "./reportWebVitals";
import { HashRouter as Router, Routes, Route } from "react-router-dom";

import { ApiProvider } from 'components/api'
import { AppProvider } from 'components/page'
import { FeedPage } from "pages/feed";
import { HomePage } from "pages/home";
import { ForgotPasswordPage, LoginPage } from "pages/login";
import { FeaturedPage } from "pages/featured";
import { MyBooksPage } from "pages/mybooks";
import { ProfilePage } from "pages/profile";
import { PrivacyPolicy } from "components/privacy";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Router>
      <AppProvider>
        <ApiProvider>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/feed" element={<FeedPage />} />
            <Route path="/login" element={<LoginPage mode='login' />} />
            <Route path="/sign-up" element={<LoginPage mode='sign-up' />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage mode='forgot-password' />} />
            <Route path="/reset-password" element={<ForgotPasswordPage mode='reset-password' />} />
            <Route path="/featured" element={<FeaturedPage />} />
            <Route path="/my-books" element={<MyBooksPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
          </Routes>
        </ApiProvider>
      </AppProvider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
