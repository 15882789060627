import { sdbm } from "utils/common";

export const colors = {
  cyan: "#9789BF",
  lightcyan: "#9E98BE",
  whitecyan: "#bdb8d9",
  white: "#FFFFFF",
  lightgrey: "#F3F0F0",
  midgrey: "#888888",
  grey: "#dddddd",
  darkgrey: "#222222",
  black: "#000000",
  nude: "#FCBDBC",
  teal: "#B7CCB7",
  gold: "#F8BD63",
  babyblue: "#BDD7FD",
  linkblue: "#555af2",
  transparent: "transparent",
  red: "#eb4034",
};

export const seasonalPalette = [
  colors.nude,
  colors.teal,
  colors.gold,
  colors.babyblue
]

export const selectColorWithHash = (s, palette) => {
  return palette[Math.abs(parseInt(sdbm(s || ' ') / 1000)) % palette.length]
}