import styled from "styled-components";
import $ from "jquery";

const StyledForm = styled.form`
  position: relative;
  & > fieldset > * {
    margin: 0.5em 0;
  }
`;

const StyledFieldSet = styled.fieldset`
  border: none;
  margin: 0;
`;

export const Link = styled.div`
  color: #0072e5;
  text-decoration-color: #0072e5;
  cursor: pointer;
  text-decoration: underline;
`;

export const Form = ({ children, onSubmit, disable }) => {
  return (
    <StyledForm
      className="cmp-form"
      onSubmit={e => {
        e.preventDefault();
        if (!onSubmit) return false;
        const children = $(e.target).find("[data-key]");
        const data = {};
        for (const child of children) {
          const dataKey = child.getAttribute("data-key");
          if (!dataKey) continue;
          const value = child.value || child.getAttribute("value");
          try {
            data[dataKey] = JSON.parse(value);
          } catch (e) {
            if (value !== "") data[dataKey] = value;
          }
        }
        onSubmit(data);
        return false;
      }}
    >
      <StyledFieldSet disabled={disable}>{children}</StyledFieldSet>
    </StyledForm>
  );
};
