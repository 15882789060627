import styled from 'styled-components'

import LogoIcon from "assets/logo.png";
import { colors } from 'utils/colors';
import { useAppContext } from './page';
import { QuaternaryButton, SecondaryButton } from './buttons';
import intl from 'react-intl-universal';
import { useNavigate } from 'react-router-dom';

import MenuSvg from 'assets/icons/menu.svg'
import { useState } from 'react';

import { GetNotificationsQuery, MarkNotificationsReadMutation, RemoveNotificationMutation } from 'queries/notification';
import { useMutation, useQuery } from 'urql';

import NotificationSvg from 'assets/icons/notification.svg'
import PersonAddSvg from 'assets/icons/person-add.svg'
import PersonCheckSvg from 'assets/icons/person-check.svg'
import { AddPersonMutation } from 'queries/person';

const Wrapper = styled.div.attrs(({ $top }) => ({
    $top
}))`
    background: ${colors.white};
    top: ${({ $top }) => $top}px;
    position: sticky;
    z-index: 3;
    -webkit-box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.2);
`

const OOBBox = styled.div`
    background: ${colors.white};
    position: absolute;
    height: 100vh;
    top: -100vh;
    width: 100%;
`

const LogoWrapper = styled.div`
    display: flex;
    cursor: pointer;
    width: fit-content;
`

const LogoImg = styled.img`
    width: 40px;
    height: 40px;
    padding: 5px;
`

const LogoText = styled.div`
    font-family: Rasputin;
    font-size: 24px;
    line-height: 50px;
    color: ${colors.blue};
`

const LoginWrapper = styled.div`
    width: fit-content;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    & > div {
        display: inline-block;
    }
    & .cmp-button {
        margin: 0 0.25em;
    }
`
const MenuWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0.5em;
    margin: 0.5em;
`

const ExpandedWrapper = styled.div`
    @media (max-width: 600px) {
        display: none;
    }
`

const ContractedWrapper = styled.div`
    position: fixed;
    right: 0.5em;
    @media (min-width: 600px) {
        display: none;
    }  
`

const MenuButton = styled.img`
    padding: 0.25em;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
        background-color: ${colors.lightgrey};
    }
`

const DropDown = styled.div`
    background-color: ${colors.white};
    padding: 0.5em;
    position: absolute;
    right: 0;
    width: fit-content;
    min-width: 150px;
    & > * {
        display: block;
    }
`

const MenuItem = styled.div`
    padding: 0.5em 1em;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
        background-color: ${colors.lightgrey};
    }
`

const NotificationButton = styled.img`
    padding: 0.25em;
    border-radius: 8px;
    cursor: pointer;
    margin: 0 0.5em;

    &:hover {
        background-color: ${colors.lightgrey};
    }
`

const NotificationButtonWrapper = styled.div`
    position: absolute;
    left: -2.5em;
`

const NotificationsRed = styled.div`
    position: absolute;
    right: 0.5em;
    background-color: ${colors.red};
    width: 12px;
    height: 12px;
    border-radius: 12px;
    text-align: center;
    pointer-events: none;
`

const CollapsedNotificationsButtonWrapper = styled.div`
    display: inline-block;
    position: relative;
`

const NotificationsList = styled.div`
    position: absolute;
    margin-top: 0.5em;
    right: 0;
    background-color: ${colors.white};
    padding: 1em;
    -webkit-box-shadow: 0px 10px 5px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 10px 5px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 10px 5px 0px rgba(0,0,0,0.25);
    border-radius: 10px;
    width: 250px;
`

const NotifWrapper = styled.div`
    background-color: ${colors.white};
    padding: 0.5em;
    display: flex;
`

const NotifIcon = styled.img`
    display: inline-flex;
    margin-right: 1em;
`

const NotifText = styled.div`
    display: inline-flex;
`

const Buttons = styled.div`
    text-align: center;
    & * {
        margin: 0 0.5em;
    }
`

const TextCenter = styled.div`
    text-align: center;
`

export const Logo = () => {
    return <LogoWrapper>
        <LogoImg src={LogoIcon}></LogoImg>
        <LogoText>BookBoos</LogoText>
    </LogoWrapper>
}

export const TopBar = ({ content, hasUpdates, refetchHasNewNotifs }) => {
    const navigate = useNavigate();
    const { safearea, userIsLoggedIn, logout } = useAppContext();
    const [menuOpened, setMenuOpened] = useState(false);
    const [notifsOpened, setNotifsOpened] = useState(false);

    const [notifsResult, refetchNotifs] = useQuery({
        query: GetNotificationsQuery,
        variables: {
            input: {}
        },
        pause: !userIsLoggedIn || !notifsOpened,
        requestPolicy: 'cache-and-network',
    });

    const notifications = notifsResult?.data?.getNotifications?.notifications

    const [, addPerson] = useMutation(AddPersonMutation)
    const [, removeNotfication] = useMutation(RemoveNotificationMutation)
    const [, markNotificationsRead] = useMutation(MarkNotificationsReadMutation)

    const onNotificationClick = (e) => {
        setNotifsOpened(!notifsOpened)
        if (!notifsOpened) {
            if (!hasUpdates)
                refetchNotifs()
            else
                markNotificationsRead({ input: {} }).then((resp) => {
                    refetchNotifs()
                    refetchHasNewNotifs()
                }).catch(err => { })
        }
    }

    const notfiButton = () => {
        return <>
            {hasUpdates ? <NotificationsRed /> : ''}
            <NotificationButton src={NotificationSvg} onClick={onNotificationClick} />
            {notifsOpened ? <NotificationsList>
                {notifications?.length === 0 ?
                    <TextCenter>{intl.get('notifs.noNotifications')}</TextCenter> :
                    notifications?.map((notif, i) => {
                        switch (notif.type) {
                            case 'friend-request':
                                return <div key={i}>
                                    <NotifWrapper key={i}>
                                        <NotifIcon src={PersonAddSvg} />
                                        <NotifText>{intl.get('notifs.friendRequest', { username: notif.username })}</NotifText>
                                    </NotifWrapper>
                                    <Buttons>
                                        <SecondaryButton onClick={() => {
                                            addPerson({ input: { username: notif.username } }).then((resp) => {
                                                if (resp.error) {
                                                    // TODO show toast
                                                    console.log('failed', resp.error)
                                                    return
                                                }
                                                removeNotfication({ input: { notification_id: notif.notification_id } }).then(() => {
                                                    refetchNotifs()
                                                })
                                            })
                                        }}>{intl.get('actions.accept')}</SecondaryButton>
                                        <QuaternaryButton onClick={() => {
                                            removeNotfication({ input: { notification_id: notif.notification_id } }).then(() => {
                                                refetchNotifs()
                                            })
                                        }}>{intl.get('actions.decline')}</QuaternaryButton>
                                    </Buttons>
                                </div>

                            case 'friend-connected':
                                return <NotifWrapper key={i}>
                                    <NotifIcon src={PersonCheckSvg} />
                                    <NotifText>{intl.get('notifs.nowFriends', { username: notif.username })}</NotifText>
                                </NotifWrapper>
                        }
                    })}
            </NotificationsList > : ''}
        </>
    }

    return <Wrapper className="cmp-topbar" $top={safearea.top}>
        <OOBBox />
        <Logo />
        {content || ""}
        {userIsLoggedIn ? <MenuWrapper>
            <ExpandedWrapper>
                <NotificationButtonWrapper>
                    {notfiButton()}
                </NotificationButtonWrapper>
                <QuaternaryButton onClick={() => { navigate('/featured') }}>{intl.get('menu.featured')}</QuaternaryButton>
                <QuaternaryButton onClick={() => { navigate('/feed') }}>{intl.get('menu.feed')}</QuaternaryButton>
                <QuaternaryButton onClick={() => { navigate('/my-books') }}>{intl.get('menu.myBooks')}</QuaternaryButton>
                <QuaternaryButton onClick={() => { navigate('/profile') }}>{intl.get('menu.profile')}</QuaternaryButton>
                <QuaternaryButton onClick={() => { logout() }}>{intl.get('menu.logout')}</QuaternaryButton>
            </ExpandedWrapper>
            <ContractedWrapper>
                <CollapsedNotificationsButtonWrapper>
                    {notfiButton()}
                </CollapsedNotificationsButtonWrapper>
                <MenuButton src={MenuSvg} onClick={() => {
                    setMenuOpened(!menuOpened)
                }} />
                {menuOpened ? <DropDown>
                    <MenuItem onClick={() => { navigate('/featured') }}>{intl.get('menu.featured')}</MenuItem>
                    <MenuItem onClick={() => { navigate('/feed') }}>{intl.get('menu.feed')}</MenuItem>
                    <MenuItem onClick={() => { navigate('/my-books') }}>{intl.get('menu.myBooks')}</MenuItem>
                    <MenuItem onClick={() => { navigate('/profile') }}>{intl.get('menu.profile')}</MenuItem>
                    <MenuItem onClick={() => { logout() }}>{intl.get('menu.logout')}</MenuItem>
                </DropDown> : ''}
            </ContractedWrapper>
        </MenuWrapper> : <LoginWrapper>
            <QuaternaryButton onClick={() => {
                navigate('/sign-up')
            }}>{intl.get('actions.signUp')}</QuaternaryButton>
            <QuaternaryButton onClick={() => {
                navigate('/login')
            }}>{intl.get('actions.login')}</QuaternaryButton>
        </LoginWrapper>}
    </Wrapper>
};