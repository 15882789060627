import styled from "styled-components";

// import DogRunningGif from "assets/dog-running.gif";
// import DogRingPng from "assets/dog-ring.png";
import { colors } from "utils/colors";

const Wrapper = styled.div`
  transition: opacity 0.4s;
  pointer-events: none;
  background-color: ${colors.orange};
  width: 100%;
  height: calc(100vh);
  position: absolute;
  top: 0;
  text-align: center;
  font-family: Rasputin;
  font-size: 24px;
  z-index: 10;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
`;

const SlideAnim = `
@keyframes slide {
    0% {
        transform: translateX(-100vw);
    }
    100% {
        transform: translateX(0);
    }
}
`;

// const DogRunning = styled.img`
//   ${SlideAnim}
//   width: 100px;
//   animation: slide 0.3s;
// `;

// const DogRingWrapper = styled.div`
//   margin-top: -25px;
// `;

const Text = styled.div`
  ${SlideAnim}
  animation: slide 0.4s;
`;

// const DogRing = styled.img`
//   ${SlideAnim}
//   width: 100px;
//   animation: slide 0.5s;
// `;

export const Loader = ({ loadingText }) => {
  return (
    <Wrapper className="cmp-loader">
      {/* <LoadingWrapper>
        <DogRunning src={DogRunningGif} />
        <DogRingWrapper>
          <DogRing src={DogRingPng} />
        </DogRingWrapper>
        <Text>{loadingText}</Text>
      </LoadingWrapper> */}
    </Wrapper>
  );
};
