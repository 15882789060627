import { useEffect, useState } from "react";
import { useMutation, useQuery } from "urql";
import intl from "react-intl-universal";
import styled from "styled-components";

import { colors } from "utils/colors";

import { Page, UploadFile, useAppContext } from "components/page";
import { ApiProvider } from "components/api";

import { CompressImage, ImageUriToElement } from "utils/image";
import { ImageUpload, Input } from "components/input";
import { AddPersonMutation, BlockPersonMutation, GetFriendsQuery, GetPersonQuery, SearchUsersQuery, UnaddPersonMutation, UnblockPersonMutation, UnfriendPersonMutation } from "queries/person";

import PfpPlaceholderImg from 'assets/imgs/pfp-placeholder.jpeg'
import { randomString, shortenCount } from "utils/common";
import { Modal } from "components/modal";
import { SecondaryButton } from "components/buttons";
import { Label } from "components/label";
import { HasNewNotificationsQuery } from "queries/notification";
import { PersonModal } from "components/person";

const PageWrapper = styled.div`
    max-width: 600px;
    margin: auto;
`

const Pfp = styled.img`
    width: 160px;
    height: 160px;
    max-width: 80vw;
    max-height: 80vw;
    border-radius: 80vw;
    margin: 1em;
    -webkit-box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.25);
`

const Wrapper = styled.div`
    margin: auto;
    width: fit-content;
    text-align: center;
    margin-bottom: 1em;
`

const Username = styled.div`
    font-size: 20px;
    text-align: center;
`

const InputWrapper = styled.div`
    padding: 0 1em;
`

const StyledInput = styled(Input)`
    position: relative;
`

const FriendsWrapper = styled.div`
    padding: 1em;
    max-height: 50vh;
    overflow: auto;
    margin: 1em auto;
`

const NoFriends = styled.div`
    margin: 0.5em;
`

const FriendWrapper = styled.div`
    margin: 0.5em auto;
    display: flex;
    background-color: ${colors.white};
    padding: 0.5em;
    width: calc(100% - 2em);
    border-radius: 10px;
    cursor: pointer;

    &:hover {
        background-color: ${colors.grey};
    }
`

const FriendPfp = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50px;
    display: inline-flex;
`

const FriendUsername = styled.div`
    display: inline-flex;
    line-height: 50px;
    margin-left: 0.5em;
`

export const ProfilePage = () => {
    const { username, userIsLoggedIn } = useAppContext();
    const [keywords, setKeywords] = useState('');
    const [userModal, setUserModal] = useState(false)
    const [friends, setFriends] = useState([])

    const [selfResult, refetchSelf] = useQuery({
        query: GetPersonQuery,
        variables: {
            input: {
                username
            }
        },
        pause: !username || !userIsLoggedIn,
        requestPolicy: 'cache-and-network',
    });
    const self = selfResult?.data?.getPerson?.person

    const [usersResult, refetchUsers] = useQuery({
        query: SearchUsersQuery,
        variables: {
            input: {
                keywords
            }
        },
        pause: keywords === ''
    });

    const [friendsCursor, setFriendsCursor] = useState(0)
    const [friendsResult, refetchFriends] = useQuery({
        query: GetFriendsQuery,
        variables: {
            input: {
                cursor: friendsCursor
            }
        },
        pause: !userIsLoggedIn,
        requestPolicy: 'network-only',
    });
    const newFriends = friendsResult?.data?.getFriends?.users
    const hasMore = friendsResult?.data?.getFriends?.has_more

    useEffect(() => {
        if (!newFriends || newFriends?.length === 0) return
        setFriends([...friends, ...newFriends])
    }, [newFriends])

    const [hasNewNotifs, refetchHasNewNotifs] = useQuery({
        query: HasNewNotificationsQuery,
        variables: {
            input: {}
        },
        pause: !userIsLoggedIn,
        requestPolicy: 'cache-and-network',
    });
    const hasUpdates = hasNewNotifs?.data?.hasNewNotifications?.ok

    return (
        <ApiProvider>
            <Page backgroundColor={colors.lightgrey} hasUpdates={hasUpdates} refetchHasNewNotifs={refetchHasNewNotifs}>
                {userModal ? <Modal onClose={() => { setUserModal(false) }}>
                    <PersonModal username={userModal} onAction={(action) => {
                        switch (action) {
                            case 'add':
                                refetchSelf()
                                refetchFriends()
                                break
                            case 'unadd':
                                refetchFriends()
                                break
                            case 'unfriend':
                                refetchFriends()
                                break
                            case 'unblock':
                                refetchFriends()
                                break
                        }
                    }} />
                </Modal> : ''}
                <PageWrapper>
                    <Wrapper>
                        <ImageUpload onChange={(e, data) => {
                            ImageUriToElement(data).then(image => {
                                CompressImage(image, 360, 360)
                                    .then(b => {
                                        if (!b) return
                                        UploadFile({ path: 'upload-pfp', name: 'pfp_file', binary: b }).then(() => {
                                            refetchSelf()
                                        })
                                    })
                                    .catch(err => {
                                        console.error(`process image failed`, err)
                                    })
                            })
                        }}>
                            <Pfp src={self?.pfp_url ? `${self.pfp_url}?${Math.random()}` : PfpPlaceholderImg} />
                            <Username>{username} • {shortenCount(self?.friends_count || 0)} {intl.get('labels.friends')}</Username>
                        </ImageUpload>
                    </Wrapper>
                    <InputWrapper>
                        <StyledInput debounce placeholder={intl.get('profile.searchUsers')} onChange={(e, v) => {
                            setKeywords(v)
                        }} autocompleteList={usersResult?.data?.searchUsers?.users.map((user, i) => {
                            return {
                                id: user.username,
                                img_url: user.pfp_url || PfpPlaceholderImg,
                                text: user.username
                            }
                        })} onAutocomplete={(username, i) => {
                            setUserModal(username)
                        }} />
                    </InputWrapper>
                    <FriendsWrapper onScroll={(e) => {
                        if (e.target.scrollTop + 100 > e.target.scrollHeight - e.target.clientHeight && hasMore) {
                            setFriendsCursor(friends.length)
                        }
                    }}>
                        <Label>{intl.get('profile.friends')}</Label>
                        {friends?.length > 0 ? friends.map((friend, i) => {
                            return <FriendWrapper key={i} onClick={() => {
                                setUserModal(friend.username)
                            }}>
                                <FriendPfp src={friend.pfp_url || PfpPlaceholderImg} />
                                <FriendUsername>{friend.username}</FriendUsername>
                            </FriendWrapper>
                        }) : <NoFriends>{intl.get('profile.no_friends')}</NoFriends>}
                    </FriendsWrapper>
                </PageWrapper>
            </Page>
        </ApiProvider>
    );
};
